import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Typography } from '@material-ui/core'

import { Avatar, Clickable } from '../'
import { abbreviatedUserName } from '../../shared-consumer/lib/utils'
import RootStore from '../../src/stores/RootStore'
import TextButton from '../TextButton'

interface Props {
  store?: RootStore
}

@inject('store')
@observer
class BookingProfessional extends Component<Props> {
  openProfessionalProfile = () => {
    const { modalController, checkoutStore: { provisionalProfessional } } = this.props.store!
    modalController.openModal('professionalProfile', provisionalProfessional._id, 'bookWith')
  }

  render() {
    const { store } = this.props
    const {
      checkoutStore: { provisionalProfessional },
      uiStore: { showRemoveProfessionalPopup },
      i18n: { s },
    } = store!

    return (
      <Box display='flex' mt={2} mb={2} alignItems='center' justifyContent='space-between'>
        <Clickable onClick={this.openProfessionalProfile}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box mr={2}>
              <Avatar user={provisionalProfessional} size={30} />
            </Box>
            <Typography variant='body2'>{abbreviatedUserName(provisionalProfessional)}</Typography>
          </Box>
        </Clickable>
        <TextButton onClick={showRemoveProfessionalPopup}>{s('Remove')}</TextButton>
      </Box>
    )
  }
}

export default BookingProfessional
