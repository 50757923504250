/*
 *Common strings shared between all platforms.
 *
 *The system relies on these names so DO NOT change
 *them without considering how the logic they effect
 *will need to be updated.
 */

export const SERVICE_FEE_ITEM_NAME = 'Service Fee'
export const NEW_PROFESSIONAL_DISCOUNT = 'New to Secret Spa discount'
export const OFFPEAK_ITEM_NAME = 'Off-Peak discount'
export const ELITE_TREATMENT_COST = 'Elite Professional Fee'

export const TreatmentTypes = [
  'iv-drips',
  'packages',
  'nails',
  'massage',
  'facial',
  'make-up',
  'spray-tan',
  'hair-styling',
  'hair',
  'hair-cutting',
  'waxing',
  'eyes',
]

export const CityOptions = [
  'london',
  'manchester',
  'brighton',
  'leeds',
  'liverpool',
  'glasgow',
  'edinburgh',
  'other',
]