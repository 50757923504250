import { action, observable } from 'mobx'
import RootStore from '../../src/stores/RootStore'
import { $BookingModel } from '../stores/BookingStore/BookingModel'
import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'

export class ReviewBookingHandler {
  store: RootStore
  @observable professional: $Professional | undefined
  @observable bookingId: string
  @observable wouldRebook: boolean | undefined = false

  constructor(rootStore: RootStore) {
    this.store = rootStore
  }

  @action.bound clearData = () => {
    this.professional = undefined
    this.bookingId = ''
    this.wouldRebook = false
  }

  // Check reviewable bookings and show review screen if needed
  checkReviewableBooking = async (): Promise<boolean> => {
    const { authStore, uiStore } = this.store

    // Return false if not logged in
    if (!authStore.isLoggedIn) return false

    // Get refreshed booking if needed
    await this.fetchReviewableBooking_api()

    // Get reviewable booking
    const reviewableBooking = this.latestReviewableBooking()
    if (reviewableBooking) {
      uiStore.showReviewPopup(reviewableBooking.data._id)
      return true
    }

    return false
  }

  @action.bound async fetchReviewableBooking_api() {
    const { bookingStore } = this.store
    await bookingStore.fetchItems_api({
      variables: {
        pagination: {
          limit: 1,
        },
        sort: {
          date: 1,
        },
        filter: {
          reviewPrompted: false,
          status: 'COMPLETED',
        },
      },
    })
  }

  // Returns latest reviewable booking
  latestReviewableBooking = (): $BookingModel | undefined => {
    const { bookingStore: { items } } = this.store
    // Get reviewable bookings
    return [...items]
      .sort((a, b) => (a.data.date || 0) > (b.data.date || 0) ? -1 : 1)
      .find(booking => {
        const { status, reviewPrompted, clientReviewId } = booking.data
        return status === 'COMPLETED' && !reviewPrompted && !clientReviewId
      })
  }
}