export const Descriptions = {
  'Nails': ['No need to go to a nail bar for your perfect manicure & pedicure. Our highly-skilled mobile nail technicians carry a wide range of premium polishes, both classic and gel (Shellac) to deliver an immaculate manicure & pedicure at home.'],
  'Spray tan': ['A mobile spray tan carried out by specialised tanning professionals in your home. The spray tan will take place in a tanning tent to keep your home perfectly clean. With no travel to a tanning salon, you can dry off in private for perfect results.', 'Hailed by the Evening Standard as the best mobile spray tan in London, you can be sure you will have a streak-free, long-lasting tan. Our partners are Fake Bake, Vita Liberata and Sienna X.'],
  'Facial': ['Indulge in a luxury mobile facial in the comfort of your home. Expert facialists tailor the treatment to your skin type, bringing a bed, towels and all necessary equipment. Secret Spa is now offering two new glow-enhancing facials co-created with multi award-winning skincare brand OSKIA. Our in-home facial services are available across London.'],
  'Hairdressing': ['Our mobile hairdressers offer women’s, men’s and children’s hair cuts at home with no fuss.'],
  'Make-up': ['Get ready to party with professional make-up at home services. Our elite mobile make-up artists have experience with editorial, catwalk and celebrities and are available for you to book at home too.'],
  'Massage': ['Our mobile massage therapists are certified to the highest levels in a range of techniques. They deliver spa-quality, full body massages for ultimate relaxation, without you needing to leave the house.'],
  'Waxing': ['We offer a full range of mobile waxing treatments delivered in the privacy of your own home. The mobile therapists we work with are highly-qualified and use premium products for a smooth finish and long-lasting result. Enjoy the luxury and comfort of an at home waxing service.'],
  'Lashes & Brows': ['Mobile lash extensions, tints and eyebrow shaping carried out by expert therapists in the comfort of your own home.'],
  'Cut & Colour': ['Our expert hair stylists offer exceptional salon-quality blow dries in the home. With home appointments starting at 6am you can book your mobile hair stylist to come right before your event for a blowout that lasts all day.'],
  'Hair Styling': ['Our expert hair stylists offer exceptional salon-quality blow dries in the home. With home appointments starting at 6am you can book your mobile hair stylist to come right before your event for a blowout that lasts all day.'],
  'Healthcare': ['Secret Spa have partnered with Effect Doctors™ to bring intravenous drips, packed with vitamins, minerals and medications as required, safely to your home. Carried out solely by Doctors who specialise in intravenous drug administration, you can benefit from a private IV drip at home. Their unique cocktails are the best IV drips available in London.'],
  'Packages': [''],
}