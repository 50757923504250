import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, createStyles, Typography, WithStyles, withStyles } from '@material-ui/core'

import RootStore from '../src/stores/RootStore'
import TextButton from './TextButton'
import { colors } from '../theme'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = () => createStyles({
  container: {
    background: colors.BG400,
    minHeight: '100vh',
    textAlign: 'center',
    transition: 'opacity 200ms',
    pointerEvents: 'all',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000,
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container_hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  logo: {
    width: '75vw',
    maxWidth: '400px',
    height: '20vh',
    marginTop: -80,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: 'url(/img/logos/secret-spa-logo.svg)',
    transition: 'transform 200ms ease-out',
  },
  logo_hidden: {
    transform: 'scale(1.2)',
  },
})

@inject('store')
@observer
class SplashScreen extends Component<Props> {
  reloadSite() {
    window.location.reload()
  }

  render() {
    const { classes, store } = this.props
    const { uiStore: { dialog }, i18n: { s } } = store!

    const readyClass = store!.readyStatus > 0 ? '_hidden' : ''

    return (
      <Box className={`${classes.container} ${classes['container' + readyClass]}`} aria-label='loading_screen'>
        <Box className={`${classes.logo} ${classes['logo' + readyClass]}`} />
        {!!dialog.title &&
          <Box position='fixed' bottom={0} pb={24} width='100%'>
            <Typography variant='body2'>{dialog.title}</Typography>
            <TextButton onClick={this.reloadSite}>{s('Try again')}</TextButton>
          </Box>
        }
      </Box>
    )
  }
}

export default withStyles(styles)(SplashScreen)
