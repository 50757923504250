import { OperationVariables } from '@apollo/client'
import * as Sentry from '@sentry/core'
import { SeverityLevel } from '@sentry/types'

import { $RootStore } from '../../src/stores/RootStore'
import { FormattedError, formatError } from './ErrorHelper'

const errorCodeLogLevels: Record<string, SeverityLevel> = {
  'PROMOCODE_NOT_FOUND': 'debug',
  'EXISTING_APPLICATION': 'log',
}

interface UserContextData {
  _id?: Maybe<string>
  emailAddress?: Maybe<string>
  firstName?: Maybe<string>
  lastName?: Maybe<string>
}

export default abstract class BaseErrorHandler {
  store: $RootStore

  constructor(store: $RootStore) {
    this.store = store
  }

  manageRawError = (e: any, queryName = '', queryVariables: OperationVariables = {}) => {
    const { config, communication } = this.store

    const formattedError = formatError(e, queryName, queryVariables, this.location, this.userId)
    const { code } = formattedError

    if (config.name === 'development') {
      console.info(`ERROR: ${formattedError.code} - ${formattedError.queryName} - ${formattedError.message}`)
      console.info('More error info: ', formattedError)
    }

    if (this.expiredCodes.includes(code)) this.handleExpiredSession()
    else this.showErrorInUI(formattedError)

    const logLevel: SeverityLevel = errorCodeLogLevels[code] || 'error'
    if (logLevel === 'debug') return

    const fingerprint = [code]

    if (code === 'INTERNAL_SERVER_ERROR' && formattedError.message) {
      fingerprint.push(formattedError.message)
    }

    Sentry.captureMessage(code, {
      fingerprint,
      level: logLevel,
      extra: formattedError as Record<string, any>,
    })

    this.trackInAnalytics(formattedError)

    communication.latestErrorCode = code

    const error = new Error(formattedError.message)
    e.message = 'RequestError'

    throw error
  }

  setContext(contextName: string, o: Record<string, any>) {
    Sentry.setContext(contextName, o)
  }

  setUserContext = (user: UserContextData) => {
    Sentry.setUser({
      id: user._id || '',
      email: user.emailAddress || '',
      username: `${user.firstName} ${user.lastName}`,
    })
  }

  /*
   * Capture custom exceptions$
   * Call this from any catch block you want to track in Sentry
   */
  captureException(error: any, extra?: Record<string, any>) {
    Sentry.captureException(error, {
      fingerprint: [error],
      extra,
    })

    if (this.store.config.name === 'development') {
      console.error(error)
    }
  }

  abstract handleExpiredSession(): void
  abstract showErrorInUI(error: FormattedError): void
  abstract trackInAnalytics(error: FormattedError): void
  abstract get location(): string;
  abstract get userId(): string
  abstract expiredCodes: string[]
}

