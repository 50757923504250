import { action, observable } from 'mobx'
import { generateObjectId } from '../../../shared-lib/utils'
import analytics from '../../../src/stores/analytics'

import RootStore from '../../../src/stores/RootStore'

export interface $Order {
  _id: string
  type: 'booking' | 'giftCard'
}

export type $TransactionStore = TransactionStore

export default class TransactionStore {
  @observable accountBalance = 0
  @observable balanceForOrder = 0
  @observable referralCode = ''
  @observable loading = false
  rootStore: RootStore

  // Set rootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  /*
  * Requests methods
  */

  // Update credit balance from api
  @action.bound async getAccountBalance_api() {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      const ret = await requester.balance({})
      this.accountBalance = ret.balance || 0
    } finally {
      this.loading = false
    }
  }

  // Get available credit for an order
  @action.bound async getOrderBalance_api(order: $Order) {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      const ret = await requester.balance({ order })
      this.balanceForOrder = ret.balance || 0
    } finally {
      this.loading = false
    }
  }

  // Attempt to redeem a gift card from the api
  @action.bound async redeemGiftCard_api(code: string): Promise<boolean> {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      const response = await requester.redeemGiftCard({ giftCardCode: code })
      const redeemGiftCard = response.redeemGiftCard

      this.loading = false

      if (!redeemGiftCard) return false

      await this.getAccountBalance_api()
      return true
    } catch {
      this.loading = false
      return false
    }
  }

  // Buy gift card
  async createGiftCard_api(data: any): Promise<any> {
    const { communication: { requester } } = this.rootStore
    const { recipientAddressPostcode, ...giftCard } = data
    const mergedData = {
      ...giftCard,
      recipientAddress: `${data.recipientAddress}, ${recipientAddressPostcode}`,
    }

    try {
      const response = await requester.createGiftCard({ data: mergedData })
      const createGiftCard = response.createGiftCard
      if (createGiftCard && createGiftCard.code) return createGiftCard
      return null
    } catch {
      return null
    }
  }

  // Validate gift card
  async validateGiftCard_api(_id: string): Promise<boolean> {
    const { communication: { requester } } = this.rootStore

    try {
      const response = await requester.validateGiftCard({ _id })
      const validatedGiftCard = response.validateGiftCard
      if (validatedGiftCard) return true
      return false
    } catch {
      return false
    }
  }

  getStripeSecretForPayment_api = async (order: Omit<Order, 'paymentIntentId'>): Promise<{
    confirmed: true, paymentIntentId: string } | { confirmed: false , paymentIntentId?: string }>=> {
    const { communication: { requester }, uiStore, stripeIntentHandler, cardStore } = this.rootStore
    const { amount, orderId, orderType, capture, sourceId, paymentMethodType } = order

    try {
      let paymentMethodId = sourceId

      if (paymentMethodType === 'card') {
        const card = cardStore.findItemById(sourceId)

        paymentMethodId = card?.data.stripePaymentMethod ?? ''

        if (!paymentMethodId) {
          analytics.track('Customer Payment Failed', { step: 'cardStripeId', sourceId, paymentMethodType, paymentMethodId, amount })

          uiStore.showMessage({
            title: 'Card Error',
            body: 'Error with card selected. Please contact customer support',
          })

          return { confirmed: false }
        }
      }

      const { prepareForPayment: { clientSecret, paymentIntentId } } = await requester.prepareForPayment({
        orderInfo: { amount, paymentMethodId, adminUserId: null, paymentMethodType, orderId, orderType, capture },
      })

      const result = await stripeIntentHandler.confirmCardPayment(clientSecret!, paymentMethodId)

      if (result.error) {
        analytics.track('Customer Payment Failed', { step: 'confirm', sourceId, paymentMethodType, paymentMethodId, amount, error: result.error })

        uiStore.showMessage({
          title: 'Payment Error',
          body: result.error.message,
        })

        return { confirmed: false }
      }

      return { confirmed: true, paymentIntentId }
    } catch {
      analytics.track('Customer Payment Failed', { step: 'catchError', sourceId, paymentMethodType, amount })

      return { confirmed: false }
    }
  }

  // Request a payment
  @action.bound async makePayment_api(order: Omit<Order, 'paymentIntentId'>) {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    const { confirmed, paymentIntentId } = await this.getStripeSecretForPayment_api(order)

    if (!confirmed) {
      this.loading = false
      return false
    }

    try {
      const response = await requester.completePayment({ order: { ...order, paymentIntentId } })

      const isPaid = response.completePayment?.isPaid

      if (isPaid) {
        analytics.track('Payment Completed', { order })
      }

      return !!isPaid
    } catch {
      return false
    } finally {
      this.loading = false
    }
  }

  // Tip professional
  @action.bound async tipProfessional_api(bookingId: string, sourceId: string, amount: number, paymentMethodType: string): Promise<boolean> {
    const { communication: { requester }, bookingStore } = this.rootStore

    this.loading = true

    const orderId = generateObjectId()

    const { confirmed, paymentIntentId } = await this.getStripeSecretForPayment_api({
      orderId,
      sourceId,
      amount,
      paymentMethodType,
      orderType: 'tip',
      capture: true,
    })

    const data = {
      bookingId,
      sourceId,
      amount,
      paymentMethodType,
      orderId,
      paymentIntentId,
    } as TipInput

    if (!confirmed) {
      this.loading = false
      return false
    }

    try {
      const response = await requester.tipProfessional({ data })

      const adjustment = response.tipProfessional?.adjustment

      analytics.track('Paid with 3DS', { order: data, isTip: true })

      if (adjustment && adjustment._id) {
        const booking = await bookingStore.findItemById(bookingId)
        if (booking) booking.set({ adjustments: [...booking.data.adjustments, adjustment] })
        return true
      }

      return false
    } catch {
      return false
    } finally {
      this.loading = false
    }
  }

  /*
  * Helper methods
  */

  // Sync store data in storage
  syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('TRANSACTION')
  }

  // Use when we want to save to storage
  serialize() {
    return {
    }
  }
}
