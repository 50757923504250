import { action } from 'mobx'
import ListStore from '../../../shared-lib/stores/ListStore'
import analytics from '../../../src/stores/analytics'
import { minutesToMs } from '../../lib/utils'
import TreatmentModel, { $TreatmentModel } from './TreatmentModel'

export default class TreatmentStore extends ListStore<$TreatmentModel> {
  fetchMethodName = 'getTreatments'
  ModelClass = TreatmentModel
  cacheLength = minutesToMs(15)

  @action.bound async rebookTreatments_api(bookingId: string) {
    const { communication: { requester } } = this.rootStore

    const useTestPrices = !!analytics.ABTest('testPrices')

    try {
      const response = await requester.rebookTreatments({ _id: bookingId, extra: { useTestPrices } })

      const bookingTreatmentFieldsFragment = response.rebookTreatments

      return bookingTreatmentFieldsFragment || []
    } catch {
      return []
    }
  }
}

export type $TreatmentStore = TreatmentStore
