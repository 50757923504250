import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Image from 'next/image'

import { Box, createStyles, Typography, WithStyles, withStyles } from '@material-ui/core'

import { Avatar, Clickable } from '../'
import RootStore from '../../src/stores/RootStore'
import TextButton from '../TextButton'
import NavMenuLink from './NavMenuLink'
import AccountIcon from '../../public/img/navMenuIcons/account.png'
import BookingsIcon from '../../public/img/navMenuIcons/bookings.png'
import SearchIcon from '../../public/img/navMenuIcons/search.png'
import ChatIcon from '../../public/img/navMenuIcons/chat.png'
import { theme } from '../../theme'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = () => createStyles({
  or: {
    marginLeft: 6,
    marginRight: 6,
    opacity: 0.4,
  },
})

@inject('store')
@observer
class AccountActions extends Component<Props> {
  login = () => {
    const { modalController, uiStore, routeHandler } = this.props.store!
    uiStore.navVisible = false
    routeHandler.postAuthenticationAction = undefined
    modalController.openModal('auth', 'sign-in')
  }

  createAccount = () => {
    const { modalController, uiStore, routeHandler } = this.props.store!
    uiStore.navVisible = false
    routeHandler.postAuthenticationAction = undefined
    modalController.openModal('auth', 'create-account')
  }

  openAccount = () => {
    const { modalController, uiStore } = this.props.store!
    uiStore.navVisible = false
    modalController.openModal('account')
  }

  openSearch = () => {
    const { checkoutStore: { hasPostcode }, uiStore, modalController } = this.props.store!

    // Check if user has a postcode or addresses store under account
    if (!hasPostcode) {
      uiStore.showPostCodeDialog()
    } else {
      modalController.openModal('professionalSearch', 'new')
    }
  }

  render() {
    const { classes } = this.props
    const { i18n: { s }, authStore, userStore, chatStore } = this.props.store!
    const { isLoggedIn } = authStore
    const { data, profilePictureUpdate } = userStore
    const { login, createAccount, openAccount, openSearch } = this
    const chatText = !!chatStore.unreadMessages ? s('Chats ($0)', chatStore.unreadMessages) : s('Chats')

    return (
      <>
        {isLoggedIn && data._id
          ? <Clickable onClick={openAccount}>
            <Box display='flex' flexDirection='row' mt={3} alignItems='center' aria-label='open_account_modal'>
              <Avatar user={data} size={30} isUser refetch={profilePictureUpdate} />
              <Box mr={2} />
              <Typography variant='body2'>{s('Account')}</Typography>
            </Box>
          </Clickable>
          : <Box display='flex' flexDirection='row' mt={3} alignItems='center'>
            <Image src={AccountIcon} alt={'location'} width={theme.spacing(5)} height={theme.spacing(5)} priority />
            <Box mr={2} />

            <TextButton onClick={login}>{s('Login')}</TextButton>
            <Typography className={classes.or}>or</Typography>
            <TextButton onClick={createAccount}>{s('Sign up')}</TextButton>
          </Box>
        }

        <Box mt={1} />

        {isLoggedIn && <NavMenuLink
          key='Chats'
          icon={ChatIcon}
          href={'#chats'}
          textComponent={
            <Typography variant='body2' color='inherit'>{chatText}</Typography>
          }
        />}

        {isLoggedIn && <NavMenuLink
          key={'Bookings'}
          icon={BookingsIcon}
          href={'#bookings'}
          textComponent={
            <Typography variant={'body2'} color={'inherit'}>{s('Bookings')}</Typography>
          }
        />}

        <NavMenuLink
          key={'Professionals'}
          icon={SearchIcon}
          onPress={openSearch}
          textComponent={
            <Typography variant={'body2'} color={'inherit'}>{s('Professionals')}</Typography>
          }
        />
      </>
    )
  }
}

export default withStyles(styles)(AccountActions)
