import { action, computed } from 'mobx'
import moment from 'moment-timezone'

import ListStore from '../../../shared-lib/stores/ListStore'
import listeners from '../../graph/definition/Listeners'
import BookingModel, { $BookingModel } from './BookingModel'

const upComingBookingStatuses = ['REQUESTED', 'APPROVAL_PENDING', 'ACCEPTED', 'IN_TRANSIT', 'ARRIVED']

export default class BookingStore extends ListStore<$BookingModel> {
  fetchMethodName = 'bookings'
  ModelClass = BookingModel

  // Listen on bookings and update bookings on changes
  @action.bound async listenOnBookings_api() {
    const { uiStore, userStore: { data: { _id } }, communication } = this.rootStore
    const { mergeData } = this

    return communication.client.subscribe<Subscription>({
      query: listeners.bookingUpdated,
      variables: { userId: _id },
    }).subscribe({
      next(res) {
        const data = res.data?.bookingUpdated?.booking
        data && mergeData([data], false)
      },
      error(err: any) {
        uiStore.handleErrors(err)
      },
    })
  }

  @action.bound async fetchUpcomingBooking_api() {
    this.clearCache()

    await this.fetchItems_api({
      variables: {
        pagination: {
          limit: 1,
        },
        sort: {
          date: 1,
        },
        filter: {
          status: { $in: upComingBookingStatuses },
          date: { $gte: moment().add(-3, 'hours').toDate() },
        },
      },
    })
  }

  @computed get upComingBooking(): $BookingModel | undefined {
    // Find bookings in the future with valid status
    const upComingBookings = this.items
      .filter(b => {
        return upComingBookingStatuses.includes(b.data.status) && moment(b.data.date).isAfter(Date.now())
      })
      .sort((a, b) => {
        const bDate: any = new Date(b.data.date!)
        const aDate: any = new Date(a.data.date!)
        return aDate - bDate
      })

    if (upComingBookings.length === 0) return undefined

    const nextUpComingBooking = upComingBookings[0]

    // Return booking if it is in the future
    return nextUpComingBooking
  }

  @computed get upcomingBookings(): Array<$BookingModel> {
    const statuses = ['REQUESTED', 'APPROVAL_PENDING', 'ACCEPTED', 'IN_TRANSIT', 'ARRIVED']
    return this.items.filter(b => {
      return statuses.includes(b.data.status)
        && b.data.publishedDate
        && b.data.totalLoyaltyPoints > 0
    })
  }

  @computed get hasMadeBooking() {
    return this.items.length > 0
  }

  latestBookingWithPro = (id: string): $BookingModel | undefined => {
    return [...this.items]
      .sort((a, b) => (a.data.date || 0) > (b.data.date || 0) ? -1 : 1)
      .find(booking => {
        const { status, professional } = booking.data
        return status === 'COMPLETED' && id === professional?._id
      })
  }

  @action.bound async updateBooking_api(bookingId: string) {
    const { communication: { requester } } = this.rootStore
    const booking = this.findItemById(bookingId)

    if (booking) {
      const data = booking!.toRequestData() as BookingInput

      try {
        const response = await requester.publishBooking({ data, sendNotifications: true })

        const newBooking = response.publishBooking

        if (newBooking) {
          booking.merge(newBooking)
          return true
        } else {
          return false
        }
      } catch {
        return false
      }
    }

    return false
  }
}

export type $BookingStore = BookingStore
