import { action, observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'

export interface $Card {
  _id: string
  setDefaultAt: Date
  userId: string
  stripePaymentMethod: string
  lastFourDigits: string
  createdAt?: Date
  expMonth: string
  expYear: string
  postalCode: string
  brand: string
}

export type $CardModel = CardModel

export default class CardModel extends ModelStore {
  @observable data: $Card = {
    _id: '',
    setDefaultAt: new Date(),
    userId: '',
    stripePaymentMethod: '',
    lastFourDigits: '',
    expMonth: '',
    expYear: '',
    postalCode: '',
    brand: '',
  }

  fetchMethodName = 'card'
  saveMethodName = 'addCard'
  removeMethodName = 'removeCard'

  @action.bound setAsDefaultCard = async (): Promise<boolean> => {
    const setDefaultAt = new Date()
    const { _id } = this.data

    this.set({ setDefaultAt })

    try {
      await this.rootStore.communication.requester.updateDefaultCard({ _id, setDefaultAt: setDefaultAt.toString() })
      return true
    } catch (e) {
      return false
    }
  }
}
