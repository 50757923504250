import { observable } from 'mobx'
import ModelStore from '../../../shared-lib/stores/ModelStore'
export default class ChatModel extends ModelStore {
  @observable data: Chat = {
    _id: '',
    clientId: '',
    professionalId: '',
    professional: null,
    client: null,
    lastMessage: {
      sentAt: null,
      message: null,
    },
    messageCount: {
      client: {
        unreadMessages: 0,
        sent: 0,
      },
      professional: {
        unreadMessages: 0,
        sent: 0,
      },
    },
  }
}