import debounce from 'debounce-promise'

import ListStore from '../../../shared-lib/stores/ListStore'
import RootStore from '../../../src/stores/RootStore'
import ProfessionalModel, { $ProfessionalModel } from './ProfessionalModel'

export type $ProfessionalStore = ProfessionalStore

export default class ProfessionalStore extends ListStore<$ProfessionalModel> {
  fetchMethodName = 'searchProfessionals'
  ModelClass = ProfessionalModel
  debounced_searchProfessionals_api: (opts?: Record<string, any>) => Promise<void>

  constructor(rootStore: RootStore) {
    super(rootStore)
    this.debounced_searchProfessionals_api = debounce(this.fetchItems_api, 200)
  }
}
