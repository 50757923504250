import { inject, observer } from 'mobx-react'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { TransitionGroup, Transition } from 'react-transition-group'
import dynamic from 'next/dynamic'

import { GenericPopup, PostcodePopup, RatingPopup } from '../components'
import RootStore from '../src/stores/RootStore'
import RebookPopup from '../components/RebookPopup'

const ChatModal = dynamic(() => import('./ChatModal/ChatModal'))
const AccountModal = dynamic(() => import('./AccountModal/AccountModal'))
const AddCardModal = dynamic(() => import('./AddCardModal/AddCardModal'))
const AddressesModal = dynamic(() => import('./AddressesModal/AddressesModal'))
const AddressModal = dynamic(() => import('./AddressModal/AddressModal'))
const AuthModal = dynamic(() => import('./AuthModal/AuthModal'))
const BookingModal = dynamic(() => import('./BookingModal/BookingModal'))
const BookingsModal = dynamic(() => import('./BookingsModal/BookingsModal'))
const ContactSupportModal = dynamic(() => import('./ContactSupportModal/ContactSupportModal'))
const EditClientDetailsModal = dynamic(() => import('./EditClientDetailsModal/EditClientDetailsModal'))
const EditProfileModal = dynamic(() => import('./EditProfileModal/EditProfileModal'))
const FavouritesModal = dynamic(() => import('./FavouritesModal/FavouritesModal'))
const HowToPrepareModal = dynamic(() => import('./HowToPrepareModal/HowToPrepareModal'))
const MarkdownModal = dynamic(() => import('./MarkdownModal/MarkdownModal'))
const MarketingPreferencesModal = dynamic(() => import('./MarketingPreferencesModal/MarketingPreferencesModal'))
const PaymentMethodsModal = dynamic(() => import('./PaymentMethodsModal/PaymentMethodsModal'))
const ProfessionalProfileModal = dynamic(() => import('./ProfessionalProfileModal/ProfessionalProfileModal'))
const ProfessionalSearchModal = dynamic(() => import('./ProfessionalSearchModal/ProfessionalSearchModal'))
const AddCodeModal = dynamic(() => import('./AddCodeModal/AddCodeModal'))
const PurchaseGiftCardModal = dynamic(() => import('./PurchaseGiftCardModal/PurchaseGiftCardModal'))
const RedeemGiftCardModal = dynamic(() => import('./RedeemGiftCardModal/RedeemGiftCardModal'))
const ReviewModal = dynamic(() => import('./ReviewModal/ReviewModal'))
const ReviewsModal = dynamic(() => import('./ReviewsModal/ReviewsModal'))
const TipProfessionalModal = dynamic(() => import('./TipProfessionalModal/TipProfessionalModal'))
const BookingInstructionsModal = dynamic(() => import('./BookingInstructionsModal/BookingInstructionsModal'))
const InformationModal = dynamic(() => import('./InformationModal/InformationModal'))
const BookingRequestedModal = dynamic(() => import('./BookingRequestedModal/BookingRequestedModal'))
const TreatmentModal = dynamic(() => import('./TreatmentModal/TreatmentModal'))
const SelectAvailableProModal = dynamic(() => import('./BookingRequestedModal/SelectAvailableProModal'))
const ExtraTreatmentsModal = dynamic(() => import('./ExtraTreatmentsModal/ExtraTreatmentsModal'))
const ParkingInformationModal = dynamic(() => import('./ParkingInformationModal/ParkingInformationModal'))
const ProfessionalQualityModal = dynamic(() => import('./ProfessionalQualityModal/ProfessionalQualityModal'))
const VouchersModal = dynamic(() => import('./VouchersModal/VouchersModal'))
const ChatsModal = dynamic(() => import('./ChatsModal/ChatsModal'))

const modalRouteNames = {
  account: AccountModal,
  favourites: FavouritesModal,
  bookings: BookingsModal,
  addCard: AddCardModal,
  addresses: AddressesModal,
  addEditAddress: AddressModal,
  auth: AuthModal,
  extraTreatments: ExtraTreatmentsModal,
  bookingDetails: BookingModal,
  support: ContactSupportModal,
  editClientDetails: EditClientDetailsModal,
  editProfile: EditProfileModal,
  treatmentPreparation: HowToPrepareModal,
  page: MarkdownModal,
  marketingPreferences: MarketingPreferencesModal,
  paymentMethods: PaymentMethodsModal,
  professionalProfile: ProfessionalProfileModal,
  professionalSearch: ProfessionalSearchModal,
  addCode: AddCodeModal,
  giftCard: PurchaseGiftCardModal,
  redeemGiftCard: RedeemGiftCardModal,
  review: ReviewModal,
  reviews: ReviewsModal,
  tipProfessional: TipProfessionalModal,
  bookingInstructions: BookingInstructionsModal,
  bookingRequested: BookingRequestedModal,
  selectAvailablePro: SelectAvailableProModal,
  treatment: TreatmentModal,
  parkingInformation: ParkingInformationModal,
  vouchers: VouchersModal,
  chat: ChatModal,
  chats: ChatsModal,
}

const stripeEnabledRoutes = ['addCard', 'tipProfessional', 'extraTreatments', 'giftCard', 'bookingDetails', 'review']
const stripePromise = loadStripe(process.env.PUBLISHABLE_STRIPE_KEY ?? '')

@inject('store')
@observer
class ModalScreens extends React.Component<{ store?: RootStore }> {
  render() {
    const { readyStatus, modalController, uiStore } = this.props.store!

    if (readyStatus === 0) {
      return null
    }

    const modalsToShow = modalController.modalPropStack
      .filter(modalProps => modalRouteNames.hasOwnProperty(modalProps.name))

    return <>
      {/* Modals */}
      <TransitionGroup>
        {modalsToShow.map(modalProps => {
          const Component = modalRouteNames[modalProps.name]

          return <Transition key={modalProps.key} timeout={300}>
            {state => {
              const open = ['entering', 'entered'].includes(state)

              if (stripeEnabledRoutes.includes(modalProps.name)) {
                return <Elements stripe={stripePromise}>
                  <Component modalProps={modalProps} open={open} />
                </Elements>
              } else {
                return <Component modalProps={modalProps} open={open} />
              }
            }}
          </Transition>
        })}
      </TransitionGroup>

      {/* To be refactored like the other modals */}
      <InformationModal />
      <PostcodePopup />
      <RatingPopup />
      <RebookPopup />
      <ProfessionalQualityModal />

      {/* Must be on top! */}
      <GenericPopup uiStore={uiStore} />
    </>
  }
}

export default ModalScreens
