import { action, computed, observable } from 'mobx'
import ListStore from '../../../shared-lib/stores/ListStore'
import listeners from '../../graph/definition/Listeners'
import BookingModel from '../BookingStore/BookingModel'
import ChatModel from './ChatModel'

export default class ChatStore extends ListStore<ChatModel> {
  fetchMethodName = 'chats'
  ModelClass = ChatModel
  @observable unreadMessages = 0

  @computed get itemsSortedByLastMessage() {
    return this.items.slice().sort((a, b) => a.data.lastMessage.sentAt - b.data.lastMessage.sentAt)
  }

  @action.bound updateUnreadMessages(messages: number) {
    this.unreadMessages = messages
  }

  @action.bound checkForNewUnreads = async () => {
    const { uiStore, authStore } = this.rootStore

    const previousUnreads = this.unreadMessages
    if (authStore.isLoggedIn) {
      await this.getUnreadMessages_api()
    }

    if (previousUnreads < this.unreadMessages) uiStore.isUnreadMessageCardVisible = false
  }

  @action.bound getUnreadMessages_api = async () => {
    const { communication: { requester } } = this.rootStore

    const { getUnreadMessages } = await requester.getUnreadMessages()

    if (!getUnreadMessages) return

    this.unreadMessages = getUnreadMessages
  }

  @action.bound dismiss = () => {
    const { uiStore } = this.rootStore

    uiStore.isUnreadMessageCardVisible = true
    this.syncInStorage()
  }

  @action.bound findOrCreateChat_api = async (professionalId: string) => {
    const { communication: { requester } } = this.rootStore

    const existingChat = this.items.find(chat => chat.data.professionalId === professionalId)

    if (existingChat) return existingChat.data._id

    this.loading = true

    try {
      const { findOrCreateChat } = await requester.findOrCreateChat({ professionalId })

      if (!findOrCreateChat) return null

      this.mergeData([findOrCreateChat])

      return findOrCreateChat._id
    } catch {
      return null
    } finally {
      this.loading = false
    }

  }

  @action.bound findChatFromBooking = (booking: BookingModel) => {
    const chat = this.items.find(item =>
      item.data.professionalId == booking.data.professional?._id,
    )

    return chat
  }

  @action.bound getUnreadMessagesFromBooking = (booking?: BookingModel) => {
    if (!booking) return 0

    const chat = this.findChatFromBooking(booking)

    return chat ? chat.data.messageCount.client.unreadMessages : 0
  }

  @action.bound listenOnChats_api() {
    const { communication, uiStore, userStore: { data: { _id } } } = this.rootStore
    const { mergeData, updateUnreadMessages } = this
    return communication.client.subscribe<Subscription>({
      query: listeners.chatUpdated,
      variables: { userId: _id },
    }).subscribe({
      next(res) {
        const data = res.data?.chatUpdated.chat
        const unread = res.data?.chatUpdated.clientUnreads
        if (typeof unread === 'number') updateUnreadMessages(unread)
        data && mergeData([data], false)
      },
      error(err: any) {
        uiStore.handleErrors(err)
      },
    })
  }

  syncInStorage = () => {
    this.rootStore.storeDataSync.saveStoreToStorage('CHAT')
  }
}

export type $ChatStore = ChatStore