import treatments from './elitePricing.json'

export interface BasketCalculationItem {
  _id: string
  treatment: {
    _id?: string
    price?: number
    fee?: number
    optionGroups?: {
      name: string
      multiSelect: boolean
      defaultIndex: number | null
      options: {
        _id?: string
        selected?: boolean
        price?: number
        fee?: number
      }[],
    }[],
  },
}

export function getEliteCostsForTreatment(treatmentId?: string): { price: number, fee: number } {
  if (!treatmentId) return { price: 0, fee: 0 }

  const treatment = treatments.find(treatment => treatment._id === treatmentId)

  if (!treatment) return { price: 0, fee: 0 }

  return { price: treatment.price, fee: treatment.fee }
}

export function calculateEliteCostsForBasket(items: BasketCalculationItem[]) {
  const costs = { price: 0, fee: 0 }

  items.map(item => {
    const itemCosts = getEliteCostsForTreatment(item.treatment._id)
    costs.fee += Math.max(itemCosts.fee - (item.treatment.fee || 0), 0)
    costs.price += Math.max(itemCosts.price - (item.treatment.price || 0), 0)

    if (!!item.treatment.optionGroups) {
      item.treatment.optionGroups.map(group => group.options.map(option => {
        if (option.selected) {
          const optionCosts = getEliteCostsForTreatment(option._id)
          costs.fee += Math.max(optionCosts.fee - (option.fee || 0), 0)
          costs.price += Math.max(optionCosts.price - (option.price || 0), 0)
        }
      }))
    }
  })

  return costs
}