import { action, computed, observable } from 'mobx'
import { $Postcode } from '../../handlers/ServiceAreaHandler'
import { itemDuration, itemPrice } from '../../helpers/bookingItemHelpers'
import { $Professional } from '../ProfessionalStore/ProfessionalModel'
import { $Treatment } from '../TreatmentStore/TreatmentModel'
import { $UserAddress } from '../UserAddressStore/UserAddressModel'
import { $User } from '../UserStore/UserStore'
import ModelStore from '../../../shared-lib/stores/ModelStore'
import momentTz from 'moment-timezone'
import { professionalOrStatusStringHelper } from '../../helpers/bookingStatusHelper'

interface $PromoCodeLimits {
  availableToIds?: Array<string>
  unavailableToIds?: Array<string>
  includedTreatmentIds?: Array<string>
  excludedTreatmentIds?: Array<string>
  availableTo?: Array<$User>
  unavailableTo?: Array<$User>
  includedTreatments?: Array<$Treatment>
  excludedTreatments?: Array<$Treatment>
  bookingsCount?: number
  bookingsCountCondition?: string
  minTreatmentCount?: number
  minBasketValue?: number
  perCustomerCap?: number
  totalAvailable?: number
  days?: Array<number>
  hours?: Array<number>
  regions?: Array<string>
}

interface $PromoCodeRedeemAction {
  givenBy: string
  action: string
  receiveAmount: number
}

export interface $PromoCode {
  _id?: string
  promoCode: string
  title?: string
  description?: string
  createdAt: number
  startDate: number
  expiryDate?: number
  limits: $PromoCodeLimits
  redeemAction?: $PromoCodeRedeemAction
  active?: boolean
  discountType: string
  value: number
  addedBy?: string
  campaign?: string
}

export interface $ClientDetail {
  accountOwner: boolean
  firstName: string
  contactNumber: string
  gender: string
}

export interface $Booking {
  _id: string
  user: any
  clientReviewId?: string
  clientReview?: any
  professional?: $Professional
  status: string
  date?: Date
  address: Partial<$UserAddress>
  postcode: $Postcode
  postcodeId?: string
  items: Array<$BookingItem>
  note: string,
  totalPrice: number
  totalFee: number
  totalDuration: number
  promoCodeId?: string
  promoCodeDiscount: number
  promoCode?: $PromoCode
  title: string
  type: string
  requestedProfessionals: Array<$Professional>
  requestedProfessionalResponses: Array<$RequestedProfessionalResponse>
  clientDetails: Array<$ClientDetail>
  reviewPrompted: boolean
  transactions: Array<$BookingTransaction>
  adjustments: Array<$BookingAdjustment>
  region: string
  createdAt: Date
  timezone: string
  tags: Array<string>
  additionalInformation: any
  clientNote: string
  clientPictures: Array<string>
  totalLoyaltyPoints: number
  publishedDate: Date | null
  crossSellPromoCode?: string
}

export type $RequestedProfessionalResponseStatus = 'rejected' | 'accepted' | 'addedAvailability'

export type $AvailabilityAdded = {
  day: string
  times: Array<number>
}

export interface $RequestedProfessionalResponse {
  professionalId: string
  status: $RequestedProfessionalResponseStatus
  respondedAt: Date
  availabilityAdded: Array<$AvailabilityAdded>
}

export interface $BookingItem {
  _id: string
  treatment: $BookingItemTreatment
}

export interface $BookingItemTreatment extends Partial<$Treatment> {
  _id?: string
}

export interface $BookingTransaction {
  _id: string
  sourceType: string
  amount: number
}

export interface $BookingAdjustment {
  _id: string
  amount: number
  payoutDrafted: boolean
  professionalId: string
  adjustmentType: 'bonus' | 'tip' | 'penalty' | 'feeAdjustment'
  note: string
}

export interface NewReviewData {
  feedback: string
  comment: string
  professionalId: string
  bookingId: string
  rating: number
  reviewer: 'client'
  wouldRebookPro?: boolean
}

export type $BookingModel = BookingModel

const defaults = {
  _id: '',
  status: '',
  items: [],
  note: '',
  user: {},
  totalPrice: 0,
  totalFee: 0,
  totalDuration: 0,
  promoCode: undefined,
  promoCodeDiscount: 0,
  address: {},
  postcode: {
    _id: '',
    code: '',
    name: '',
    region: '',
  },
  postcodeId: '',
  clientDetails: [{
    accountOwner: true,
    firstName: '',
    contactNumber: '',
    gender: '',
    note: '',
  }],
  professional: undefined,
  professionalId: '',
  requestedProfessionals: [],
  requestedProfessionalResponses: [],
  title: '',
  reviewPrompted: false,
  transactions: [],
  adjustments: [],
  type: 'sendToAll',
  region: '',
  date: undefined,
  createdAt: new Date(),
  timezone: 'Europe/London',
  tags: [],
  additionalInformation: {},
  clientNote: '',
  clientPictures: [],
  totalLoyaltyPoints: 0,
  publishedDate: null,
}

export default class BookingModel extends ModelStore {
  @observable data: $Booking = { ...defaults }

  fetchMethodName = 'booking'

  // Check if requested a favourite OR if professional is favourite
  @computed get isFavourite(): boolean {
    const { professionalIsFavourite } = this.rootStore.favouriteStore
    const { professional, requestedProfessionals } = this.data

    const pro = professional || (requestedProfessionals.length > 0 && requestedProfessionals[0])
    return pro && professionalIsFavourite(pro._id)
  }

  // Get date with timezone
  @computed get dateTimezone() {
    return momentTz(this.data.date).tz(this.data.timezone)
  }

  @computed get timezoneAbbreviation() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone === this.data.timezone ? '' : `(${momentTz(this.data.date).tz(this.data.timezone).format('z')})`
  }

  // Format date with time
  @computed get formattedDateWithTime(): string {
    return this.dateTimezone.format('ddd Do MMM, H:mm')
  }

  // Format date without time
  @computed get formattedDateWithoutTime(): string {
    return this.dateTimezone.format('ddd Do MMM, YYYY')
  }

  @computed get unreadMessages(): number {
    const { chatStore: { findChatFromBooking } } = this.rootStore

    const chat = findChatFromBooking(this)

    if (!chat) return 0

    return chat.data.messageCount.client.unreadMessages
  }

  // Treatment and professional summary
  @computed get summary(): string {
    const { title, professional } = this.data

    let str = title
    if (professional) str += ` with ${professional.firstName}`

    return `${str}.`
  }

  // Format month and year e.g. January 2020
  @computed get monthYearString(): string {
    return this.dateTimezone.format('MMMM YYYY')
  }

  // Format date + booking duration
  @computed get formattedDateTimeBrackets(): string {
    const startTime = this.dateTimezone.format('HH:mm')
    const endTime = momentTz(this.dateTimezone).add(this.totalDurationComputed, 'minutes').format('HH:mm')
    return `${startTime} - ${endTime}`
  }

  @computed get bookingIsEditable() {
    return ['ACCEPTED', 'APPROVAL_PENDING'].includes(this.data.status)
      && momentTz().add(24, 'hours').isBefore(this.dateTimezone)
  }

  // Return tue if has address
  @computed get hasAddress(): boolean {
    const { address } = this.data
    if (address && address._id) return true
    return false
  }

  // Calculate duration
  @computed get totalDurationComputed() {
    return this.data.items.reduce((a: number, b: $BookingItem) => {
      return a + itemDuration(b.treatment)
    }, 0)
  }

  @computed get treatmentsWithoutDiscount() {
    return this.data.items.filter(item => item.treatment.isTreatment)
  }

  @computed get totalPriceComputed() {
    return this.data.items.reduce((a: number, b: $BookingItem) => {
      return a + itemPrice(b.treatment)
    }, 0)
  }

  @computed get totalPriceWithoutDiscounts() {
    return this.treatmentsWithoutDiscount.reduce((a: number, b: $BookingItem) => {
      return a + itemPrice(b.treatment)
    }, 0)
  }

  // Request cancellation
  @action.bound async requestBookingCancellation_api(): Promise<number | null> {
    try {
      const ret = await this.rootStore.communication.requester.requestBookingCancellation({ _id: this.data._id })
      const percentage = ret.requestBookingCancellation
      return percentage === undefined ? null : percentage
    } catch (e) {
      return null
    }
  }

  // Clear data but retain address
  @action.bound clear() {
    const address = this.data.address
    const region = this.data.region
    const postcodeId = this.data.postcodeId
    const postcode = this.data.postcode
    const clientDetails = this.data.clientDetails

    this.data = { ...defaults, address, postcodeId, region, postcode, clientDetails }
  }

  // Clear all data, used on sign out
  @action.bound resetToDefaults() {
    this.data = { ...defaults }
  }

  // Cancel a booking
  @action.bound async cancelBooking_api(): Promise<boolean | null> {
    const { uiStore } = this.rootStore

    try {
      const ret = await this.rootStore.communication.requester.cancelBooking({ _id: this.data._id })
      const refundSuccessful = ret.cancelBooking || false

      // Update booking status to cancelled
      this.data.status = 'CANCELLED'

      // Stripe refund failed
      if (!refundSuccessful) {
        uiStore.handleErrors({ code: 'BOOKING_ERROR_REFUND_BOOKING' })
      }

      return refundSuccessful
    } catch (e) {
      return null
    }
  }

  // Submit review to API
  async createReview_api(data: NewReviewData): Promise<boolean> {
    this.loading = true
    try {
      const ret = await this.rootStore.communication.requester.createReview({ data: data as NewReview })
      const createReview = ret.createReview

      if (createReview) {
        // Add review _id to booking
        this.set({ clientReviewId: createReview })
      }

      this.loading = false
      return !!createReview
    } catch (e) {
      this.loading = false
      return false
    }
  }

  // Update review prompted true
  async updateReviewPrompted_api(_id: string): Promise<boolean> {
    try {
      await this.rootStore.communication.requester.updateReviewPrompted({ _id, reviewPrompted: true })

      // Update reviewPrompted to true locally
      this.set({ reviewPrompted: true })

      return true
    } catch (e) {
      return false
    }
  }

  @action.bound async selectProfessionalForRequest_api(professionalId: string, date: Date) {
    const args = {
      _id: this.data._id,
      professionalId,
      date,
    }

    this.loading = true

    try {
      const ret = await this.rootStore.communication.requester.selectProfessionalForRequest(args)
      const booking = ret.selectProfessionalForRequest || {}
      this.merge(booking)
      this.loading = false
      return true
    } catch (e) {
      this.loading = false
      return false
    }
  }

  // Computes if booking is for someone other than the account owner who is making the booking
  @computed get isForSomeoneElse(): boolean {
    const clientDetail = this.data.clientDetails[0]
    return clientDetail && clientDetail.accountOwner === false
  }

  // Computes if it has a promo code
  @computed get appliedPromoCode(): string {
    return this.data.promoCode?.promoCode || ''
  }

  // Computes if booking can be reviewed
  @computed get canReview(): boolean {
    return this.data.status === 'COMPLETED' && !this.data.clientReviewId
  }

  // Computes if cancel
  @computed get canCancel(): boolean {
    const invalidStatuses: Array<string> = ['IN_TRANSIT', 'ARRIVED', 'COMPLETED', 'CANCELLED']

    return !invalidStatuses.includes(this.data.status)
  }

  // Get total paid
  @computed get totalPaid(): number {
    let totalPaid = 0
    this.data.transactions.forEach((t: $BookingTransaction) => {
      if (t.sourceType === 'card') totalPaid += (t.amount * -1)
    })
    return totalPaid
  }

  // Get credits
  @computed get creditsUsed(): number {
    let totalCredits = 0
    this.data.transactions.forEach((t: $BookingTransaction) => {
      if (t.sourceType !== 'card') totalCredits += (t.amount * -1)
    })
    return totalCredits
  }

  // Get total tipped
  @computed get totalTipped(): number {
    let totalTipped = 0
    this.data.adjustments.forEach((adjustment: $BookingAdjustment) => {
      if (adjustment.adjustmentType === 'tip') totalTipped += adjustment.amount
    })
    return totalTipped
  }

  @computed get professionalOrStatusString() {
    const { status, type, requestedProfessionals } = this.data
    const { i18n: { s } } = this.rootStore

    return professionalOrStatusStringHelper(
      status,
      type,
      this.requestedProfessional,
      requestedProfessionals.length > 0,
      s,
      this.containsMassage)
  }

  @computed get respondedProfessionals() {
    return this?.data.requestedProfessionalResponses.filter(r => {
      return r.status === 'addedAvailability'
    }) || []
  }

  @computed get containsMassage() {
    return !!this.data.items.some(item => item.treatment.type === 'massage')
  }

  @computed get treatmentIds(): Array<string> {
    const { items } = this.data
    const ids = items.map((item: $BookingItem) => {
      return item.treatment._id
    })

    return ids.filter(Boolean) as Array<string>
  }

  @computed get optionIds(): Array<string> {
    const { items } = this.data
    const ids: Set<string> = new Set()

    items.forEach((item: $BookingItem) => {
      item.treatment.optionGroups?.forEach((optionGroup: any) => {
        optionGroup.options?.forEach((option: any) => {
          if (option._id && option.selected) {
            ids.add(option._id)
          }
        })
      })
    })

    return [...ids]
  }

  @computed get requestedProfessional() {
    const { professional, requestedProfessionals } = this.data
    return professional ? professional : requestedProfessionals.length === 1 ? requestedProfessionals[0] : undefined
  }

  get canContactProfessional() {
    return !!this.data.professional
  }

  toRequestData() {
    const { data } = this

    const promoCodeId = data.promoCode?._id ? data.promoCode._id : null
    const requestedProfessionalIds = this.data.requestedProfessionals.map((p: any) => p._id)
    const userId = data.user ? data.user._id : undefined
    const professionalId = data.professional ? data.professional._id : null
    const postcodeId = data.postcode._id || data.postcodeId
    const _id = data._id || undefined

    return {
      _id,
      userId,
      professionalId,
      date: data.date,
      address: data.address,
      postcodeId,
      items: data.items,
      promoCodeId,
      promoCodeDiscount: data.promoCodeDiscount,
      clientDetails: data.clientDetails,
      requestedProfessionalIds,
      note: data.note,
      type: data.type,
      region: data.region,
      additionalInformation: data.additionalInformation,
      clientNote: data.clientNote,
      clientPictures: data.clientPictures,
      tags: data.tags,
    }
  }
}
