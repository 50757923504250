import { inject, observer } from 'mobx-react'
import React from 'react'

import { Box, IconButton, Typography, WithStyles, withStyles } from '@material-ui/core'

import RootStore from '../src/stores/RootStore'
import { colors } from '../theme'
import { Close, Message } from '@material-ui/icons'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  unreadButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: theme.spacing(8),
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingRight: 0,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    display: 'flex',
    background: `linear-gradient(25deg, ${colors.PRI900}, ${colors.PRI910})`,
    transition: 'transform 200ms',
    transform: 'translateY(-80px)',
    pointerEvents: 'none',
    zIndex: 10000,
    '&:hover': {
      opacity: 0.9,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      top: 'auto',
      left: 'auto',
      width: 'auto',
      borderRadius: '50px',
      transform: 'translateY(80px)',
    },
  },
  visible: {
    cursor: 'pointer',
    transform: 'translateY(0)',
    pointerEvents: 'all',
  },
  icon: {
    color: 'white',
  },
  unreadCountText: {
    color: 'white',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}))

const ZendeskMessageAlert = function (props: Props) {
  const {
    store,
    classes,
  } = props
  const { zendeskHandler, i18n: { s, pluralise } } = store!

  const containerClassName = [
    classes.unreadButton,
    (zendeskHandler.unread > 0 ? classes.visible : ''),
  ].join(' ')

  const supportMessage = s('$0 new support $1', zendeskHandler.unread, pluralise('message', zendeskHandler.unread))

  return (
    <Box className={containerClassName}>
      <Box display='flex' onClick={zendeskHandler.openWindow}>
        <Message className={classes.icon} />
        <Typography className={classes.unreadCountText} variant='body2'>{supportMessage}</Typography>
      </Box>

      <IconButton onClick={zendeskHandler.dismissUnread}>
        <Close className={classes.icon} />
      </IconButton>
    </Box>
  )
}

export default withStyles(styles)(inject('store')(observer(ZendeskMessageAlert)))
