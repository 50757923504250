import { getEliteCostsForTreatment } from '../../shared-lib/eliteProfessionalHelpers'
import analytics from '../../src/stores/analytics'
import { $RootStore } from '../../src/stores/RootStore'
import { $BookingItem, $BookingItemTreatment } from '../stores/BookingStore/BookingModel'
import { $Treatment, $TreatmentOptionGroup } from '../stores/TreatmentStore/TreatmentModel'

// Toggle option on booking item, e.g. Add Chrome, 30/60 minutes
export const selectDefaultOptions = (treatment: $Treatment): any => {
  // setup options and defaults
  treatment.optionGroups.forEach((o: $TreatmentOptionGroup) => {
    o.options.forEach((option, i) => {
      option.selected = i === o.defaultIndex
    })
  })
}

// Toggle option on booking item, e.g. Add Chrome, 30/60 minutes
export const toggleOption = (treatment: $Treatment, groupName: string, optionId: any): any => {
  const optionGroup: any = treatment.optionGroups.find((o: any) => o.name === groupName)

  if (!optionGroup) return [] // err

  const changedOptions: any = {
    added: [],
    removed: [],
  }

  optionGroup.options.forEach((o: $Treatment) => {
    const { name } = o

    if (o._id === optionId) {
      if (!optionGroup.multiSelect) {
        // You can only select multiple options in multi select
        const oldValue = o.selected

        if (oldValue === false) {
          changedOptions.added.push(name)
          o.selected = true
        }
      } else {
        // otherwise toggle it
        o.selected = !o.selected
        if (o.selected) {
          changedOptions.added.push(name)
        } else {
          changedOptions.removed.push(name)
        }
      }
    } else if (!optionGroup.multiSelect) {
      // if unique set others to false
      if (o.selected === true) {
        changedOptions.removed.push(name)
      }

      o.selected = false
    }
  })

  return changedOptions
}

export const eliteItemPrice = (treatment: $Treatment | $BookingItemTreatment): number => {
  if (!treatment) return 0

  let total: number = (treatment.price || 0) + getEliteCostsForTreatment(treatment._id).price

  treatment.optionGroups && treatment.optionGroups.forEach((og: $TreatmentOptionGroup) => {
    og.options.forEach(o => {
      if (o.selected) total += getEliteCostsForTreatment(o._id).price + (o.price || 0)
    })
  })

  return total
}

// Computes item total
export const itemPrice = (treatment: $Treatment | $BookingItemTreatment): number => {
  if (!treatment) return 0

  let total: number = treatment.price || 0

  treatment.optionGroups && treatment.optionGroups.forEach((og: $TreatmentOptionGroup) => {
    og.options.forEach(o => {
      if (o.selected) total += o.price || 0
    })
  })

  return total
}

// Computes item duration
export const itemDuration = (treatment: $Treatment | $BookingItemTreatment): number => {
  if (!treatment) return 0

  let total: number = treatment.duration || 0

  treatment.optionGroups && treatment.optionGroups.forEach((og: $TreatmentOptionGroup) => {
    og.options.forEach(o => {
      if (o.selected) total += o.duration || 0
    })
  })

  return total
}

// Computes item description
export const itemDescription = (treatment: $Treatment | $BookingItemTreatment): string => {
  if (!treatment) return ''

  const strs: Array<string> = []

  treatment.optionGroups && treatment.optionGroups.forEach((og: $TreatmentOptionGroup) => {
    og.options.forEach(o => {
      if (o.selected) strs.push(o.name || '')
    })
  })

  return strs.join(', ')
}

export const itemHasSelectedOptions = (treatment: $Treatment | $BookingItemTreatment) => {
  let count = 0

  treatment.optionGroups && treatment.optionGroups.forEach((og: $TreatmentOptionGroup) => {
    og.options.forEach(o => {
      if (o.selected) count++
    })
  })

  return count > 0
}

export const getItemTreatmentTypes = (items: Array<$BookingItem>) => {
  return items.reduce<Set<string>>((acc, item) => {
    acc.add(item.treatment.type || '')
    return acc
  }, new Set())
}

export const checkTreatmentIssues = (treatment: $Treatment, rootStore: $RootStore, finishAdding: () => void) => {
  const {
    checkoutStore: {
      booking: { data: { items } },
    },
    uiStore,
    i18n: { s },
  } = rootStore

  const types = getItemTreatmentTypes(items)

  types.add(treatment.type)
  types.delete('other')

  if (hasImpossibleCombination(types)) {
    uiStore.showMessage({
      title: s('TREATMENT_COMBINATIONS_IMPOSSIBLE_TITLE'),
      body: s('TREATMENT_COMBINATIONS_IMPOSSIBLE_BODY'),
      buttons: [
        {
          title: s('OK'),
        },
      ],
    })
  } else if (types.size >= 3) {
    uiStore.showMessage({
      title: s('TREATMENT_COMBINATIONS_MANY_TITLE'),
      body: s('TREATMENT_COMBINATIONS_MANY_BODY'),
      buttons: [
        {
          title: s('Continue'),
          onPress: async () => {
            analytics.track('Multiple Treatments Decision', { continued: true })
            finishAdding()
          },
        },
        {
          title: s('Cancel'),
          onPress: async () => {
            analytics.track('Multiple Treatments Decision', { continued: false })
          },
        },
      ],
    })

  } else {
    finishAdding()
  }
}

export const hasImpossibleCombination = (types: Set<string>) => {
  if (types.size === 1) return false

  const allTypes = [...Array.from(types)]
  let typesAllowed = [...allTypes]

  if (types.has('hair-cutting') || types.has('hair-colouring')) {
    typesAllowed = ['hair',
      'hair-cutting',
      'hair-styling',
      'hair-colouring',
    ]

  } else if (types.has('hair-styling')) {
    typesAllowed = ['hair',
      'hair-cutting',
      'hair-styling',
      'hair-colouring',
      'make-up',
    ]
  }

  return allTypes.filter(type => !typesAllowed.includes(type))
    .filter(type => types.has(type)).length > 0
}