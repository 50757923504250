/*
  Fix for bug where, sometimes on ios webkit, after closing a modal
  the page content would become unrespsonive. Upon investigation it 
  seemed to be a side effect of setting overflow: hidden on the body
  which is required to prevent body scrolling while the modal is open. 
  
  When the bug occures you can see in the webkit debugger layers tab 
  that the body scroll position has been reset to 0, despite the
  visbile content looking as though the page has a positive scroll
  position. 

  This fix aims to record the scrollY when the modal is opened and reset
  it after the modal is closed.
*/

let scrollY = 0

export const recordWindowScrollY = () => {
  scrollY = window.scrollY
}

export const resetWindowScrollY = () => {
  setTimeout(() => {
    window.scroll({ top: scrollY })
  }, 100)
}