import { $RootStore } from '../../src/stores/RootStore'

export default class SubscriptionHandlerBase {
  store: $RootStore
  bookingsSubscription: ZenObservable.Subscription | undefined = undefined
  chatsSubscription: ZenObservable.Subscription | undefined = undefined

  constructor(store: $RootStore) {
    this.store = store
  }

  // Overwrite in CA and WB platforms
  listenToRouteChange() {
    return
  }

  // Overwrite in CA and WB platforms
  shouldSubscribeTo(_route: string): string[] {
    return []
  }

  routeDidChange = async (route: string) => {
    const { bookingStore, communication, chatStore, authStore } = this.store as any

    if (!authStore.isLoggedIn || route === '') return

    const shouldSubscribe = this.shouldSubscribeTo(route)

    if (!!shouldSubscribe.length) {
      const connectTo = []
      if (shouldSubscribe.includes('Bookings') && !this.bookingsSubscription) connectTo.push('Bookings')
      if (shouldSubscribe.includes('Chats') && !this.chatsSubscription) connectTo.push('Chats')

      if (connectTo.length > 0) {
        communication.connectWSLink()

        if (connectTo.includes('Bookings')) {
          this.bookingsSubscription = await bookingStore.listenOnBookings_api()
        }

        if (connectTo.includes('Chats')) {
          this.chatsSubscription = await chatStore.listenOnChats_api()
        }
      }
    }

    if (!shouldSubscribe.length) {
      if (!shouldSubscribe.includes('Bookings') && !!this.bookingsSubscription) {
        this.bookingsSubscription.unsubscribe()
        this.bookingsSubscription = undefined
      }

      if (!shouldSubscribe.includes('Chats') && !!this.chatsSubscription) {
        this.chatsSubscription.unsubscribe()
        this.chatsSubscription = undefined
      }

      communication?.closeWSLink()
    }
  }
}