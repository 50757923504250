import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { inject, observer } from 'mobx-react'
import RootStore from '../src/stores/RootStore'

interface Props {
  store?: RootStore
}

const DropDownMessage = (props: Props) => {
  const {
    dropdownMessageHandler: { visible, hideDropdownMessage, messageData },
    i18n: { s },
  } = props.store!

  const handleClose = () => {
    messageData?.onTap && messageData?.onTap()
    hideDropdownMessage()
  }

  // The RN dropdown component uses 'warn' so we need to convert to 'warning' for MUI
  const severity = messageData.type === 'warn' ? 'warning' : messageData.type

  return <Snackbar
    open={visible}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert onClose={handleClose} severity={severity}>
      {!!messageData?.message && s(messageData.message)}
      {messageData?.message && messageData?.title ? ' - ' : ''}
      {!!messageData?.title && s(messageData.title)}
    </Alert>
  </Snackbar>
}

export default (inject('store')(observer(DropDownMessage)))