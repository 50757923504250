import React from 'react'
import Head from 'next/head'

interface PageTitleProps {
  title?: string
}

const PageTitle = (props: PageTitleProps) => {
  const { title = '' } = props

  return (
    <Head>
      {title !== '' && <title>{title} - Secret Spa</title>}
    </Head>
  )
}

export default PageTitle