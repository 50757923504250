import { computed } from 'mobx'

import ListStore from '../../../shared-lib/stores/ListStore'
import ReviewModel, { $ReviewModel } from './ReviewModel'

export default class ReviewStore extends ListStore<$ReviewModel> {
  fetchMethodName = 'reviews'
  ModelClass = ReviewModel
  defaultFetchVariables = {
    hidden: false,
  }

  @computed get summaryItems() {
    return [...this.items].splice(0, 5)
  }
}

export type $ReviewStore = ReviewStore
