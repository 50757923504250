import React from 'react'
import Image from 'next/image'
import { Box, Typography, WithStyles, withStyles } from '@material-ui/core'
import { colors, theme } from '../theme'
import TextButton from './TextButton'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  title: string
  icon?: StaticImageData
  onClick?: () => any
}

const styles = styleCreate(theme => ({
  listButton: {
    cursor: 'pointer',
    borderBottom: `1px solid ${colors.LINE}`,
    height: theme.spacing(10),
    display: 'flex',
    alignContent: 'center',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      width: '100%',
    },
    '&:hover': {
      opacity: 0.5,
    },
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
}))

const ListButton = (props: Props) => {
  const { title, icon, classes, onClick } = props

  return <Box className={classes.listButton}>
    <TextButton onClick={onClick}>
      <Box className={classes.icon}>
        <Image src={icon} alt={title} width={theme.spacing(5)} height={theme.spacing(5)} priority />
      </Box>
      <Typography variant='subtitle1'>{title}</Typography>
    </TextButton>
  </Box>
}

export default withStyles(styles)(ListButton)
