import { observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import { $Postcode } from '../../handlers/ServiceAreaHandler'
import { minutesToMs } from '../../lib/utils'
import { $Treatment } from '../TreatmentStore/TreatmentModel'

interface Information {
  appliedDate: string
  careerHighlights: string
  careerStartDate: string
  city: string
  employmentHistory: string
  instagram: string
  insuranceExpiryDate: string
  postalCode: string
  qualifications: string
  website: string
  status?: string
}

export interface $ProfessionalProfile {
  user?: $Professional
  userId: string
  bio: string
  picture: string
  favouriteCount: number
  reviewCount: number
  averageReview: number
  featured?: boolean
  treatments: Array<Partial<$Treatment>>
  postcodes: Array<$Postcode>
  isFavourite?: boolean
  information?: Information
  disabledOptionIds?: Array<string>
  genderPreference: string
  numberOfBookings: number
  lastBookedAt?: Date
  teamNote?: string
}

export interface $Professional {
  _id: string
  firstName: string
  lastName: string
  tags: string[]
  profileUrl?: string
  professionalProfile?: $ProfessionalProfile
}

export type $ProfessionalModel = ProfessionalModel

export const DefaultProfessionalData = {
  _id: '',
  firstName: '',
  lastName: '',
  blocked: false,
  tags: [],
  profileUrl: '',
  professionalProfile: {
    userId: '',
    bio: '',
    picture: '',
    favouriteCount: 0,
    reviewCount: 0,
    averageReview: 5,
    treatments: [],
    postcodes: [],
    isFavourite: false,
    genderPreference: 'none',
    numberOfBookings: 0,
  },
}

export default class ProfessionalModel extends ModelStore {
  @observable data: $Professional = DefaultProfessionalData
  cacheLength = minutesToMs(15)
  fetchMethodName = 'getProfessional'
}
