import { reaction } from 'mobx'
import SubscriptionHandlerBase from '../../shared-lib/communication/SubscriptionHandlerBase'

const bookingRoutes = [
  '#bookings',
  '#bookingRequested',
  '#bookingDetails',
  '/booking/confirmation',
]

const chatRoutes = [
  '#chats',
  '#chat',
  '/categories',
  '#bookings',
  '#bookingDetails',
]

export default class SubscriptionHandler extends SubscriptionHandlerBase {
  listenToRouteChange() {
    this.store.router.events.on('routeChangeComplete', (url: string) => {
      this.routeDidChange(url)
    })

    reaction(
      () => this.store.modalController.windowHash,
      windowHash => {
        this.routeDidChange(windowHash)
      })
  }

  shouldSubscribeTo(route: string) {
    const arr = []

    if (bookingRoutes.some(r => route.includes(r))) arr.push('Bookings')
    if (chatRoutes.some(r => route.includes(r))) arr.push('Chats')
    return arr
  }
}