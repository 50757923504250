import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Image from 'next/image'

import { Box, Typography, WithStyles, withStyles } from '@material-ui/core'

import LocationSmallBlack from '../../public/img/icons/locationSmallBlack.png'
import RootStore from '../../src/stores/RootStore'
import { colors, theme } from '../../theme'
import TextButton from '../TextButton'
import { upperCase } from 'voca'
import { styleCreate } from '../../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  iconContainer: {
    marginRight: theme.spacing(2),
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: colors.BG100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2.5),
    display: 'flex',
  },
}))

@inject('store')
@observer
class BookingPostcode extends Component<Props> {
  onClick = () => {
    const {
      userAddressStore,
      authStore,
      modalController,
      uiStore,
    } = this.props.store!

    const hasAddresses = authStore.isLoggedIn && userAddressStore.items.length > 0
    uiStore.toggleNavVisible(false)

    if (hasAddresses) {
      // Show addresses
      modalController.openModal('addresses/select')
    } else {
      uiStore.showPostCodeDialog()
    }
  }

  render() {
    const { store, classes } = this.props
    const {
      checkoutStore: { booking },
      i18n: { s },
    } = store!

    const { postcode } = booking.data

    if (!postcode.code) {
      return null
    }

    return (
      <Box display='flex' mt={2} mb={2} alignItems='center' justifyContent='space-between'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box className={classes.iconContainer}>
            <Image src={LocationSmallBlack} alt={'location'} width={theme.spacing(3)} height={theme.spacing(3)} priority />
          </Box>
          <Typography variant='body2'>{upperCase(postcode.code)}</Typography>
        </Box>
        <TextButton onClick={this.onClick}>{s('Change')}</TextButton>
      </Box>
    )
  }
}

export default withStyles(styles)(BookingPostcode)
