import { action, computed, observable, observe } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Dialog, DialogContent, Typography, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { $Postcode } from '../shared-consumer/handlers/ServiceAreaHandler'
import analytics from '../src/stores/analytics'
import RootStore from '../src/stores/RootStore'
import { gradients } from '../theme'
import { FormTextField, RectButton } from './'
import TextButton from './TextButton'
import { upperCase } from 'voca'
import { styleCreate } from '../lib/styleCreate'

const styles = styleCreate(theme => ({
  inner: {
    padding: theme.spacing(6),
    maxWidth: 340,
    marginTop: 100,
    [theme.breakpoints.between('xs', 'xs')]: {
      padding: theme.spacing(4),
      marginTop: -10,
      maxWidth: '100%',
    },
  },
  input: {
    width: 200,
    [theme.breakpoints.between('xs', 'xs')]: {
      alignSelf: 'center',
    },
  },
  content: {
    background: `url(/img/graphics/serviceMap.png) no-repeat, ${gradients.POSTCODE_POPUP}`,
    backgroundSize: '300px, auto',
    backgroundPosition: 'right 40px top 40px, 0',
    width: '90vw',
    maxWidth: 800,
    height: 500,
    [theme.breakpoints.between('xs', 'xs')]: {
      backgroundSize: '70%, auto',
      backgroundPosition: 'center bottom 60px, 0',
      maxWidth: '100%',
      height: '70vh',
      textAlign: 'center',
    },
  },
  continueWithoutLocation: {
    padding: theme.spacing(6),
    bottom: 0,
    position: 'absolute',
    [theme.breakpoints.between('xs', 'xs')]: {
      padding: theme.spacing(4),
      width: `calc(100% - ${theme.spacing(8)}px)`,
      textAlign: 'center',
    },
  },
}))

export interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

@inject('store')
@observer
class PostcodePopup extends Component<Props> {
  @observable isVisible = false
  @observable postcode = ''
  @observable fieldFocussed = false

  constructor(props: Props) {
    super(props)
    this.observeUiStore()
  }

  showModelAndInitState = async () => {
    this.postcode = ''
    this.showModal()
  }

  // Observe postCodeDialogVisible from uiStore
  observeUiStore = (): void => {
    const { uiStore } = this.props.store!

    if (uiStore.postCodeDialogVisible) {
      this.showModelAndInitState()
    }

    observe(uiStore, 'postCodeDialogVisible', ({ newValue }) => {
      if (newValue === true) {
        this.showModelAndInitState()
      }
    })
  }

  // Show modal
  showModal = (): void => {
    this.isVisible = true
  }

  // Hide modal
  hideModal = (): void => {
    this.isVisible = false
    const { uiStore } = this.props.store!
    uiStore.postCodeDialogVisible = false
  }

  @action.bound onChangeField = (_property: string, value: string) => {
    this.postcode = upperCase(value)
  }

  @action.bound onFocusField = () => {
    this.fieldFocussed = true
  }

  @action.bound onBlurField = () => {
    this.fieldFocussed = false
  }

  @computed get canSubmit() {
    return this.postcode.length > 4
  }

  // On press Add
  setPostcode = async (): Promise<void | boolean> => {
    const {
      checkoutStore,
      serviceAreaHandler,
      router,
      uiStore,
    } = this.props.store!

    if (!checkoutStore.validPostcode(this.postcode)) return uiStore.showInvalidPostcodePopup()

    if (!this.canSubmit) return

    // Check if postal code is in a serviceable area
    const postcodeRecord: $Postcode | undefined = await serviceAreaHandler.verifyPostcodeIsInServiceArea(this.postcode)

    if (postcodeRecord) {
      const { code, region } = postcodeRecord

      const result = serviceAreaHandler.postcodeChangeResult(postcodeRecord)
      checkoutStore.setBookingAddress(postcodeRecord)

      analytics.track('Postcode Set', { postalCode: upperCase(code), region })

      if (result === 'regionChanged') {
        checkoutStore.emptyBasket()
        router.reload()
        return
      }

      this.hideModal()
    }
  }

  continueWithoutLocation = () => {
    this.hideModal()
    analytics.track('Postcode Not Set')
  }

  onClose = (_event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      this.hideModal()
    }
  }

  render() {
    const { store, classes } = this.props
    const { i18n: { s }, serviceAreaHandler } = store!

    return (
      <Dialog
        open={this.isVisible}
        maxWidth={false}
        aria-label={this.isVisible ? 'postcode_popup' : ''}
        onClose={this.onClose}
      >
        <DialogContent className={classes.content}>
          <Box className={classes.inner}>
            <Typography variant='h3'>
              {s('Where would you like to book?')}
            </Typography>
            <br />

            <FormTextField
              property='postcode'
              value={this.postcode}
              onChange={this.onChangeField}
              onSubmit={this.setPostcode}
              placeholder={s('Enter your postcode')}
              className={classes.input}
              componentProps={{
                onFocus: this.onFocusField,
                onBlur: this.onBlurField,
              }}
            />

            {this.canSubmit && <Box className='animate__animated animate__slideInLeft animate__faster'>
              <RectButton
                onClick={this.setPostcode}
                color={'primary'}
                variant={'contained'}
                loading={serviceAreaHandler.loading}
              >
                {s('Continue')}
              </RectButton>
            </Box>}
          </Box>

          {!this.fieldFocussed &&
            <Box className={classes.continueWithoutLocation}>
              <TextButton onClick={this.continueWithoutLocation}>
                {s('Continue without setting location')}
              </TextButton>
            </Box>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(PostcodePopup)
