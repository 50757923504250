import { useMemo } from 'react'
import { $RootStore } from '../../src/stores/RootStore'

export default function useViewModel<T>(VM: new (store: $RootStore) => T, store: $RootStore): T {
  return useMemo(() => new VM(store), [store])
}

interface Props {
  store?: $RootStore
}

export function useViewModelWithProps<T, K extends Props>(VM: new (props: K) => T, props: K): T {
  return useMemo(() => {
    const viewModel = new VM(props)

    // TODO: Remove 'as any' when implement testUiStore on CA
    const { testUiStore } = props.store as any
    if (testUiStore && testUiStore?.isTesting) {
      testUiStore.addViewModel(viewModel)
    }

    return viewModel
  }, [props.store])
}