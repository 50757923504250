import { action, computed, observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import { minutesToMs, urlSafeString } from '../../lib/utils'
import { Descriptions } from './CategoryDescriptions'

export interface $Category {
  _id: string
  name: string
  treatmentIds: Array<string>
  slug: string
  description: string
  images: Array<{ image: string, text: string }>
  active: boolean
}

export type $CategoryModel = CategoryModel

export default class CategoryModel extends ModelStore {
  @observable data: $Category = {
    _id: '',
    name: '',
    treatmentIds: [],
    slug: '',
    description: '',
    images: [],
    active: false,
  }

  fetchMethodName = 'category'
  cacheLength = minutesToMs(15)

  @action.bound merge(o: any) {
    for (const i in o) {
      if (i === 'treatments') {
        this.rootStore.treatmentStore.mergeData(o.treatments)
      } else {
        this.data[i] = o[i]
      }
    }
  }

  @computed get urlSafeName() {
    return urlSafeString(this.data.name)
  }

  @computed get description() {
    return Descriptions[this.data.name]
  }
}
