import { computed } from 'mobx'
import moment from 'moment-timezone'

import { ApolloRequesterOptions } from '../../../shared-lib/communication/requester'
import ListStore from '../../../shared-lib/stores/ListStore'
import { AddorUpdateParkingInformationMutationVariables } from '../../graph/generated/client'
import { minutesToMs } from '../../lib/utils'
import UserAddressModel, { $UserAddressModel } from './UserAddressModel'

export default class UserAddressStore extends ListStore<$UserAddressModel> {
  fetchMethodName = 'getAddresses'
  ModelClass = UserAddressModel
  cacheLength = minutesToMs(15)

  fetchMethod_api = async (_variables: any, queryOptions: ApolloRequesterOptions): Promise<any> => {
    try {
      const ret = await this.rootStore.communication.requester.getAddresses({}, queryOptions)
      const questions = ret.questions || { address: [] }
      return questions.address
    } catch (e) {
      return null
    }
  }

  fetchAddressParkingInformation = async (addressId: string) => {
    try {
      this.loading = true
      const ret = await this.rootStore.communication.requester.getParkingInformation({ addressId }, {})
      this.loading = false
      if (!ret.getParkingInformation) return null
      const data = { ...ret.getParkingInformation } as any
      delete data.__typename
      return data
    } catch (e) {
      this.loading = false
      return null
    }
  }

  addOrUpdateParkingInformation = async (data: AddorUpdateParkingInformationMutationVariables) => {
    try {
      this.loading = true
      const ret = await this.rootStore.communication.requester.addorUpdateParkingInformation(data, {})
      this.loading = false
      return ret.addorUpdateParkingInformation
    } catch (e) {
      this.loading = false
      return null
    }
  }

  // Finds the default address
  @computed get defaultAddress(): $UserAddressModel | undefined {
    if (this.items.length === 0) {
      return undefined
    }

    const sorted = [...this.items]

    sorted.sort((a, b) => {
      const dateA = typeof a.data.setDefaultAt === 'string' ? moment(parseInt(a.data.setDefaultAt, 10)) : moment(a.data.setDefaultAt)
      const dateB = typeof b.data.setDefaultAt === 'string' ? moment(parseInt(b.data.setDefaultAt, 10)) : moment(b.data.setDefaultAt)

      return dateA.isSameOrAfter(dateB) ? -1 : 1
    })

    return sorted[0]
  }

  // Returns default addressId
  @computed get defaultAddressId(): string | undefined {
    return this.defaultAddress ? this.defaultAddress.data._id : undefined
  }

  // Sync store data
  syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('USER_ADDRESSES')
  }

  // Used when syncing to storage
  serialize = () => {
    return {
      items: this.items.map((item: any) => item.data),
    }
  }
}

export type $UserAddressStore = UserAddressStore
