import { toJS } from 'mobx'
import { getBrowserTimezone } from '../../shared-consumer/lib/utils'
import { $BookingModel } from '../../shared-consumer/stores/BookingStore/BookingModel'
import packageJSON from '../../package.json'
import AnalyticsConsumerBase from '../../shared-consumer/lib/AnalyticsConsumerBase'

function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

// Extend shared AnalyticsBase
class Analytics extends AnalyticsConsumerBase {
  get analytics() {
    return window.analytics
  }

  init() {
    this.storage = window.localStorage
    this.config.platform = process.env.PLATFORM
    this.config.name = process.env.SS_ENV
    this.config.timezone = getBrowserTimezone()
    this.config.version = packageJSON.version
  }

  // Track page views to segment
  page = (pageName: string, data?: any) => {
    this.analytics.page(pageName, this.parseProperties({
      ...toJS(data),
      ...this.getContext(),
    }))

    window.amplitude && this.logToExtraServices(pageName, data)
  }

  track = (eventName: string, data?: any) => {
    super.track(eventName, data)

    window.amplitude && this.logToExtraServices(eventName, data)
  }

  ecommerceStep = (action: 'Viewed' | 'Completed', stepNumber: number, stepName: string, booking: $BookingModel, params: any = {}) => {
    super.ecommerceStep(action, stepNumber, stepName, booking, params)

    const eventName = `${action} ${stepName}`
    window.amplitude && this.logToExtraServices(eventName, params)
  }

  logToExtraServices(eventName: string, data?: any) {
    if (this.config.name !== 'production') return

    const enabledEvents = [
      'ViewCategory',
      'ViewTreatment',
      'Treatment Added',
      'Checkout: Prompted to Login',
      'Payment Button Pressed',
      'Viewed SelectProfessional',
      'Viewed BookingSummary',
    ]

    if (enabledEvents.includes(eventName)) {
      // Get referred path from cookie, e.g. treamtents/nails
      const referral = getCookie('referral')

      if (window.amplitude) {
        window.amplitude.getInstance().logEvent(eventName, {
          ...data,
          ...this.getContext(),
          url: referral,
        })
      }

      if (window.hj) {
        window.hj('event', eventName)
      }
    }
  }

  populateCampaignData = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const campaignData: Record<string, any> = {}

    const utmTerms = ['campaign', 'source', 'medium', 'term', 'content']

    let didTrack = false

    if (urlParams.get('utm_campaign') || urlParams.get('utm_source')) {
      // Track via url utms
      utmTerms.forEach(u => {
        campaignData[u] = urlParams.get(`utm_${u}`)
      })

      didTrack = true
    } else if (getCookie('utm_campaign') || getCookie('utm_campaign')) {
      // Track via cookie
      utmTerms.forEach(u => {
        campaignData[u] = getCookie(`utm_${u}`)
      })

      didTrack = true
    }

    if (didTrack) {
      // If we tracked send save the data
      campaignData.platform = this.config.platform
      campaignData.touchDate = new Date()
      this.track('Track Campaign', campaignData)
      this.storage.setItem('lastTouchCampaign', JSON.stringify(campaignData))
      this.campaignData = campaignData
    } else {
      // Otherwise just set the last touch data
      this.campaignData = JSON.parse(this.storage.getItem('lastTouchCampaign')) || {}
    }

    return didTrack
  }
}

export default new Analytics()
