import Link from 'next/link'
import React from 'react'

import { Link as A } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { snakeCase } from 'voca'

interface Props {
  children: any
  onClick?: any
  href?: any
  as?: string
  target?: string
  variant?: Variant
  externalHref?: string
  color?: any
  ariaPrefix?: string
  className?: string
}

const style = {
  cursor: 'pointer',
}

const TextButton = (props: Props) => {
  const {
    children,
    onClick,
    href,
    as,
    externalHref,
    target,
    color,
    ariaPrefix = '',
    variant = 'body2',
    className = '',
  } = props

  const onClickInternal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      // Remove focus from the button on press, otherwise subsequent "enter" key presses will cause another submission
      // @ts-ignore
      e.target.blur && e.target.blur()
      e.stopPropagation()
      onClick()
    }
  }

  const classes = className ? className + ' textButton' : 'textButton'

  if (externalHref) {
    return (
      <A
        color={color}
        variant={variant}
        className='textButton'
        style={style}
        href={externalHref}
        target={target}>
        {children}
      </A>
    )
  }

  return (
    <>
      {href ? (
        <Link href={href} as={as || href}>
          <A
            aria-label={snakeCase(`${children} ${ariaPrefix} text button`)}
            target={target}
            variant={variant}
            className={classes}
            style={style}>
            {children}
          </A>
        </Link>
      )
        : <A
          aria-label={snakeCase(`${children} ${ariaPrefix} text button`)}
          target={target}
          variant={variant}
          onClick={onClickInternal}
          className={classes}
          style={style}>
          {children}
        </A>
      }
    </>
  )
}

export default TextButton
