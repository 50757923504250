import { action, computed, observable } from 'mobx'

import { $RootStore } from '../../src/stores/RootStore'
import { mergeDeep } from '../utils'
import ModelStore from './ModelStore'
import { ApolloRequesterOptions, Sdk } from '../communication/requester'

interface $opts { variables?: any, clear?: boolean, refresh?: boolean, cacheLength?: number }

class ListStore<T extends ModelStore> {
  @observable loading = false
  @observable refreshing = false
  @observable items: Array<T> = []
  @observable editItem: T | undefined
  originalEditItemData: any
  ModelClass = ModelStore
  cacheLength = 0
  lastUpdatedAt = 0
  rootStore: $RootStore
  fetchMethodName = ''
  defaultFetchVariables = {}

  constructor(rootStore: $RootStore) {
    this.rootStore = rootStore
  }

  clearCache = () => {
    this.lastUpdatedAt = 0
  }

  useCached = (cacheLength: number) => {
    return this.lastUpdatedAt + cacheLength > Date.now() ? 'cache-first' : 'network-only'
  }

  fetchMethod_api = async <T, K extends keyof T>(variables: any, queryOptions: ApolloRequesterOptions): Promise<T[K] | null> => {
    const combinedVariables = mergeDeep({ ...this.defaultFetchVariables }, variables || {})

    try {
      const ret = await this.rootStore.communication.requester[this.fetchMethodName as keyof Sdk](
        combinedVariables, queryOptions,
      )
      const data = ret && ret[this.fetchMethodName as keyof typeof ret] || null
      return data
    } catch (e) {
      // Error is handled in Communication
      return null
    }
  }

  // Refresh list, used when you pull to refresh
  @action.bound refreshItems_api = async (opts: $opts = {}) => {
    this.refreshing = true
    this.clearCache()

    await this.fetchItems_api(opts)

    this.refreshing = false
  }

  @action.bound fetchItems_api = async (opts: $opts = {}) => {
    const { variables, clear } = opts

    this.loading = true

    if (clear) this.items.length = 0

    const fetchPolicy = this.useCached(opts.cacheLength || this.cacheLength)
    const data = await this.fetchMethod_api(variables, { fetchPolicy })

    if (data) {
      this.mergeData(data)
      this.lastUpdatedAt = Date.now()
    }

    this.loading = false
  }

  @action.bound fetchItem_api = async (_id: string) => {
    if (!_id) return undefined

    this.loading = true

    const item = this.findItemById(_id)

    if (item) {
      await item.fetch_api()
    } else {
      const newItem = new this.ModelClass({ _id }, this.rootStore) as T
      await newItem.fetch_api()
      this.items.push(newItem)
    }

    this.loading = false

    return this.findItemById(_id)
  }

  @action.bound removeItem = async (_id: string, deleteItemFromDatabase = true): Promise<boolean> => {
    const item = this.findItemById(_id)

    if (item) {
      this.loading = true
      const isDeleted = deleteItemFromDatabase ? await item.remove_api() : true
      this.loading = false

      // Filter removed item
      this.items = this.items.filter(i => {
        return i.data._id !== _id
      })

      this.clearCache()

      return isDeleted
    }

    return false
  }

  @action.bound addItem = async (data: any): Promise<T | undefined> => {
    const item = new this.ModelClass(data, this.rootStore)
    this.loading = true
    const savedItem = await item.save_api()
    this.loading = false

    if (!savedItem) return undefined

    this.items.unshift(savedItem)
    this.clearCache()
    return savedItem
  }

  @action.bound updateItem = async (data: any): Promise<T | undefined> => {
    const item = this.findItemById(data._id)

    if (!item) return undefined

    item.merge(data)
    this.loading = true
    const savedItem = await item.save_api()
    this.loading = false
    return savedItem
  }

  @action.bound createEditItem = async (_id: string) => {
    let data = {}

    if (_id) {
      this.loading = true
      const existingModel = await this.fetchItem_api(_id)
      this.loading = false
      if (existingModel) data = { ...existingModel.data }
    }

    this.setEditItem(data)
  }

  @action.bound setEditItem = (data: T['data']) => {
    this.originalEditItemData = { ...data }
    this.editItem = new this.ModelClass(data, this.rootStore) as T
  }

  editItemHasChanged = () => {
    if (!this.editItem) return false
    return JSON.stringify(this.editItem.data) !== JSON.stringify(this.originalEditItemData)
  }

  findItemById = (_id: string | undefined): T | undefined => {
    if (!_id) return undefined

    const item = this.items.find(x => x.data._id === _id)
    return item
  }

  findItemByProperty = (value: string, property: string): T | undefined => {
    const item = this.items.find(x => x.data[property] === value)
    return item
  }

  findItemByIds = (ids: Array<string>): Array<T> => {
    return ids
      .map((i: string) => this.findItemById(i))
      .filter((o: T | undefined): o is T => o !== undefined)
  }

  @action.bound mergeData = (o: any, createNew = true) => {
    const newModels: Array<T> = []

    o.forEach((x: any) => {
      const i = this.findItemById(x._id)
      if (i) {
        i.merge(x)
      } else if (createNew) {
        newModels.push(new this.ModelClass(x, this.rootStore) as T)
      }
    })

    this.items = [...this.items, ...newModels]
  }

  // Get ordered items using sortMethod
  @computed get orderedItems() {
    return this.items.slice(0).sort(this.sortMethod)
  }

  // Sort items by method (override this method in stores)
  sortMethod = (_a: any, _b: any) => {
    return 0
  }

  // Clear all booking data
  @action.bound clearData(): void {
    this.clearCache()
    this.items = []
  }

  // Computed property to determine whether to show empty list message
  @computed get hasNoItems(): boolean {
    return !this.loading && !this.refreshing && this.items.length === 0
  }

  // Called when populating with data from storage
  inflate(o: typeof this) {
    for (const i in o) {
      if (i === 'items') this.mergeData(o.items)
      else this[i] = o[i]
    }
  }

  // Used when syncing to storage
  serialize = () => {
    return {
      items: this.items.map((item: any) => item.data),
    }
  }
}

export default ListStore
