import { action, computed, observable, reaction, toJS } from 'mobx'
import { generateObjectId } from '../../../shared-lib/utils'
import { $Postcode } from '../../handlers/ServiceAreaHandler'
import { $Treatment } from '../../stores/TreatmentStore/TreatmentModel'
import BookingModel, { $BookingItem, $BookingItemTreatment, $BookingModel, $ClientDetail } from '../BookingStore/BookingModel'
import { $Professional } from '../ProfessionalStore/ProfessionalModel'
import { $UserAddress } from '../UserAddressStore/UserAddressModel'
import analytics from '../../../src/stores/analytics'
import RootStore from '../../../src/stores/RootStore'
import { AddPromoCodeToUserMutationVariables } from '../../graph/generated/client'
import { OFFPEAK_ITEM_NAME, SERVICE_FEE_ITEM_NAME, NEW_PROFESSIONAL_DISCOUNT, ELITE_TREATMENT_COST } from '../../../shared-lib/commonStrings'
import { calculateServiceCharge } from '../../helpers/abTestingHelpers'
import moment, { Moment } from 'moment-timezone'
import PromoCodeModel from '../PromoCodeStore/PromoCodeModel'
import { textFromReason } from '../../helpers/promoCodeHelpers'
import { $PromoNotUseableReason } from '../../viewModels/VouchersViewModel'
import { getItemTreatmentTypes } from '../../helpers/bookingItemHelpers'
import { calculateEliteCostsForBasket } from '../../../shared-lib/eliteProfessionalHelpers'

const treatmentData = {
  name: '',
  price: 0,
  isOption: false,
  isTreatment: false,
  isPackage: false,
  type: 'other',
  fee: 0,
  duration: 0,
  shortDescription: '',
  info: [],
  optionGroups: [],
}

export type $BookingFlow = 'treatment:pro:time' | 'pro:treatment:time' | 'treatment:time:pro'
interface PromoCodeResult {
  canUse: boolean
  canApply: boolean
  promoFound: boolean
}

export default class CheckoutStore {
  rootStore: RootStore
  bookingFlow: $BookingFlow = 'treatment:time:pro'
  @observable booking: $BookingModel
  @observable loading = false
  @observable availablePaymentService: 'unknown' | 'applePay' | 'googlePay' = 'unknown'
  @observable selectedPaymentMethod: 'card' | 'applePay' | 'googlePay' = 'card'
  @observable selectedCardId = ''
  @observable servicesSupported = false
  @observable provisionalProfessional: $Professional | undefined = undefined
  @observable reservedUntil: Moment | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.booking = new BookingModel({}, rootStore)
    this.listenOnBooking()

    const { cardStore } = this.rootStore

    if (!this.selectedCardId && cardStore.items.length > 0 && cardStore.defaultCardId) {
      this.setSelectedCard(cardStore.defaultCardId)
    }
  }

  listenOnBooking = () => {
    // Listen on items
    reaction(() => this.booking.data.items.length, (length: number) => {

      if (length === 0) {
        this.resetBookingInstructions()
      }
    })
  }

  @computed get hasEliteProfessional() {
    return this.provisionalProfessional && this.provisionalProfessional.tags?.includes('ElitePro')
  }

  @computed get reservedUntilText() {
    return this.reservedUntil ?
      this.reservedUntil.diff(moment(), 'seconds') :
      0
  }

  validPostcode(postcode: string) {
    const regex = /(^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2}))$/gm

    return regex.test(postcode)
  }

  @action.bound setFlow = (flow: $BookingFlow) => {
    this.bookingFlow = flow
    this.booking.data.additionalInformation.bookingFlow = flow
    this.updateSessionContext()
  }

  @action.bound setBookingAddress = (postcode: $Postcode, address: $UserAddress | Record<string, string> = {}) => {
    this.booking.set({ address, postcodeId: postcode._id, postcode, region: postcode.region })

    this.updateSessionContext()
    this.syncInStorage()
  }

  @action.bound updateSessionContext = () => {
    const { favouriteStore } = this.rootStore
    const { date, type, requestedProfessionals, professional, region, items, postcode } = this.booking.data

    const requestedProfessional = requestedProfessionals.length > 0 ? requestedProfessionals[0] : undefined
    const proId: string | undefined = (professional?._id || requestedProfessional?._id ||
      this.provisionalProfessional?._id)
    const selectedAFavourite = favouriteStore.professionalIsFavourite(proId)

    const itemTypes = getItemTreatmentTypes(this.booking.data.items)

    const context = {
      bookingTotal: this.booking.totalPriceComputed,
      bookingDate: date,
      bookingType: type,
      bookingFlow: this.bookingFlow,
      bookingRegion: region,
      bookingPostcode: postcode?.code?.toLowerCase(),
      bookingItems: items.map((i: $BookingItem) => i.treatment.name),
      bookingItemTypes: Array.from(itemTypes),
      provisionalProfessionalId: this.provisionalProfessional?._id,
      selectedAFavourite,
      requestedProfessionals: requestedProfessionals.length,
      requestedProfessionalIds: requestedProfessionals.map((p: $Professional) => p._id),
      professionalId: professional && professional._id,
    }

    analytics.setSessionContext(context)
  }

  @action.bound updateClientDetails = async () => {
    const {
      userStore: { data },
      checkoutStore: { booking },
    } = this.rootStore

    // Merge user credentials with existing booking client details
    const { firstName, gender, mobile } = data
    const clientDetails = booking.data.clientDetails.length > 0 ? booking.data.clientDetails[0] : {
      firstName: null,
      gender: null,
      contactNumber: null,
    }

    this.setClientDetails({
      accountOwner: true,
      firstName: clientDetails.firstName || firstName,
      gender: clientDetails.gender || gender || '',
      contactNumber: clientDetails.contactNumber || mobile || '',
    })
  }

  @action.bound prepareForBookingSummary = async () => {
    await this.setupPaymentMethod()

    this.updateClientDetails()
  }

  @action.bound setReservedUntil(time: Moment | null) {
    this.reservedUntil = time
  }

  @action.bound clearFeesAndDiscounts = () => {
    this.removeEliteProfessionalCost()
    this.removeNewProfessionalDiscount()
    this.removeServiceFee()
  }

  @action.bound setupPaymentMethod = async () => {
    const {
      cardStore,
      config: { supportsApplePay = false },
    } = this.rootStore

    await cardStore.fetchItems_api()

    // Set the payment method
    const isCardPaymentMethodSelected = this.selectedPaymentMethod === 'card'

    if (isCardPaymentMethodSelected && cardStore.defaultCard) {
      this.setSelectedCard(cardStore.defaultCard.data._id)
    } else if (cardStore.items.length === 0 && isCardPaymentMethodSelected && supportsApplePay) {
      this.setPaymentMethod('applePay')
    }
  }

  @action.bound async saveDraftBooking_api(): Promise<boolean> {
    const { communication: { requester } } = this.rootStore
    const data = this.booking.toRequestData() as BookingInput

    try {
      const response = await requester.saveDraftBooking({ data })

      const bookingDraft = response.saveDraftBooking

      this.booking.merge(bookingDraft)
      this.syncInStorage()

      return true
    } catch {
      return false
    }
  }

  @action.bound async publishBooking_api() {
    const { communication: { requester } } = this.rootStore
    const data = this.booking.toRequestData() as BookingInput

    try {
      const response = await requester.publishBooking({ data, sendNotifications: true })

      const newBooking = response.publishBooking

      if (newBooking) {
        this.booking.merge(newBooking)
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }

  addPromoCode_api(promoCode: string, applying: boolean): Promise<boolean>
  addPromoCode_api(promoCode: string, applying: boolean, checkFound: boolean): Promise<PromoCodeResult>
  @action.bound async addPromoCode_api(promoCode: string, applying: boolean, checkFound?: boolean) {
    const { communication: { requester }, uiStore, i18n: { s }, promoCodeStore } = this.rootStore
    const { booking } = this

    this.loading = true

    const bookingVar = {
      booking: {
        _id: booking.data._id,
        items: booking.data.items,
        region: booking.data.region,
        date: booking.data.date,
        totalPrice: booking.data.totalPrice,
      },
    }

    const args = {
      code: promoCode,
      ...(applying && bookingVar),
    } as AddPromoCodeToUserMutationVariables['data']

    try {
      // Check promo code with API
      const response = await requester.addPromoCodeToUser({ data: args })

      // If promo code is invalid then show reasons in UI
      if (response.addPromoCodeToUser) {
        const { canApply, promoCodeDiscount, data, reasons } = response.addPromoCodeToUser
        const { canUse } = data ?? { canUse: false }

        if ((!canUse || (applying && !canApply)) && reasons && reasons[0]) {
          const title = applying ? s('PROMO_INVALID_TITLE') : !canUse ? s('PROMO_NOT_ADDED_TITLE') : s('PROMO_NO_LONGER_VALID_TITLE')

          const body = textFromReason(reasons[0] as $PromoNotUseableReason)

          uiStore.showMessage({
            title,
            body,
          })

          // Set data on booking
          this.booking.set({
            promoCodeDiscount: 0,
            promoCode: {},
            promoCodeId: '',
          })
        } else if (data) {
          // Set data on booking
          if (canApply) {
            this.booking.set({
              promoCodeDiscount,
              promoCode: data,
              promoCodeId: data._id,
            })
          }

          if (canUse && !promoCodeStore.findItemById(data._id!)) {
            const promoCodeModel = new PromoCodeModel(data, this.rootStore)
            promoCodeStore.items.push(promoCodeModel)
          }
        }
        this.syncInStorage()

        this.loading = false

        if (checkFound) {
          return { canUse: !!canUse, canApply: !!canApply, promoFound: !!data }
        } else {
          return !!canApply
        }
      }

      this.loading = false

      if (checkFound) {
        return { canUse: false, canApply: false, promoFound: false }
      } else {
        return false
      }
    } catch (e) {
      this.loading = false

      if (checkFound) {
        return { canUse: false, canApply: false, promoFound: false }
      } else {
        return false
      }
    }
  }

  @action.bound removePromoCode(): void {
    this.booking.set({
      promoCode: {},
      promoCodeDiscount: 0,
      promoCodeId: '',
    })

    this.syncInStorage()
  }

  @action.bound bookWithProfessional = async (professionalId: string, source: string) => {
    const { professionalStore } = this.rootStore

    // Ensure we have the pro data
    const professional = await professionalStore.fetchItem_api(professionalId)
    if (professional) {
      this.provisionalProfessional = professional.data

      analytics.setSessionContext({
        bookWithProfessionalSource: source,
      })

      analytics.track('Book With Professional', { professionalId: professional.data._id, source })

      if (this.hasEliteProfessional) {
        this.addEliteProfessionalCost()
      }

      this.updateSessionContext()
      this.syncInStorage()
    }
  }

  @action.bound removeProvisionalProfessionalFromBooking = () => {
    if (this.isTradeTestBooking) {
      this.booking.data.tags = this.booking.data.tags.slice().filter(t => t !== 'tradeTest')
      this.emptyBasket()
    }
    this.provisionalProfessional = undefined
    this.removeEliteProfessionalCost()

    this.updateSessionContext()
    this.syncInStorage()
  }

  @action.bound selectInstantProfessionalAndDate = (professional: $Professional, date: Date) => {
    this.booking.set({
      professional,
      date,
      requestedProfessionals: [],
      type: 'instant',
    })

    this.updateSessionContext()
  }

  @action.bound selectRequestableProfessionalAndDate = (professional: $Professional, date: Date) => {
    this.booking.set({
      professional: undefined,
      date,
      requestedProfessionals: [professional],
      type: 'request',
    })

    this.updateSessionContext()
  }

  @action.bound selectSendToAll = () => {
    this.booking.set({
      professional: undefined,
      requestedProfessionals: [],
      type: 'sendToAll',
    })

    this.updateSessionContext()
  }

  @action.bound setBookingDate = (date: Date) => {
    this.booking.set({
      date,
    })

    this.updateSessionContext()
  }

  @action.bound completePublish = () => {
    const { bookingStore } = this.rootStore

    const publishedBookingData = toJS(this.booking.data)
    bookingStore.mergeData([publishedBookingData])

    this.emptyBasket()
    this.reservedUntil = null

    return bookingStore.findItemById(publishedBookingData._id)
  }

  @action.bound resetBooking = () => {
    const { professionalMatcherStore } = this.rootStore

    this.booking.set({
      professional: undefined,
      date: professionalMatcherStore.selectedDate,
      requestedProfessionals: [],
      type: 'sendToAll',
    })

    this.updateSessionContext()
  }

  @action.bound setSelectedCard = (id: string) => {
    this.selectedCardId = id
    this.setPaymentMethod('card')
  }

  @action.bound setPaymentMethod = (method: 'card' | 'applePay' | 'googlePay') => {
    this.selectedPaymentMethod = method
    this.syncInStorage()
  }

  @action.bound setClientDetails(clientDetail: $ClientDetail): void {
    this.booking.data.clientDetails = [clientDetail]
  }

  @action.bound setParkingInformationId = (id: string) => {
    this.booking.data.address.parkingInformationId = id
  }

  @action.bound addItem = (treatment: $BookingItemTreatment, analyticParams?: Record<string, any>) => {
    const item: $BookingItem = {
      _id: generateObjectId(),
      treatment: {
        ...treatment,
        info: [], // Remove info
      },
    }

    this.booking.data.items.push(item)
    this.syncInStorage()

    if (this.hasEliteProfessional) {
      this.addEliteProfessionalCost()
    }

    analytics.ecommerceEvent('Product Added', treatment)
    analytics.track('Treatment Added', {
      ...analytics.parseTreatment(treatment),
      ...analyticParams,
    })

    this.updateSessionContext()
    return item
  }

  @action.bound removeItem = (item: $BookingItem) => {
    this.booking.data.items = this.booking.data.items.filter((t: $BookingItem) => t._id !== item._id)
    this.syncInStorage()

    if (this.hasEliteProfessional) {
      this.removeEliteProfessionalCost()
      this.addEliteProfessionalCost()
    }

    analytics.ecommerceEvent('Product Removed', item.treatment as $Treatment)
    analytics.track('Treatment Removed', analytics.parseTreatment(item.treatment as $Treatment)) // Have to parse treatment so Segment does not complain

    this.updateSessionContext()

    return this.booking.data.items.length
  }

  @action.bound emptyBasket = () => {
    this.provisionalProfessional = undefined
    this.booking.clear()
    this.updateSessionContext()
    this.syncInStorage()
  }

  @action.bound resetDraftBooking = () => {
    this.booking.data._id = ''
    this.booking.data.status = 'DRAFT'
    this.syncInStorage()
  }

  bookingDataIsValid = () => {
    const data = this.booking.toRequestData()
    if (data.items.length === 0) return false
    if (!data.postcodeId) return false

    return true
  }

  @action.bound payBookingWithCardAndPublish = async () => {
    const { selectedCardId, booking, amountToPay } = this

    return this.payBookingAndPublish({
      orderId: booking.data._id,
      orderType: 'booking',
      sourceId: selectedCardId,
      amount: amountToPay,
      capture: false,
      paymentMethodType: 'card',
    })
  }

  @action.bound payBookingWithServiceAndPublish = async (sourceId: string, paymentMethodType: string) => {
    const { booking, amountToPay } = this

    return this.payBookingAndPublish({
      orderId: booking.data._id,
      orderType: 'booking',
      sourceId,
      amount: amountToPay,
      capture: false,
      paymentMethodType,
    })
  }

  @action.bound payBookingAndPublish = async (order: Omit<Order, 'paymentIntentId'>) => {
    const { transactionStore } = this.rootStore
    const { amountToPay } = this

    let isPaid = false
    this.loading = true
    if (amountToPay > 0) {
      isPaid = await transactionStore.makePayment_api(order)

    } else {
      isPaid = true
    }

    if (isPaid) {
      const bookingCreated = await this.publishBooking_api()
      this.loading = false
      return bookingCreated
    } else {
      this.loading = false
      return null
    }
  }

  @action.bound setBookingClientPictures = (clientPictures: Array<string>) => {
    this.booking.data.clientPictures = clientPictures
    this.syncInStorage()
  }

  @action.bound setBookingClientNote = (clientNote: string) => {
    this.booking.data.clientNote = clientNote
    this.syncInStorage()
  }

  @action.bound resetBookingInstructions = () => {
    this.setBookingClientPictures([])
    this.setBookingClientNote('')
  }

  addServiceFee = () => {
    const { serviceFeeCap = 10000, serviceFeePercentage = 0.05 } = this.rootStore.remoteConfig

    // Remove existing service fee
    this.removeServiceFee()
    const price = calculateServiceCharge(serviceFeePercentage, serviceFeeCap, this.booking.totalPriceComputed)

    this.booking.data.items.push({
      _id: generateObjectId(),
      treatment: {
        ...treatmentData,
        name: SERVICE_FEE_ITEM_NAME,
        price,
      },
    })
  }

  removeServiceFee = () => {
    this.booking.data.items = this.booking.data.items.filter(item => item.treatment.name !== SERVICE_FEE_ITEM_NAME)
  }

  addEliteProfessionalCost = () => {
    const alreadyHasEliteCost = this.booking.data.items.find(item => item.treatment.name === ELITE_TREATMENT_COST)

    if (alreadyHasEliteCost) this.removeEliteProfessionalCost()

    const costs = calculateEliteCostsForBasket(this.booking.data.items)

    if (!costs.price || !costs.fee) return

    this.booking.data.items.push({
      _id: generateObjectId(),
      treatment: {
        ...treatmentData,
        name: ELITE_TREATMENT_COST,
        ...costs,
      },
    })
  }

  removeEliteProfessionalCost = () => {
    this.booking.data.items = this.booking.data.items.filter(item => item.treatment.name !== ELITE_TREATMENT_COST)
    this.syncInStorage()

    this.updateSessionContext()
  }

  addNewProfessionalDiscount = () => {
    const { remoteConfig } = this.rootStore

    const alreadyHasDiscount = this.booking.data.items.find(item => item.treatment.name === NEW_PROFESSIONAL_DISCOUNT)

    if (alreadyHasDiscount) return

    this.booking.data.items.push({
      _id: generateObjectId(),
      treatment: {
        ...treatmentData,
        name: NEW_PROFESSIONAL_DISCOUNT,
        price: -remoteConfig.newProfessionalDiscount,
      },
    })
  }

  removeNewProfessionalDiscount = () => {
    this.booking.data.items = this.booking.data.items.filter(item => item.treatment.name !== NEW_PROFESSIONAL_DISCOUNT)
  }

  addOffPeakDiscount = () => {
    const offPeakItem = this.booking.data.items.find(item => item.treatment.name === OFFPEAK_ITEM_NAME)

    // Booking already has off peak item
    if (offPeakItem) return

    const price = analytics.ABTest('offPeakPricingAmount') as number || -500

    this.booking.data.items.push({
      _id: generateObjectId(),
      treatment: {
        ...treatmentData,
        name: OFFPEAK_ITEM_NAME,
        price,
      },
    })
  }

  removeOffPeakDiscount = () => {
    this.booking.data.items = this.booking.data.items.filter(item => item.treatment.name !== OFFPEAK_ITEM_NAME)
  }

  // Computed properties

  @computed get bookingRegionOrDefault() {
    const { region } = this.booking.data
    return region || 'london'
  }

  @computed get minPriceReached() {
    return this.booking.totalPriceWithoutDiscounts >= this.minPrice ||
      (this.isTradeTestBooking && this.booking.data.items.length > 0)
  }

  @computed get minPrice() {
    const { remoteConfig: { minCallOutFees = {} } } = this.rootStore
    return minCallOutFees[this.bookingRegionOrDefault] ?? 3800
  }

  @computed get bookingIsEmpty() {
    return this.booking.data.items.length === 0
  }

  @computed get addToBookingCallToAction() {
    return this.bookingIsEmpty ? 'Book now' : 'Add to booking'
  }

  @computed get selectedCard() {
    const { cardStore } = this.rootStore

    return cardStore.findItemById(this.selectedCardId)
  }

  // Determines if you can proceed to payment
  @computed get canPublishBooking(): boolean {
    const { config: { platform } } = this.rootStore

    const isCardSelected = this.selectedPaymentMethod === 'card' && this.selectedCardId.length > 0

    let paymentMethodSelected = false
    if (platform === 'CA') {
      paymentMethodSelected = isCardSelected || this.selectedPaymentMethod === 'applePay'
    } else {
      paymentMethodSelected = isCardSelected || this.servicesSupported
    }

    return this.hasAddress && this.hasClientDetails && (paymentMethodSelected || this.amountToPay <= 0)
  }

  @computed get hasAddress() {
    const { data: { address } } = this.booking
    return !!(address && Object.keys(address).length > 0)
  }

  @computed get hasPostcode() {
    const { data: { postcode } } = this.booking
    return !!postcode._id
  }

  @computed get hasProfessional() {
    const { data: { professional } } = this.booking
    return !!(professional && Object.keys(professional).length > 0)
  }

  @computed get hasClientDetails() {
    const { data: { clientDetails } } = this.booking
    return !!(clientDetails && clientDetails.length > 0 && clientDetails[0].firstName && clientDetails[0].gender)
  }

  @computed get amountToPay() {
    const { totalPriceComputed, data: { promoCodeDiscount } } = this.booking
    const { balanceForOrder } = this.rootStore.transactionStore

    return Math.max(totalPriceComputed - promoCodeDiscount - balanceForOrder, 0)
  }

  @computed get isTradeTestBooking() {
    return this.booking.data.tags.includes('tradeTest')
  }

  // Used when a customer logs out
  clearData = () => {
    this.emptyBasket()
    this.booking.resetToDefaults()
    this.updateSessionContext()
    this.syncInStorage()
  }

  syncInStorage = () => {
    this.rootStore.storeDataSync.saveStoreToStorage('CHECKOUT')
  }

  inflate = (data: Record<string, any>) => {
    for (const i in data) {
      if (i === 'booking') this.booking.merge(data.booking)
      else this[i] = data[i]
    }
  }

  serialize(): any {
    return {
      booking: this.booking.data,
      provisionalProfessional: this.provisionalProfessional,
      selectedCardId: this.selectedCardId,
      selectedPaymentMethod: this.selectedPaymentMethod,
      bookingFlow: this.bookingFlow,
    }
  }
}
