import { action, computed } from 'mobx'
import moment from 'moment-timezone'

import ListStore from '../../../shared-lib/stores/ListStore'
import { minutesToMs } from '../../lib/utils'
import CardModel, { $CardModel } from './CardModel'

export default class CardStore extends ListStore<$CardModel> {
  fetchMethodName = 'cards'
  ModelClass = CardModel
  cacheLength = minutesToMs(15)

  // Request card intent
  requestCardSetupIntent = async (): Promise<any> => {
    try {
      const ret = await this.rootStore.communication.requester.requestCardSetupIntent({})
      const clientSecret = ret.requestCardSetupIntent
      return clientSecret
    } catch (e) {
      return null
    }
  }

  // Add new card
  @action.bound addCard = async (token: any): Promise<$CardModel | undefined> => {
    this.loading = true

    try {
      const ret = await this.rootStore.communication.requester.addCard({ token })
      const cardData = ret.addCard

      let newCard = undefined

      if (cardData) {
        // Add new item
        newCard = new this.ModelClass(cardData, this.rootStore)
        this.items.unshift(newCard)
      }

      this.loading = false
      return newCard
    } catch (e) {
      this.loading = false
      return undefined
    }
  }

  // Finds the default card
  @computed get defaultCard(): $CardModel | undefined {
    if (this.items.length === 0) {
      return undefined
    }

    const sorted = [...this.items]

    sorted.sort((a, b) => {
      const dateA = typeof a.data.setDefaultAt === 'string' ? moment(parseInt(a.data.setDefaultAt, 10)) : moment(a.data.setDefaultAt)
      const dateB = typeof b.data.setDefaultAt === 'string' ? moment(parseInt(b.data.setDefaultAt, 10)) : moment(b.data.setDefaultAt)

      return dateA.isSameOrAfter(dateB) ? -1 : 1
    })

    return sorted[0]
  }

  // Returns default addressId
  @computed get defaultCardId(): string | undefined {
    return this.defaultCard ? this.defaultCard.data._id : undefined
  }

  // Sort items by createdAt
  sortMethod = (a: any, b: any) => {
    return new Date(b.data.createdAt) > new Date(a.data.createdAt) ? -1 : 1
  }

  @computed get selectedOrFirstCard() {
    const { checkoutStore: { selectedCard } } = this.rootStore
    const firstCard = this.items.length > 0 ? this.items[0] : undefined
    return selectedCard && selectedCard || firstCard
  }
}

export type $CardStore = CardStore
