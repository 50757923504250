import { action, computed, observable } from 'mobx'
import { sleep } from '../../../shared-lib/utils'

export interface $ModalProps {
  key: string
  name: string
  data: Array<string>
}

function decodeString(string: string) {
  if (!string) return undefined
  return decodeURIComponent(string)
}

export class ModalController {
  @observable windowHash = ''

  @computed get modalPropStack() {
    const hashes = decodeURIComponent(this.windowHash).split('#').filter(s => s !== '')

    return hashes.map(hash => {
      const [name, arg1, arg2, arg3] = hash.split('/')

      const modalProps = {
        key: hash,
        name,
        data: [arg1, arg2, arg3].map((arg: string) => decodeString(arg)).filter(Boolean),
      }

      return modalProps
    })
  }

  @action.bound openModal(name: string, arg1?: any, arg2?: any, arg3?: any): void {
    let hash = [name, arg1, arg2, arg3].filter((x: any) => !!x).join('/')

    // Check we are not going to the same screen twice
    const lastScreen = this.modalPropStack[this.modalPropStack.length - 1]
    if (lastScreen && lastScreen.key === hash) return

    if (hash[0] !== '#') hash = `#${hash}`
    const fullHash = this.windowHash + hash
    this.setHashOnWindow(fullHash)
    this.windowHash = fullHash
  }

  @action.bound openModalFromHash(hash: string) {
    const fullHash = this.windowHash + hash
    this.setHashOnWindow(fullHash)
    this.windowHash = fullHash
  }

  @action.bound handleInitialHash = async (hash: string) => {
    const hashes = hash.split('#').filter(s => s !== '')

    if (hashes.length > 0) {
      let fullHash = ''

      for (hash of hashes) {
        fullHash += `#${hash}`
        await this.setHashOnWindow(fullHash)
      }

      this.windowHash = fullHash
    }

    // Listen to changes on the window hash for forward & back button presses
    window.addEventListener('hashchange', () => {
      this.windowHash = window.location.hash
    })
  }

  @action.bound closeAll = async () => {
    const historyLength = this.modalPropStack.length
    this.windowHash = ''

    if (historyLength > 0) {
      window.history.go(-historyLength)
      await sleep(historyLength * 100) // wait as window.history is async
    }
  }

  @action.bound goBack = async () => {
    const currentHashes = this.windowHash.split('#')

    if (currentHashes.length > 0) {
      window.history.back()
      await sleep(100) // wait as window.history is async
    }
  }

  async setHashOnWindow(hash: string) {
    window.history.pushState(undefined, '', hash)
  }

  buildFullHash(hashes: Array<string>) {
    if (hashes.length === 0) return ''
    return '#' + hashes.filter(s => s !== '').join('#')
  }
}