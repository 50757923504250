import { computed } from 'mobx'
import ListStore from '../../../shared-lib/stores/ListStore'
import { minutesToMs } from '../../lib/utils'
import { $Treatment } from '../TreatmentStore/TreatmentModel'
import CategoryModel, { $CategoryModel } from './CategoryModel'

export default class CategoryStore extends ListStore<$CategoryModel> {
  fetchMethodName = 'categories'
  ModelClass = CategoryModel
  cacheLength = minutesToMs(15)
  defaultFetchVariables = { filter: {} }

  @computed get activeCategories() {
    return this.items.filter(c => c.data.active)
  }

  categoriesForTreatments = (treatments: Array<Partial<$Treatment>>): Array<$CategoryModel> => {
    const categories: Array<$CategoryModel> = []

    this.items.forEach(categorie => {
      const { data: { treatmentIds } } = categorie
      if (treatments.find(treatment => treatmentIds.find(treatmentId => treatmentId === treatment._id))) {
        categories.push(categorie)
      }
    })

    return categories
  }
}

export type $CategoryStore = CategoryStore
