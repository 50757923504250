import { observable } from 'mobx'
import ModelStore from '../../../shared-lib/stores/ModelStore'
import { defaultParkingInformation } from '../../helpers/parkingInformationHelper'

export type $ParkingInformationModel = ParkingInformationModel

export default class ParkingInformationModel extends ModelStore {
  @observable data: ParkingInformation = { ...defaultParkingInformation }
  fetchMethodName = 'getParkingInformation'
  saveMethodName = 'addorUpdateParkingInformation'
}
