import React from 'react'

import { Box } from '@material-ui/core'
import { Info } from '@material-ui/icons'

import Clickable from './Clickable'

interface Props {
  color: string
  onClick: () => void
}

const InfoIconButton = ({ color, onClick }: Props) => {
  return <Clickable onClick={onClick}>
    <Box p={1} marginTop='-4px' marginBottom='-6px'>
      <Info style={{ color, fontSize: 20, lineHeight: 24 }} />
    </Box>
  </Clickable>
}

export default InfoIconButton