export interface $SyncStore {
  key: string
  store: any
}

export type $StoreDataSync = StoreDataSync

export default class StoreDataSync implements $StoreDataSync {
  syncStores: Array<$SyncStore> = []
  storage: any
  keyPrefix: string

  constructor(keyPrefix: string) {
    this.keyPrefix = keyPrefix
  }

  setStorage(storage: any) {
    this.storage = storage
  }

  // Check if sync exists and add store to it
  addSyncStore(key: string, store: any): void {
    const currentSync = this.getSyncStoreByKey(key)
    if (currentSync) {
      throw new Error(`sync already exists with key ${key}`)
    }

    const syncStore: $SyncStore = {
      key,
      store,
    }

    this.syncStores.push(syncStore)
  }

  // Get a store with a given key
  getSyncStoreByKey(key: string): $SyncStore | undefined {
    return this.syncStores.find(s => s.key === key)
  }

  // Get storage key from a given sync key
  storageKeyFromKey(key: string): string {
    return `${this.keyPrefix}${key}`
  }

  // Sync a store
  async saveStoreToStorage(key: string): Promise<void> {
    const syncStore = this.getSyncStoreByKey(key)
    if (!syncStore) {
      throw new Error(`no sync with key of ${key}`)
    }
    const data = syncStore.store.serialize()
    try {
      // local only
      await this.storage.setItem(this.storageKeyFromKey(syncStore.key), JSON.stringify(data))
    } catch (error) {
      // TODO: do something with error
    }
  }

  // Get store from storage
  async inflateStoreFromStorage(key: string): Promise<void> {
    const syncStore = this.getSyncStoreByKey(key)
    if (!syncStore) {
      throw new Error(`no sync with key of ${key}`)
    }

    try {
      const json = await this.storage.getItem(this.storageKeyFromKey(key))
      if (json !== null) {
        const value = JSON.parse(json)
        syncStore.store.inflate(value)
      }
    } catch (error) {
      // TODO: do something with error
    }
  }

  async clearAllData() {
    this.storage.clear()
  }

  // TODO: Sync All Stores
}
