import gql from 'graphql-tag'

export const USER_FIELDS = gql`
  fragment UserFields on User {
    _id
    firstName
    lastName
    emailAddress
    emailConfirmed
    mobile
    mobileConfirmed
    gender
    marketingPreferences
    userPreferences
    createdAt
    promoCodeIds
    subscriptionDetails {
      status
    }
  }
`
export const ADDRESS_FIELDS = gql`
  fragment AddressFields on UserAddress {
    _id
    label
    firstLine
    secondLine
    city
    county
    postalCode
    note
    setDefaultAt
    userId
    parkingInformationId
    createdAt
  }
`

export const CARD_FIELDS = gql`
  fragment CardFields on Card {
    _id
    setDefaultAt
    userId
    stripePaymentMethod
    expMonth
    expYear
    lastFourDigits
    brand
    blocked
    blockedReason
    createdAt
  }
`

export const TREATMENT_FIELDS = gql`
  fragment TreatmentFields on Treatment {
    _id
    name
    shortDescription
    duration
    price
    fee
    type
    isPackage
    isOption
    isTreatment
    info {
      title
      body
    }
    optionGroups {
      name
      multiSelect
      defaultIndex
      options {
        _id
        name
        shortDescription
        duration
        price
        fee
        selected
        info {
          title
          body
        }
      }
    }
    tags
  }
`

export const BOOKING_FIELDS = gql`
  fragment BookingFields on Booking {
    _id
    createdAt
    status
    address
    totalPrice
    totalDuration
    totalFee
    items
    title
    date
    note
    reviewPrompted
    clientReviewId
    clientDetails {
      contactNumber
      firstName
      gender
    }
    clientReview {
      comment
      rating
    }
    requestedProfessionals {
      _id
      firstName
      lastName
    }
    requestedProfessionalResponses {
      professionalId
      status
      respondedAt
      availabilityAdded
    }
    professional {
      _id
      firstName
      lastName
      role
      gender
      professionalProfile {
        averageReview
        reviewCount
      }
    }
    transactions {
      _id
      sourceType
      amount
    }
    adjustments {
      _id
      amount
      payoutDrafted
      professionalId
      adjustmentType
      note
    }
    promoCodeDiscount
    promoCode {
      _id
      promoCode
      value
      discountType
      limits {
        includedTreatmentIds
        excludedTreatmentIds
      }
    }
    postcodeId
    postcode {
      _id
      code
      name
      region
    }
    type
    region
    additionalInformation
    clientNote
    clientPictures
    totalLoyaltyPoints
    publishedDate
    tags
    crossSellPromoCode
  }
`

export const CHAT_FIELDS = gql`
  fragment ChatFields on Chat {
    _id
    professionalId
    professional {
      firstName
      lastName
    }
    clientId
    client {
      firstName
      lastName
    }
    lastMessage {
      message
      sentAt
    }
    messageCount {
      professional {
        unreadMessages
        sent
      }
      client {
        unreadMessages
        sent
      }
    }
  }
`

export const FAVOURITE_FIELDS = gql`
  fragment FavouriteFields on Favourite {
    _id
    userId
    professional {
      _id
      firstName
      lastName
      profileUrl
      professionalProfile {
        treatmentIds
        postcodeIds
        averageReview
        reviewCount
        favouriteCount
      }
    }
  }
`

export const TREATMENT_STUB_FIELDS = gql`
  fragment TreatmentStubFields on Treatment {
    _id
    name
    shortDescription
    price
    fee
    tags
    type
    duration
    numberOfPros
    info {
      title
      body
    }
  }
`

export const ADJUSTMENT_FIELDS = gql`
  fragment AdjustmentFields on Adjustment {
    _id
    bookingId
    professionalId
    note
    amount
    adjustmentType
    payoutDrafted
    createdAt
  }
`

export const PROFESSIONAL_PROFILE_FIELDS = gql`
  fragment ProfessionalProfileFields on ProfessionalProfile {
    averageReview
    reviewCount
    treatments {
      _id
      name
      type
    }
    favouriteCount
    bio
    postcodes {
      _id
      code
      name
      region
    }
    information
    disabledOptionIds
    genderPreference
    numberOfBookings
    lastBookedAt
    teamNote
  }
`

export const PROFILE_MATCH_FIELDS = gql`
  fragment ProfileMatchFields on ProfileMatch {
    userId
    user {
      _id
      firstName
      lastName
      profileUrl
    }
    favouriteCount
    reviewCount
    averageReview
  }
`

export const SUGGESTED_PROFESSIONAL_FIELDS = gql`
  fragment SuggestedProfessional on ProfileMatch {
    _id
    userId
    user {
      _id
      firstName
      lastName
      profileUrl
    }
    featured
    favouriteCount
    reviewCount
    isFavourite
    numberOfBookings
    averageReview
    treatments {
      _id
      name
    }
    availability {
      day
    }
    disabledOptionIds
  }
`

export const AVAILABLE_PROFESSIONAL_FIELDS = gql`
  fragment AvailableProfessionalsFields on AvailableProfessionals {
    day,
    professionals {
      professional {
        _id
        firstName
        lastName
        tags
        professionalProfile {
          favouriteCount
          reviewCount
          averageReview
          featured
          information
          genderPreference
          numberOfBookings
        }
      }
      day
      availableTimeSlots
      bookedTimesSlots
      bookingId
    }
  }
`

export const USERPROMOCODE_FIELDS = gql`
  fragment UserPromoCodeFields on UserPromoCode {
    _id
    promoCode
    expiryDate
    active
    discountType
    value
    redeemAction {
      action
      givenByUser {
        firstName
      }
    }
    limits {
      excludedTreatments {
        name
      }
      includedTreatments {
        name
      }
      excludedTreatmentTypes
      includedTreatmentTypes
    }
    featured
    expired
    canUse
    reasons {
      reason
      value
    }
  }
`

export const IN_APP_MESSAGE_FIELDS = gql`
  fragment InAppMessageFields on InAppMessage {
    _id
    name
    platform
    eventName
    match
    contentType
    updatedAt
  }
`