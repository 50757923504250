
import { observable } from 'mobx'
import ModelStore from '../../../shared-lib/stores/ModelStore'

export type $PromoCodeModel = PromoCodeModel

const defaults = {
  _id: '',
  promoCode: '',
  expiryDate: null,
  active: false,
  discountType: 'cash',
  value: 0,
  redeemAction: {
    action: '',
    givenByUser: {
      firstName: '',
    },
  } as any,
  limits: {
    excludedTreatments: [],
    includedTreatments: [],
    excludedTreatmentTypes: [],
    includedTreatmentTypes: [],
  } as any,
  expired: false,
  featured: false,
  canUse: false,
  reasons: [],
  useCount: 0,
} as UserPromoCode

export default class PromoCodeModel extends ModelStore {
  @observable data: UserPromoCode = {
    ...defaults,
  }
}
