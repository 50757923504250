import { action, computed, observable } from 'mobx'
import RootStore from '../RootStore'

interface CleanupAction {
  name: string
  action: () => void
}

export default class UIStore {
  rootStore: RootStore
  @observable testing = process.env.SS_ENV !== 'production'
  @observable viewModels: Record<string, any> = {}
  @observable cleanupActions: Array<CleanupAction> = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    if (rootStore.config.name !== 'production' && typeof window !== 'undefined') {
      // must cast as any to set property on window
      const _global = (window /* browser */ || global /* node */) as any

      _global.startTesting = this.startTesting
      _global.endTesting = this.endTesting
    }
  }

  startTesting = () => {
    this.testing = true
    this.syncInStorage()
  }

  endTesting = () => {
    this.testing = false
    this.syncInStorage()
  }

  @computed get isTesting () {
    return this.testing
  }

  addViewModel = (view: any) => {
    if (view.name) {
      this.viewModels[view.name] = view
      console.info(`${view.name} added to test store`)
    } else {
      console.warn('Trying to add a view model without a name')
    }
  }

  @action.bound inflate(data: any) {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }

  // Sync store data
  @action.bound syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('UITEST')
  }

  clearData() {
    this.testing = false
    this.syncInStorage()
  }

  // Use when we want to save to storage
  serialize() {
    return {
      testing: this.testing,
    }
  }
}
