import BaseErrorHandler from '../shared-lib/communication/BaseErrorHandler'
import { FormattedError } from '../shared-lib/communication/ErrorHelper'
import analytics from '../src/stores/analytics'

export default class ErrorHandler extends BaseErrorHandler {
  expiredCodes = ['AUTH_TOKEN_EXPIRED']

  get location() {
    return this.store.router.pathname
  }

  get userId() {
    return this.store.userStore.data._id
  }

  trackInAnalytics = (error: FormattedError) => {
    analytics.track('Error Message', { code: error.code, error })
  }

  // Session expired, try to re-auth
  handleExpiredSession = async () => {
    const {
      loadStore,
      uiStore,
      authStore,
      modalController,
      i18n: { s },
      clearAllData,
      userStore: { data: { mobile } },
    } = this.store

    authStore.setSessionExpired(true)

    if (authStore.userRole !== 'GUEST') {
      authStore.clearData()
      await modalController.closeAll()
      uiStore.showMessage({
        title: s('TOKEN_EXPIRED_TITLE'),
        body: s('TOKEN_EXPIRED_BODY_USER', mobile),
        buttons: [
          {
            title: s('Send verification code'),
            onPress: () => {
              this.loginExpiredSession()
            },
          },
          {
            title: s('Create a new account'),
            onPress: () => {
              this.createAccountExpiredSession()
            },
          },
        ],
        important: true,
      })
    } else {
      // create a new guest account and clear the basket
      clearAllData()
      authStore.clearData()
      await modalController.closeAll()

      await authStore.createGuestUser_api()

      // Load the main store
      loadStore()
    }
  }

  createAccountExpiredSession = () => {
    const {
      modalController,
      routeHandler,
      router,
    } = this.store

    routeHandler.postAuthenticationAction = () => {
      // Reload whole site on re-auth
      router.replace('/').then(() => router.reload())
    }

    modalController.openModal('auth', 'create-account')
  }

  // Re-authenticate expired session
  loginExpiredSession = async () => {
    const {
      authStore,
      userStore: { data: { mobile } },
      modalController,
      uiStore,
      i18n: { s },
      routeHandler,
      router,
    } = this.store

    authStore.setAuthFlow('sign-in')

    routeHandler.postAuthenticationAction = () => {
      // Reload whole site on re-auth
      router.replace('/').then(() => router.reload())
    }

    try {
      // Identify with mobile
      const userExists = await authStore.identifyWithMobile_api(mobile)
      if (!userExists) throw new Error('Failed to identify')

      // Then send the code
      const codeSent = await authStore.sendMobileVerification_api(mobile)
      if (codeSent) modalController.openModal('auth', 're-auth')
    } catch (e) {
      uiStore.showMessage({
        title: s('REAUTH_FAILED_TITLE'),
        body: s('REAUTH_FAILED_BODY'),
        buttons: [
          {
            title: s('Login manually'),
            onPress: () => {
              modalController.openModal('auth', 'sign-in')
            },
          },
        ],
      })
    }
  }

  showErrorInUI = (error: FormattedError) => {
    this.store.uiStore.handleErrors(error)
  }
}
