import { observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import analytics from '../../../src/stores/analytics'
import { $RootStore } from '../../../src/stores/RootStore'
import { minutesToMs } from '../../lib/utils'

export interface $TreatmentOptionGroup {
  name: string
  multiSelect: boolean
  defaultIndex: number | null
  options: Array<$TreatmentOption>
  optionIds?: Array<string>
}

export type $TreatmentOption = Partial<$Treatment>

export interface $TreatmentInfo {
  title: string
  body: string
}

export interface $Treatment {
  _id: string
  name: string
  price: number
  duration: number
  fee: number
  type: string
  isOption: boolean
  isTreatment: boolean
  isPackage: boolean
  shortDescription: string
  selected: boolean
  info: Array<$TreatmentInfo>
  optionGroups: Array<$TreatmentOptionGroup>
  tags: Array<string>
  numberOfPros?: number
}

export type $TreatmentModel = TreatmentModel

export const DefaultTreatmentData = {
  _id: '',
  name: '',
  price: 0,
  duration: 0,
  fee: 0,
  type: '',
  isOption: false,
  isTreatment: false,
  isPackage: false,
  shortDescription: '',
  selected: false,
  info: [],
  optionGroups: [],
  tags: [],
  numberOfPros: undefined,
}

export default class TreatmentModel extends ModelStore {
  @observable data: $Treatment = DefaultTreatmentData
  cacheLength = minutesToMs(15)
  fetchMethodName = 'getTreatment'

  constructor(o: Partial<$Treatment>, rootStore: $RootStore) {
    super(o, rootStore)

    const useTestPrices = !!analytics.ABTest('testPrices')

    this.defaultFetchVariables = { extra: { useTestPrices } }
  }
}
