import { inject, observer } from 'mobx-react'
import React from 'react'
import { Box, Typography } from '@material-ui/core'

import RootStore from '../src/stores/RootStore'
import { TextButton } from '.'

interface Props {
  store?: RootStore
}

const CookieBanner = observer((props: Props) => {
  const { store } = props
  const { uiStore, i18n: { s } } = store!

  if (!uiStore.cookieBannerVisible) {
    return null
  }

  const acceptCookies = (): void => {
    uiStore.hideCookieBanner()
  }

  return (
    <Box
      bgcolor='#e3e3e4'
      position='fixed'
      left={0}
      right={0}
      bottom={0}
      p={3}
      zIndex={399}
    >
      <Typography variant='caption' color='textSecondary'>
        {s('COOKIE_BANNER_MESSAGE_1')}{' '}
        <a style={{ color: '#A74C75' }} href='https://secretspa.co.uk/privacy-policy/' target='_blank' rel='noreferrer'>{s('COOKIE_BANNER_COOKIE_LINK_MESSAGE')}</a>
      </Typography>

      <Box mt={1}>
        <TextButton onClick={acceptCookies}>{s('Accept')}</TextButton>
      </Box>
    </Box>
  )

})

export default inject('store')(CookieBanner)
