import React from 'react'

import { Box } from '@material-ui/core'

const logo = {
  width: '100%',
  maxWidth: '280px',
}

class Logo extends React.Component<{ size?: number }> {
  render() {
    const { size = 18 } = this.props

    return <Box>
      <Box height={size} mb={4}>
        <img src='/img/logos/secret-spa-logo.svg' alt='Secret Spa' style={logo} />
      </Box>
    </Box>
  }
}

export default Logo