import { $RootStore } from '../../src/stores/RootStore'
import { $Postcode } from '../handlers/ServiceAreaHandler'
import { includesArray } from '../lib/utils'
import { $BookingItem } from '../stores/BookingStore/BookingModel'
import { $CategoryModel } from '../stores/CategoryStore/CategoryModel'
import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'
import { $Treatment } from '../stores/TreatmentStore/TreatmentModel'

export const treatmentsToIds = (treatments: Array<Partial<$Treatment>>) => {
  if (!treatments) return []
  return treatments.map((t: Partial<$Treatment>) => t._id || '')
}

export const professionalCanDoTreatments = (treatmentIds: Array<string>, professional?: $Professional) => {
  if (!professional) return false

  const proTreatmentIds = treatmentsToIds(professional.professionalProfile?.treatments || [])
  return includesArray(proTreatmentIds, treatmentIds)
}

export const professionalCannotDoOptions = (optionIds: Array<string>, professional?: $Professional) => {
  if (!professional) return false

  return includesArray(professional.professionalProfile?.disabledOptionIds || [], optionIds)
}

export const professionalUncoveredTreatmentNames = (treatmentIds: Array<string>, professional: $Professional | undefined, items: $BookingItem[]) => {
  if (!professional) return ''
  const uncoveredTreatmentsIds = professionalUncoveredTreatmentIds(treatmentIds, professional)
  return items.filter(i => uncoveredTreatmentsIds.includes(i.treatment._id || '')).map(t => t.treatment.name).join(', ')
}

export const professionalUncoveredTreatmentIds = (treatmentIds: Array<string>, professional?: $Professional) => {
  if (!professional) return []

  const proTreatmentIds = treatmentsToIds(professional.professionalProfile?.treatments || [])
  return treatmentIds.filter((_id: string) => !proTreatmentIds.includes(_id))
}

export const professionalUncoveredOptionNames = (optionIds: Array<string>, professional: $Professional | undefined, items: $BookingItem[]) => {
  if (!professional) return ''

  const uncoveredTreatmentsIds = professionalUncoveredOptionIds(optionIds, professional)

  const names: Array<string> = []
  items.forEach(i => i.treatment.optionGroups?.forEach(optionGroup => optionGroup.options?.forEach(option => {
    if (option._id && uncoveredTreatmentsIds.includes(option._id)) {
      names.push(option.name?.replace('Add', '') || '')
    }
  })))

  return names.join(', ')
}

export const professionalUncoveredOptionIds = (optionIds: Array<string>, professional?: $Professional) => {
  if (!professional) return []

  const disabledOptionIds = professional.professionalProfile?.disabledOptionIds || []
  return optionIds.filter((_id: string) => disabledOptionIds.includes(_id))
}

export const professionalCoversPostcode = (postcodeId: string, professional?: $Professional) => {
  if (professional?.professionalProfile?.postcodes) {
    return !!professional.professionalProfile.postcodes.find((p: $Postcode) => p._id === postcodeId)
  }

  return false
}

export const filteredProCategories = (categories: Array<$CategoryModel>, professional?: $Professional) => {
  if (!professional || !professional.professionalProfile) return categories.filter(c => c.data.active)

  const treatmentIds = treatmentsToIds(professional.professionalProfile?.treatments)

  return categories.filter((category: $CategoryModel) => {
    return category.data.treatmentIds.some(c => treatmentIds.includes(c))
  })
}

export const filterTreatmentIdsByPro = (treatmentIds: Array<string>, professional?: $Professional) => {
  if (!professional || !professional.professionalProfile) return treatmentIds

  const proTreatmentIds = treatmentsToIds(professional.professionalProfile?.treatments)

  return treatmentIds.filter((treatmentId: string) => {
    return proTreatmentIds.includes(treatmentId)
  })
}

export const showGenderPreferencePopup = (store: $RootStore, firstName: string, genderPreference: string, callback: () => void) => {
  const { uiStore, checkoutStore } = store

  uiStore.showMessage({
    title: `${firstName} only accepts bookings from ${genderPreference} clients.`,
    body: 'Please confirm that you (or the person who will be attending the booking) understand this requirement.',
    buttons: [
      {
        title: `Yes, I am ${genderPreference}`,
        onPress: () => {
          checkoutStore.setClientDetails({ ...checkoutStore.booking.data.clientDetails[0], gender: genderPreference })
          callback()
        },
      },
      {
        title: 'Select different professional',
      },
    ],
  })
}

export const refreshUserGender = (store: $RootStore) => {
  const { userStore, checkoutStore, authStore } = store

  let gender = ''
  if (authStore.isLoggedIn) {
    gender = userStore.data.gender
  }

  checkoutStore.setClientDetails({ ...checkoutStore.booking.data.clientDetails[0], gender })
}
