import { observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import { $Professional } from '../ProfessionalStore/ProfessionalModel'

export interface $Favourite {
  _id: string
  userId: string
  professional: Partial<$Professional>
}

export type $FavouriteModel = FavouriteModel

export const DefaultFavouriteData = {
  _id: '',
  userId: '',
  professional: {
    _id: '',
    firstName: '',
    lastName: '',
    treatmentIds: [],
    postcodeIds: [],
  },
}

export default class FavouriteModel extends ModelStore {
  @observable data: $Favourite = DefaultFavouriteData
}
