import { Moment } from 'moment'
import { CheckoutMode } from '../../shared-consumer/graph/generated/enums'
import { $UpdateBookingAddressResult } from '../../shared-consumer/handlers/ServiceAreaHandler'
import { showGenderPreferencePopup } from '../../shared-consumer/helpers/professionalBookingHelper'
import { $BookingModel } from '../../shared-consumer/stores/BookingStore/BookingModel'
import { $Professional } from '../../shared-consumer/stores/ProfessionalStore/ProfessionalModel'
import { sleep } from '../../shared-lib/utils'
import { breakPoints } from '../../theme'
import analytics from '../stores/analytics'
import RootStore from '../stores/RootStore'

export default class RouteHandler {
  rootStore: RootStore
  postAuthenticationAction: (() => void) | undefined = undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  redirectPostLogin = async () => {
    if (this.postAuthenticationAction) {
      this.postAuthenticationAction.call(this)
      delete this.postAuthenticationAction
    }
  }

  redirectPreCheckout = async () => {
    const { newBookingHandler, router, uiStore, modalController, checkoutStore } = this.rootStore

    // Ensure modal is closed
    await modalController.closeAll()

    const flow = await newBookingHandler.preparePreCheckout()

    if (flow === 'error') {
      uiStore.showCheckoutErrorPopup(this.goToRootRoute)
    } else if (flow === 'pro:treatment:time') {
      router.push(`/booking/select-time?pro=${checkoutStore.provisionalProfessional._id}`)
        .then(() => window.scrollTo(0, 0))
    } else {
      this.goToFindProfessionals()
    }
  }

  redirectPostUpdateAddress = async (result: $UpdateBookingAddressResult) => {
    const { modalController } = this.rootStore

    await modalController.closeAll()

    if (result === 'redirectHome') {
      this.redirectToHomeAndFetchCategories()
    } else if (result === 'redirectPreCheckout') {
      this.redirectPreCheckout()
    } else if (result === 'afterLoginOk') {
      this.redirectPostLogin()
    }
  }

  handleInitialRoute = async () => {
    const { modalController, router, orderTrackerHandler } = this.rootStore

    // window.__initialHash is set on page load inside routing.ts
    let initialHash = window.__initialHash || modalController.windowHash

    const queryParams = new URLSearchParams(window.location.search)
    const screenName = queryParams.get('screen')

    // Support deep links
    if (screenName) {
      const _id = queryParams.get('_id')

      if (screenName === 'ViewBooking' && _id) {
        initialHash = `#bookings#bookingDetails/${_id}`
      } else if (screenName === 'Redirection') {
        const priceId = queryParams.get('priceId')
        const mode = queryParams.get('mode')
        window.location.href = `/redirection/${mode}?priceId=${priceId}`
      }
    }

    orderTrackerHandler.checkForTracking(queryParams)

    if (router.pathname === '/404') return

    // // Force next js to load correct path
    await router.replace(window.location.pathname)

    // Set the #hashes
    modalController.handleInitialHash(initialHash)
  }

  redirectToHomeAndFetchCategories = async () => {
    const { router, treatmentStore, categoryStore, checkoutStore, modalController } = this.rootStore

    await modalController.closeAll()
    treatmentStore.clearData()
    categoryStore.clearData()

    await categoryStore.fetchItems_api({
      variables: {
        filter: { regions: { $in: [checkoutStore.booking.data.region] } },
      },
      clear: true,
    })

    router.push('/')
    setTimeout(() => {
      this.goToRootRoute()
    }, 100)
  }

  goToRootRoute = () => {
    const { categoryStore: { items }, router } = this.rootStore

    const isDektop = window.innerWidth > breakPoints.md

    let url = '/categories'
    let urlAs = '/categories'

    if (isDektop && items.length > 0) {
      url = '/categories/[id]'
      urlAs = `/categories/${items[0].urlSafeName}`
    }

    router.push(url, `${urlAs}`)
  }

  redirectPreBookingSummary = () => {
    const {
      checkoutStore: { booking },
      authStore,
      modalController,
      router,
    } = this.rootStore

    // If user is not signed in, send them to Auth flow
    if (!authStore.isLoggedIn) {
      analytics.track('Checkout: Prompted to Login')

      modalController.closeAll()

      this.postAuthenticationAction = () => {
        this.redirectPreBookingSummary()
      }
      modalController.openModal('auth')

      return
    }

    if (booking) {
      const { clientDetails } = booking.data

      if (booking.data.professional) {
        const { professionalProfile, firstName } = booking.data.professional
        const { genderPreference } = professionalProfile || {}

        //Check if pro covers gender
        if (genderPreference && genderPreference !== 'none' && clientDetails[0].gender !== genderPreference) {
          showGenderPreferencePopup(this.rootStore, firstName, genderPreference, this.redirectPreBookingSummary)
          return
        }
      }
      router.push('/booking/summary')
    }
  }

  goToViewDiary = (professional: $Professional) => {
    const { router, checkoutStore } = this.rootStore

    checkoutStore.setFlow('treatment:pro:time')
    router.push(`/booking/select-time?pro=${professional._id}`).then(() => window.scrollTo(0, 0))

    analytics.incrementSessionContextProperty('professionalDiaryViewed', 1)
  }

  goToFindProfessionals = (selectedDate?: Moment) => {
    const { router, checkoutStore, editBookingHandler } = this.rootStore

    checkoutStore.setFlow('treatment:time:pro')

    let route = '/booking/select-professional-time'
    let liquidity = 'high'

    if (selectedDate) {
      route += `?selectedDate=${selectedDate.valueOf()}`
    }

    const isEditingBooking = !!editBookingHandler.editingBookingId

    let useLowLiquidityUI = false

    if (checkoutStore.bookingRegionOrDefault !== 'london' && !!analytics.ABTest('nonLondonLowLiquidityUI')) {
      liquidity = 'low'
      useLowLiquidityUI = true
    }

    if (useLowLiquidityUI && !isEditingBooking) {
      route = '/booking/select-professional-low'
    }

    analytics.setSessionContext({ liquidity })
    router.push(route).then(() => window.scrollTo(0, 0))
  }

  redirectFromDeepLink = () => {
    const url = window.location.href

    if (url.includes('/pro/')) {
      this.handleProfessionalDeeplink(url)
    } else {
      this.goToRootRoute()
    }
  }

  redirectToStripeSubscription = () => {
    this.redirectToStripe(CheckoutMode.Subscription)
  }

  redirectToStripePayment = () => {
    this.redirectToStripe(CheckoutMode.Payment)
  }

  redirectToStripe = async (mode: CheckoutMode) => {
    const { router, communication, userStore } = this.rootStore

    await userStore.fetch_api()

    const priceId = Array.isArray(router.query.priceId) ? router.query.priceId[0] : router.query.priceId

    if (mode === CheckoutMode.Subscription) {
      const status = userStore.data.subscriptionDetails.status
      analytics.track('Subscription Redirection', { priceId, status })

      if (status === 'active') {
        const { manageSubscriptionUrl } = await communication.requester.manageSubscriptionUrl()

        if (manageSubscriptionUrl) {
          router.push(manageSubscriptionUrl)
          return
        }
      } else {
        const checkoutData = { priceId, mode }
        const { checkoutUrl } = await communication.requester.checkoutUrl({ checkoutData })

        if (checkoutUrl) {
          router.push(checkoutUrl)
          return
        }
      }
    } else if (mode === CheckoutMode.Payment && priceId) {
      analytics.track('Bundle Redirection', { priceId })

      const checkoutData = { priceId, mode }
      const { checkoutUrl } = await communication.requester.checkoutUrl({ checkoutData })

      if (checkoutUrl) {
        router.push(checkoutUrl)
        return
      }
    }

    this.goToRootRoute()
  }

  handleProfessionalDeeplink = async (url: string) => {
    const { router, modalController: { openModal } } = this.rootStore

    // Get profileUrl
    const urlParts = url.split('/')
    const endOfUrl = urlParts[urlParts.length - 1]
    const profileUrl = endOfUrl.split('?')[0]

    const urlParams = new URLSearchParams(window.location.search)

    // Get professional profile
    const response = await this.rootStore.communication.requester.getProfessional({ profileUrl })

    // Has found professional
    if (response.getProfessional) {
      router.push('/categories', '/categories').then(() => {
        openModal('professionalProfile', response.getProfessional._id, urlParams.get('trade-test') ? 'tradeTest' : 'bookWith')
      })
    } else {
      this.goToRootRoute()
    }
  }

  showPopupOnRoute = () => {
    const { router: { pathname } } = this.rootStore

    if (pathname === '/application' || pathname === '/pro/[profileUrl]' || pathname === '/subscription/[pageUrl]') {
      return false
    } else {
      return true
    }
  }

  cleanQueriesFromUrl = () => {
    const { router } = this.rootStore
    const baseUrl = router.asPath.split('?')[0].split('#')[0]
    router.replace(router.route, `${baseUrl}${window.location.hash}`, { shallow: true })
  }

  showClientDetails = () => {
    const { modalController } = this.rootStore
    modalController.openModal('editClientDetails')
  }

  handleAuthRequired = () => {
    const { authStore, modalController, uiStore } = this.rootStore

    if (!authStore.isLoggedIn) {
      const postLoginHash = window.location.hash
      this.postAuthenticationAction = () => {
        modalController.openModalFromHash(postLoginHash)
      }
      modalController.closeAll()
      uiStore.showLoginRequiredModal()
      return false
    }

    return true
  }

  startChangeBookingTime = async (booking: $BookingModel) => {
    const { editBookingHandler, router, modalController } = this.rootStore

    const { professional, _id } = booking.data

    await modalController.closeAll()
    editBookingHandler.editBookingTime(_id)
    router.push(`/booking/select-time?pro=${professional._id}`).then(() => window.scrollTo(0, 0))
  }

  finishChangeBookingTime = async () => {
    const { modalController, router, checkoutStore, editBookingHandler } = this.rootStore

    checkoutStore.provisionalProfessional = undefined
    router.replace('/categories')
    editBookingHandler.clearEditingBookingId()

    // Wait for redirect
    await sleep(100)
    modalController.openModal('bookings')
  }
}
