export enum AdvancePaymentOptionId {
  FullDeposit = 'FULL_DEPOSIT',
  No = 'NO',
  PartialDeposit = 'PARTIAL_DEPOSIT'
}

export enum AllowedBookingTimes {
  AnyDayAnyTime = 'ANY_DAY_ANY_TIME',
  AnyRequestOrInstantDay = 'ANY_REQUEST_OR_INSTANT_DAY',
  UseInstantAvailability = 'USE_INSTANT_AVAILABILITY'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum CheckoutMode {
  Payment = 'payment',
  Subscription = 'subscription'
}

export enum CompletePaymentMethodOptionId {
  Bank = 'bank',
  Cash = 'cash',
  Machine = 'machine',
  Other = 'other',
  Stripe = 'stripe'
}

export enum ConsultationFormStatus {
  Completed = 'COMPLETED',
  NotRequired = 'NOT_REQUIRED',
  ReminderSent = 'REMINDER_SENT',
  Requested = 'REQUESTED'
}

export enum ContactType {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Phone = 'PHONE',
  Tiktok = 'TIKTOK'
}

export enum DiaryEventWarningStatus {
  Bad = 'bad',
  None = 'none',
  Warn = 'warn'
}

export enum DiscountType {
  Monies = 'monies',
  Percent = 'percent'
}

export enum ExternalBookingPaymentStatus {
  DepositNotPaid = 'DEPOSIT_NOT_PAID',
  DepositPaid = 'DEPOSIT_PAID',
  FullyPaid = 'FULLY_PAID',
  NoDepositNotPaid = 'NO_DEPOSIT_NOT_PAID',
  NoDepositPartiallyPaid = 'NO_DEPOSIT_PARTIALLY_PAID'
}

export enum ExternalBookingSource {
  BookingPortal = 'BOOKING_PORTAL',
  ProApp = 'PRO_APP'
}

export enum ExternalBookingStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  CancelRequested = 'CANCEL_REQUESTED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  ToComplete = 'TO_COMPLETE'
}

export enum MessageType {
  Modal = 'MODAL'
}

export enum MixedPayoutStatus {
  Arrived = 'ARRIVED',
  OnTheWay = 'ON_THE_WAY',
  Processing = 'PROCESSING',
  Projected = 'PROJECTED',
  Refunded = 'REFUNDED'
}

export enum OnboardingStepStatus {
  Completed = 'COMPLETED',
  NotComplete = 'NOT_COMPLETE',
  Skipped = 'SKIPPED'
}

export enum PaymentMethodOptionId {
  ManualCollection = 'MANUAL_COLLECTION',
  PaymentLink = 'PAYMENT_LINK'
}

export enum PaymentTypes {
  Bank = 'bank',
  Cash = 'cash',
  Machine = 'machine',
  Other = 'other',
  Stripe = 'stripe',
  StripeRefund = 'stripe_refund'
}

export enum Platform {
  Ca = 'CA',
  Pa = 'PA',
  Wb = 'WB'
}

export enum QuestionType {
  Image = 'IMAGE',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'TEXT'
}

export enum RedeemableBy {
  Anyone = 'anyone',
  Custom = 'custom',
  ExistingClients = 'existingClients',
  NewClients = 'newClients'
}

export enum SpecialQuestions {
  Comments = 'comments'
}
