export const locations = {
  private: 'Private parking',
  street: 'On my street',
  streetClose: 'On a nearby street',
  other: 'Other nearby parking',
  none: 'I don\'t know where to park nearby',
}

type ParkingLocations = keyof typeof locations
export const parkingLocationTypes = [...Object.keys(locations) as Array<ParkingLocations>]

export const locationsDetails = {
  private: 'Any useful information for the pro such as a gate code?',
  street: 'Street parking details',
  streetClose: 'Street parking details',
  other: 'Parking information',
}

export const defaultParkingInformation = {
  _id: '',
  locationDetails: '',
  locationType: '',
  cost: '',
  note: '',
  addressId: '',
  postcode: '',
  proComments: [],
}

export const costDetails = [
  'Free',
  'Free for limited time',
  'Less than £3 an hour',
  'More than £3 an hour',
]