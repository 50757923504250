import { observable } from 'mobx'
import { nullCleanObject } from '../../shared-lib/utils'

import analytics from '../../src/stores/analytics'
import RootStore from '../../src/stores/RootStore'
import { $UserAddress, $UserAddressModel } from '../stores/UserAddressStore/UserAddressModel'

export interface $Postcode {
  _id: string
  code: string
  name: string
  region: string
  active?: boolean
}

export type $PostcodeChangeResult = 'noChange' | 'outerCodeChanged' | 'regionChanged'
export type $UpdateBookingAddressResult = 'ok' | 'redirectHome' | 'redirectPreCheckout' | 'afterLoginOk'

export const getOuterCode = (postcode: string) => {
  const x = postcode.replace(/\s/g, '')
  return x.substr(0, x.length - 3)
}

export default class ServiceAreaHandler {
  rootStore: RootStore
  @observable loading = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  checkPostcodeInServiceArea_api = async (postcode: string): Promise<$Postcode | undefined> => {
    const { communication: { requester } } = this.rootStore
    this.loading = true

    try {
      const response = await requester.checkPostcodeInServiceArea({ postcode })
      this.loading = false
      const postcodeResponse = response.checkPostcodeInServiceArea

      return nullCleanObject(postcodeResponse) || undefined
    } catch (e) {
      this.loading = false
      return undefined
    }
  }

  postcodeChangeResult = (postcode: $Postcode): $PostcodeChangeResult => {
    const { checkoutStore: { bookingRegionOrDefault, booking } } = this.rootStore

    let result = 'noChange'
    if (booking.data.postcode.code !== postcode.code) result = 'outerCodeChanged'
    if (bookingRegionOrDefault !== postcode.region) result = 'regionChanged'

    return result as $PostcodeChangeResult
  }

  verifyAndSetInitialAddress = async (address: $UserAddress) => {
    const { checkoutStore } = this.rootStore

    const postcode = await this.checkPostcodeInServiceArea_api(address.postalCode)

    if (postcode) {
      checkoutStore.setBookingAddress(postcode, address)

      return true
    }

    return false
  }

  verifyPostcodeIsInServiceArea = async (postalCode: string) => {
    const { uiStore, userStore, communication } = this.rootStore

    const postcode = await this.checkPostcodeInServiceArea_api(postalCode)

    if (!postcode) {

      if (!communication.latestErrorCode) {
        const outerCode = getOuterCode(postalCode)

        uiStore.showPostcodeOutsideServiceAreaPopup()
        analytics.track('Postcode Outside Service Area', {
          outerCode,
          fullCodeEntered: postalCode,
          emailAddress: userStore.data.emailAddress,
        })
      }

      return
    }

    return postcode
  }

  handleChangeBookingAddress = async (addressModel: $UserAddressModel,
    postcode: $Postcode,
    inCheckout: boolean,
    onComplete: (result: $UpdateBookingAddressResult) => void,
    onCompleteOk: $UpdateBookingAddressResult = 'ok',
  ) => {
    const { checkoutStore, newBookingHandler, serviceAreaHandler, uiStore } = this.rootStore
    const { setBookingAddress, selectSendToAll, booking, bookingIsEmpty, provisionalProfessional, removeProvisionalProfessionalFromBooking } = checkoutStore
    const { confirmProfessionalCoversPostcode } = newBookingHandler

    const professional = inCheckout ? booking.data.professional : provisionalProfessional

    const changeResult = serviceAreaHandler.postcodeChangeResult(postcode)
    const bookingRequiresValidation = !bookingIsEmpty || professional

    if (changeResult === 'regionChanged') {
      const onAccept = () => {
        analytics.track('Booking Region Changed', { from: booking.data.region, to: postcode.region })
        booking.clear()
        setBookingAddress(postcode, addressModel.data)
        onComplete('redirectHome')
      }

      // If region has changed we need to clear booking and refetch categories
      if (bookingRequiresValidation) {
        uiStore.showRegionChangedPopup(onAccept)
      } else {
        onAccept()
      }

      return
    }

    if (!bookingRequiresValidation || changeResult === 'noChange') {
      // If no items or change in postcode then just continue
      analytics.track('Booking Innercode Changed', { from: booking.data.postcode.code, to: postcode.code })
      setBookingAddress(postcode, addressModel.data)

      onComplete(onCompleteOk)

      return
    }

    if (changeResult === 'outerCodeChanged') {
      if (professional) {
        confirmProfessionalCoversPostcode(postcode, professional, (removed: boolean) => {
          setBookingAddress(postcode, addressModel.data)
          if (removed) {
            removeProvisionalProfessionalFromBooking()
            onComplete('redirectHome')
          }
        })
      } else if (inCheckout) {
        // If outer code has changed and we're at booking summary then we need to force user to reselect their pro
        uiStore.showOuterCodeChangedPopup(() => {
          analytics.track('Booking Outercode Changed', { from: booking.data.postcode.code, to: postcode.code })

          selectSendToAll() // Remove pro & requested pros
          setBookingAddress(postcode, addressModel.data)
          onComplete('redirectPreCheckout')
        })
      } else {
        // If no items or change in postcode then just continue
        analytics.track('Booking Outercode Changed', { from: booking.data.postcode.code, to: postcode.code })
        setBookingAddress(postcode, addressModel.data)
        onComplete(onCompleteOk)
      }
    }
  }
}
