import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Typography } from '@material-ui/core'

import { currencyFormat } from '../../shared-lib/currency'
import RootStore from '../../src/stores/RootStore'

interface Props {
  store?: RootStore
}

@inject('store')
@observer
class ItemsTotal extends Component<Props> {
  render() {
    const { store } = this.props
    const { totalPriceComputed } = store!.checkoutStore.booking
    const { i18n: { s } } = store!

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between' mb={2}>
        <Typography variant='body2'>{s('Total')}</Typography>
        <Typography variant='body2'>{currencyFormat(totalPriceComputed)}</Typography>
      </Box>
    )
  }
}

export default ItemsTotal
