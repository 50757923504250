import { inject, observer } from 'mobx-react'
import React from 'react'
import Image from 'next/image'
import { Box, Hidden, withStyles, WithStyles } from '@material-ui/core'

import RootStore from '../src/stores/RootStore'
import { colors, theme } from '../theme'
import HelpTextIcon from '../public/img/icons/helpText.png'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
  type?: 'floatingMobile' | 'floatingDesktop' | 'inPlace'
  position?: { top?: number, left?: number, bottom?: number, right?: number }
}

const styles = styleCreate(theme => ({
  general: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  floatingMobile: {
    position: 'fixed',
    background: colors.BG100,
    borderRadius: theme.spacing(20),
    boxShadow: '#653ed310 0px 0px 15px',
  },
  floatingDesktop: {
    position: 'fixed',
    background: colors.BG100,
    borderRadius: theme.spacing(20),
    boxShadow: '#653ed310 0px 0px 15px',
  },
}))

const ZendeskHelpButton = withStyles(styles)(inject('store')(observer((props: Props) => {
  const {
    store,
    classes,
    type = 'inPlace',
    position,
  } = props

  const { zendeskHandler } = store!

  const containerClassName = [
    classes.general,
    classes[type],
  ].join(' ')

  return (
    <Box
      onClick={zendeskHandler.openWindow}
      style={{ ...position }}
      className={containerClassName}
      aria-label='zendesk-help-button'
    >
      <Box fontSize={0} ml={0.5}>
        <Image
          alt='Help Icon'
          src={HelpTextIcon}
          width={theme.spacing(9.6)}
          height={theme.spacing(3.4)}
          priority
        />
      </Box>
    </Box>
  )
})))

export default ZendeskHelpButton

export const ZendeskHelpButtonContainer = () => {
  return (
    <Hidden mdUp>
      <Box position='absolute' right={2}>
        <ZendeskHelpButton />
      </Box>
    </Hidden>
  )
}
