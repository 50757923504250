import { action, computed } from 'mobx'

import ListStore from '../../../shared-lib/stores/ListStore'
import { minutesToMs } from '../../lib/utils'
import FavouriteModel, { $FavouriteModel } from './FavouriteModel'

export default class FavouriteStore extends ListStore<$FavouriteModel> {
  fetchMethodName = 'favourites'
  cacheLength = minutesToMs(15)
  ModelClass = FavouriteModel

  @action.bound async addFavourite_api(professionalId: string): Promise<void> {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      const response = await requester.addFavourite({ professionalId })
      const favouriteData = response.addFavourite
      favouriteData && this.mergeData([favouriteData])

      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }

  @action.bound async removeFavourite_api(professionalId: string): Promise<void> {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      const response = await requester.removeFavourite({ professionalId })
      const removed = response.removeFavourite

      if (removed) {
        this.items = this.items.filter(i => {
          return i.data.professional._id !== professionalId
        })
      }

      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }

  professionalIsFavourite = (professionalId?: string) => {
    if (!professionalId) return false
    const isFavourite = !!(this.items.find(x => x.data.professional._id === professionalId))
    return isFavourite
  }

  @computed get favouriteProIds() {
    return this.items.map(favourite => favourite.data.professional._id!)
  }
}

export type $FavouriteStore = FavouriteStore
