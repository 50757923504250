import momentTz from 'moment-timezone'

import { penceToPounds } from '../shared-lib/currency'
import RootStore from '../src/stores/RootStore'
import trackOrder from '../scripts/rakuten_order'
import { checkCookies, setCookies } from 'cookies-next'
import analytics from '../src/stores/analytics'
import { OptionsType } from 'cookies-next/lib/types'

interface RakutenParams {
  ranMID: string
  ranEAID: string
  ranSiteID: string
}

export default class OrderTrackerHandler {
  rootStore: RootStore
  ordersTracked: string[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  orderHasBeenTracked = (bookingId: string): boolean => {
    return this.ordersTracked.includes(bookingId)
  }

  rakutenOrderTrackingUrl = async (bookingId: string) => {
    const { bookingStore } = this.rootStore
    const { findItemById } = bookingStore

    const booking = findItemById(bookingId)

    if (!booking) return null

    this.ordersTracked.push(bookingId)
    this.syncInStorage()

    const { _id, promoCode, promoCodeDiscount, items, status } = booking.data
    const { _id: customerId } = this.rootStore.userStore.data
    const currency = 'GBP'

    const lineitems = items.map(item => {
      const { treatment: { price = 0, name, type } } = item
      return {
        quantity: 1,
        unitPrice: penceToPounds(price),
        unitPriceLessTax: penceToPounds(price),
        SKU: name,
        productName: name,
        optionalData: {
          type: type,
        },
      }
    })

    const rm_trans = {
      affiliateConfig: { ranMID: '47913', discountType: 'item', includeStatus: 'false', taxRate: 20, removeTaxFromDiscount: true },
      orderid : _id,
      currency: currency,
      customerStatus: analytics.userTraits.numberOfBookings > 0 ? 'EXISTING' : 'NEW',
      conversionType: 'Sale',
      customerID: customerId,
      discountCode: promoCode?.promoCode ?? '',
      discountAmount: penceToPounds(promoCodeDiscount),
      taxAmount: 0,
      lineitems,
      optionalData: {
        status: status,
      },
    }

    trackOrder(window,document,rm_trans)
  }

  checkForTracking = (queryParams: URLSearchParams) => {
    const ranMID = queryParams.get('ranMID')
    const ranEAID = queryParams.get('ranEAID')
    const ranSiteID = queryParams.get('ranSiteID')

    if (!!ranMID && !!ranEAID && !!ranSiteID) {
      this.setRakutenCookies({ ranMID, ranEAID, ranSiteID })
    }
  }

  setRakutenCookies = (params: RakutenParams) => {
    let options: OptionsType = {
      maxAge: 180 * 86400,
      sameSite: 'lax' as const,
      path: '/',
    }

    if (process.env.SS_ENV && process.env.SS_ENV !== 'development') {
      const domain = {
        production: '.secretspa.co.uk',
        staging: '.secretspa.io',
      }[process.env.SS_ENV]

      options = {
        ...options,
        secure: true,
        domain: domain,
      }
    }

    if (checkCookies('rmStoreGateway', options)) {
      // If we already have a cookie, we don't need to set it again
      return
    }

    const paramString = `amid:${params.ranMID}|atrv:${params.ranSiteID}`
    const value = paramString + `|ald:${momentTz().utc().format('YYYYMMDD_HHmm')}` + `|auld:${Date.now()}`
    const stringValue = String(value)

    setCookies('rmStoreGateway', stringValue, options)
  }

  // Sync store data in storage
  syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('ORDERS')
  }

  inflate = (data: Record<string, any>) => {
    Object.keys(data).forEach(key => {
      this[key as keyof typeof this] = data[key]
    })
  }

  serialize = () => {
    return {
      ordersTracked: this.ordersTracked,
    }
  }
}
