import { action, observable } from 'mobx'

import { $RootStore } from '../../src/stores/RootStore'
import { ApolloRequesterOptions, Sdk } from '../communication/requester'
import { mergeDeep } from '../utils'

interface $opts { variables?: any, clear?: boolean, cacheLength?: number }

class ModelStore {
  @observable loading = false
  @observable refreshing = false
  @observable data: any = {}
  cacheLength = 0
  lastUpdatedAt = 0
  rootStore: $RootStore
  fetchMethodName = ''
  saveMethodName = ''
  removeMethodName = ''
  defaultFetchVariables: Record<string, any> = {}

  constructor(o: any, rootStore: $RootStore) {
    // Set initial data
    this.rootStore = rootStore
    this.merge(o)
  }

  clearCache = () => {
    this.lastUpdatedAt = 0
  }

  // If we have exceeded cache length
  useCached = (cacheLength: number) => {
    return this.lastUpdatedAt + cacheLength > Date.now() ? 'cache-first' : 'network-only'
  }

  // Should return data to merge
  fetchMethod_api = async <T, K extends keyof T>(variables: any, options: ApolloRequesterOptions): Promise<T[K] | null> => {
    const combinedVariables = mergeDeep({ ...this.defaultFetchVariables }, variables || {})

    try {
      const ret = await this.rootStore.communication.requester[this.fetchMethodName as keyof Sdk](
        {
          _id: this.data._id,
          ...combinedVariables,
        },
        options,
      )
      return ret && ret[this.fetchMethodName as keyof typeof ret] || null
    } catch (e) {
      // Error is handled in Communication
      return null
    }
  }

  // Refresh data, used when you pull to refresh
  @action.bound refresh_api = async () => {
    this.refreshing = true
    this.clearCache()

    await this.fetch_api()

    this.refreshing = false
  }

  // Fetch new data and merge with current
  @action.bound fetch_api = async (opts: $opts = {}) => {
    const { variables } = opts

    this.loading = true

    const fetchPolicy = this.useCached(opts.cacheLength || this.cacheLength)
    const data = await this.fetchMethod_api(variables, { fetchPolicy })

    // If data received set on data object
    if (data) {
      this.merge(data)
      // Set last updated at for cache
      this.lastUpdatedAt = Date.now()
    }

    this.loading = false
  }

  // Save new data to server
  @action.bound save_api = async (): Promise<any> => {
    this.loading = true

    try {
      // Execute the save method
      const response = await this.rootStore.communication.requester[this.saveMethodName as keyof Sdk](
        { ...this.saveArgs() },
      )
      this.loading = false

      // Response failed so return
      if (!response) return false

      // Extract data
      const o = response[this.saveMethodName as keyof typeof response]

      // Set data returned by server on object
      this.merge(o)

      // Set last updated at for cache
      this.lastUpdatedAt = Date.now()

      return this
    } catch (e) {
      this.loading = false
      return false
    }
  }

  removeMethod_api = async (): Promise<any> => {
    this.loading = true
    // Execute the remove method
    try {
      const response: any = await this.rootStore.communication.requester[this.removeMethodName as keyof Sdk](
        { _id: this.data._id } as any,
      )

      // Response failed so return
      if (!response) return false

      return !!response[this.removeMethodName]
    } catch {
      return false
    } finally {
      this.loading = false
    }
  }

  // Remove record from server
  @action.bound remove_api = async () => {
    this.loading = true
    const deleteCount = await this.removeMethod_api()
    this.loading = false
    return !!deleteCount
  }

  // Merge data on class data object
  @action.bound merge(o: any) {
    const data = { ...o }
    delete data.__typename // remove __typename

    mergeDeep(this.data, data)
  }

  // Set data on class data object
  @action.bound set(o?: Record<string, any>) {
    const data = { ...o }
    delete data.__typename // remove __typename

    for (const i in data) {
      this.data[i] = data[i]
    }
  }

  // Args for saving remotely
  saveArgs = (): any => {
    return {
      data: this.data,
    }
  }

  // Called when populating with data from storage
  inflate(o: any) {
    this.merge(o)
  }

  // Used when syncing to storage
  serialize = () => {
    return this.data
  }
}

export default ModelStore
