import Link from 'next/link'
import React from 'react'

import { Button, CircularProgress, Size, styled } from '@material-ui/core'
import { colors, theme } from '../theme'
import { snakeCase } from 'voca'

const StyledButton = styled(Button)({
  border: 0,
  borderRadius: theme.spacing(5),
  boxShadow: `0 3px 5px 2px ${colors.PRI900}20`,
  height: theme.spacing(9),
  padding: '0 30px',
  textTransform: 'none',
  fontWeight: 200,
  fontSize: '1.1rem',
  color: `${colors.BG100}`,
  '&:hover': {
    boxShadow: `0 3px 5px 5px ${colors.PRI900}20`,
  },
  '&.MuiButton-containedSizeSmall': {
    height: theme.spacing(6),
    fontSize: '14px',
    fontWeight: '700',
    padding: theme.spacing(0, 3),
  },
  '&.MuiButton-containedSecondary': {
    color: colors.PRI900,
    backgroundColor: colors.BG100,
  },
  '&.MuiButton-containedPrimary': {
    background: `linear-gradient(25deg, ${colors.PRI900}, ${colors.PRI910})`,
  },
  '&.MuiButton-containedPrimary:hover': {
    background: `linear-gradient(25deg, ${colors.PRI900}, ${colors.PRI800})`,
  },
  '&:disabled': {
    background: `${colors.INK300} !important`,
    boxShadow: 'none !important',
  },
})

interface Props {
  className?: string
  children: any
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  href?: any
  as?: string
  disabled?: boolean
  color?: any
  variant?: any
  fullWidth?: boolean
  endIcon?: any
  startIcon?: any
  style?: any
  size?: Size
  ariaPrefix?: string
  loading?: boolean
}

const RectButton = (props: Props) => {
  const {
    className = '',
    ariaPrefix = '',
    disabled,
    color = 'primary',
    size,
    children,
    onClick,
    fullWidth,
    variant = 'contained',
    href,
    as,
    endIcon,
    startIcon,
    style,
    loading = false,
  } = props

  const content = loading ? <CircularProgress size={25} /> : children

  const inner = <StyledButton
    className={className}
    disabled={disabled || loading}
    color={color}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
      // Remove focus from the button on press, otherwise subsequent "enter" key presses will cause another submission
      // @ts-ignore
      e.target.blur && e.target.blur()
      onClick(e)
    }}
    size={size}
    fullWidth={fullWidth}
    variant={variant}
    endIcon={endIcon}
    startIcon={startIcon}
    style={style}
    aria-label={snakeCase(`${children} ${ariaPrefix} button`)}
  >
    {content}
  </StyledButton>

  return onClick ? inner : <Link href={href} as={as || href}>{inner}</Link>
}

export default RectButton
