import { action, observable } from 'mobx'

import RootStore from '../../../src/stores/RootStore'

export default class MarkdownStore {
  rootStore: RootStore
  @observable loading = false
  @observable page: any = {}

  // Set rootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  /*
  * Requests methods
  */

  @action.bound async getPage_api(type: string): Promise<void> {
    const { communication: { requester } } = this.rootStore

    try {
      const response = await requester.page({ type })
      if (response.page) this.page = response.page
      return
    } catch {
      return
    }
  }
}
