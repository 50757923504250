import { action, computed, observable } from 'mobx'

import analytics from '../src/stores/analytics'
import RootStore from '../src/stores/RootStore'
import { penceToPounds } from '../shared-lib/currency'

interface $MentionMeSettings {
  situation?: string
  visible?: boolean
  extraProps?: Record<string, any>
  urlType?: string
}

export default class MentionMeController {
  rootStore: RootStore
  @observable mentionMeSettings: $MentionMeSettings = {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  showPostPurchaseModal = (bookingId: string): void => {
    const { bookingStore: { findItemById } } = this.rootStore

    const booking = findItemById(bookingId)

    if (!booking) return

    const { data: { _id, totalPrice } } = booking

    this.showModal({
      situation: 'postpurchase',
      visible: true,
      urlType: 'referreroffer',
      extraProps: {
        order_number: _id,
        order_total: penceToPounds(totalPrice),
        order_currency: 'GBP',
      },
    })
  }

  showCheckoutModal = (): void => {
    this.showModal({
      situation: 'checkout',
      urlType: 'refereefind',
      visible: true,
    })
  }

  showReferralAccountModal = (): void => {
    this.showModal({
      situation: 'account',
      urlType: 'referreroffer',
      visible: true,
    })
  }

  showRefferedByAFriendModal = (): void => {
    this.showModal({
      situation: 'account',
      urlType: 'refereefind',
      visible: true,
    })
  }

  // Show mention me dialog
  @action.bound showModal(settings: $MentionMeSettings): void {
    this.mentionMeSettings = {
      visible: false,
    }

    // Clean up
    window.MentionMeFiredTags = {}

    // Wait to ensure tags are cleaned up
    setTimeout(() => {
      this.mentionMeSettings = settings
    }, 50)

    // Setup iframe height to modal height
    let i = 0
    const setIframeHeightInterval = setInterval(() => {
      const wrapper = document.querySelector('#mmWrapper')
      i++

      if (wrapper) {
        const { height } = wrapper.getBoundingClientRect()
        const iframe = document.querySelector('#mmWrapper iframe')

        // @ts-ignore
        if (iframe) iframe.height = `${height}px`

        clearInterval(setIframeHeightInterval)
      }

      // Try max 5 times
      if (i > 5) {
        clearInterval(setIframeHeightInterval)
      }
    }, 1000)

    analytics.track('Show Mention Me Modal', settings)
  }

  @action.bound hideModal(): void {
    this.mentionMeSettings.visible = false
    analytics.track('Hide Mention Me Modal')
  }

  @computed get mentionMeScriptUrl(): string | undefined {
    const { _id, emailAddress, firstName, lastName } = this.rootStore.userStore.data
    const { visible, situation, urlType } = this.mentionMeSettings

    if (!visible) return ''

    const url = `${process.env.MENTION_ME_URL}${urlType}/${process.env.MENTION_ME_ID}`
    const params = `?situation=${situation}&customer_id=${_id}&email=${encodeURIComponent(emailAddress)}&firstname=${encodeURIComponent(firstName)}&surname=${encodeURIComponent(lastName)}&locale=en_GB&implementation=embed`

    return `${url}${params}`
  }

  mentionMeOrderTrackingUrl = (bookingId: string) => {
    const { bookingStore: { findItemById } } = this.rootStore

    const booking = findItemById(bookingId)

    if (!booking) return ''

    const { _id, totalPrice, promoCode } = booking.data
    const { _id: customerId, emailAddress } = this.rootStore.userStore.data
    const currency = 'GBP'

    const url = `${process.env.MENTION_ME_URL}order/${process.env.MENTION_ME_ID}`
    const params = `?customer_id=${customerId}&email=${encodeURIComponent(emailAddress)}&order_number=${encodeURIComponent(_id)}&order_total=${encodeURIComponent(totalPrice / 100)}&order_currency=${encodeURIComponent(currency)}`
    const promoCodeInfo = promoCode && promoCode.promoCode ? `&coupon_code=${encodeURIComponent(promoCode.promoCode)}` : ''
    return `${url}${params}${promoCodeInfo}`
  }
}
