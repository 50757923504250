export default function addKeyPrefix(obj: Record<string, any>, prefix: string, keys?: Array<string>) {
  const ret: Record<string, any> = {}

  const objectKeys = keys || Object.keys(obj)

  objectKeys.forEach(key => {
    const newKey = `${prefix}_${key}`
    ret[newKey] = obj[key]
  })

  return ret
}
