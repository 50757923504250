import { observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import { minutesToMs } from '../../lib/utils'

export interface $UserAddress {
  _id?: string
  userId?: string
  setDefaultAt: Date
  label: string
  firstLine: string
  secondLine: string
  city: string
  county: string
  postalCode: string
  parkingInformationId?: string
}

export const defaultAddressData = {
  _id: '',
  userId: '',
  setDefaultAt: new Date(),
  label: '',
  firstLine: '',
  secondLine: '',
  city: '',
  county: '',
  postalCode: '',
}

export type $UserAddressModel = UserAddressModel

export default class UserAddressModel extends ModelStore {
  @observable data: $UserAddress = {
    ...defaultAddressData,
  }

  saveMethodName = 'addOrUpdateAddress'
  cacheLength = minutesToMs(15)

  saveArgs = (): any => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parkingInformationId, ...address } = { ...this.data }

    if (address._id === '') address._id = undefined

    return {
      address,
    }
  }

  removeMethod_api = async (): Promise<void | null> => {
    try {
      await this.rootStore.communication.requester.removeAddress({ addressId: this.data._id })
    } catch (e) {
      return null
    }
  }

  setAsDefault = async () => {
    this.set({ setDefaultAt: new Date() })
    await this.save_api()
  }
}
