import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'
import { $UserAddress } from '../stores/UserAddressStore/UserAddressModel'
import { $User } from '../stores/UserStore/UserStore'

export const groupBy = function (items: Array<any>, key: string, valueMap?: Array<any>) {
  return (valueMap || items).reduce(function (rv, x, i) {
    (rv[x[key]] = rv[x[key]] || []).push(items[i])
    return rv
  }, {})
}

export const createSectionListGroups = function (items: Array<any>, key: string, valueMap?: Array<any>) {
  const groups = groupBy(items, key, valueMap)

  return Object.keys(groups).map((title: string) => {
    return {
      title,
      data: groups[title],
    }
  })
}

// Get abbreviated name for a user
export const abbreviatedUserName = (user?: Partial<$User> | $Professional) => {
  if (!user || Object.keys(user).length === 0) return ''
  return `${user.firstName} ${(user.lastName || '')[0]}.`
}

// Get initial from a user first name
export const initialUserName = (user: Partial<$User> | $Professional | null | undefined) => {
  if (!user || Object.keys(user).length === 0) return ''
  return (user.firstName || '')[0]
}

// Get full name for a user
export const fullUserName = (user: Partial<$User> | $Professional | null | undefined) => {
  if (!user || Object.keys(user).length === 0) return ''
  return `${user.firstName} ${user.lastName}`
}

// Get user's first naame
export const userFirstName = (user: Partial<$User> | $Professional | null | undefined) => {
  if (!user || Object.keys(user).length === 0) return ''
  return `${user.firstName}`
}

// Gets current timezone for example 'Europe/London'
export function getBrowserTimezone() {
  return Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Europe/London'
}

export const arrayToString = (arr: Array<string>) => {
  if (arr.length === 0) return ''
  if (arr.length === 1) return arr[0]

  const last = arr.pop()
  const result = arr.join(', ') + ' and ' + last
  return result
}

// Format display of addresss
export const formatAddressAndPostcode = (address: Partial<$UserAddress>) => {
  const { firstLine, secondLine, postalCode } = address

  const part1 = firstLine && firstLine.length > 0 ? `${firstLine} - ` : ''
  const part2 = secondLine && secondLine.length > 0 ? `${secondLine}` : ''
  const part3 = postalCode && postalCode.length > 0 ? `, ${postalCode}` : ''

  return `${part1}${part2}${part3}`
}

export const validatePhoneNumber = (phoneNumber: string, countryCode: string) => {
  // Remove spaces non numeric digits and remove 44 or 0 from start of number
  let cleanedNumber = phoneNumber.replace(/ /g, '').replace(/\D/g, '')
  if (cleanedNumber.slice(0, 2) === '44') cleanedNumber = cleanedNumber.substr(2, phoneNumber.length)
  if (cleanedNumber[0] === '0') cleanedNumber = cleanedNumber.substr(1, cleanedNumber.length - 1)

  // Parse phone number
  const parsedNumber = `${countryCode}${cleanedNumber}`
  const numberIsValid = parsedNumber.length > 8 && parsedNumber[0] === '+'
  return numberIsValid ? parsedNumber : ''
}

// Check if an array includes every item of a target array
export const includesArray = (arr: Array<string | number>, target: Array<string | number>) => {
  return target.every(v => arr.includes(v))
}

// Check if array includes on item of another array
export const includesOne = (arr: Array<string | number>, target: Array<string | number>) => {
  const targetSet = new Set(target)
  return arr.some(el => targetSet.has(el))
}

// Replaces special characters with a -
export const urlSafeString = (str: string) => {
  return str.replace(/[\. ,:-]+/g, '-').toLowerCase()
}

// Convert minutes to ms
export const minutesToMs = (minutes: number) => {
  return minutes * 15 * 1000
}