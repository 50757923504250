import { Stripe, StripeError } from '@stripe/stripe-js'
import analytics from '../stores/analytics'
import { $RootStore } from '../stores/RootStore'

export default class StripeIntentHandler {
  rootStore: $RootStore
  stripe: Stripe | null = null

  constructor(rootStore: $RootStore) {
    this.rootStore = rootStore
  }

  setStripe(stripe: Stripe | null) {
    this.stripe = stripe
  }

  confirmCardPayment = async (clientSecret: string, paymentMethodId: string) => {
    const { s } = this.rootStore.i18n
    if (!this.stripe) {
      return { error: { message: s('PAYMENT_ERROR_BODY') } }
    }

    try {
      const historLengthBefore = window.history.length

      const result = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })

      const historLengthDiff = window.history.length - historLengthBefore
      // Hack for Stripe 3DS iframe adding to history
      if (historLengthDiff > 0) {
        window.history.go(-historLengthDiff)
      }

      analytics.track('3DS Confirm Card', {
        result,
        paymentMethodId,
      })

      if (!result) {
        return { error: { message: s('PAYMENT_ERROR_BODY') } }
      } else if (result.error) {
        return { error: result.error }
      } else if (!result.paymentIntent.id) {
        return { error: { message: s('PAYMENT_ERROR_BODY') } }
      }

      return { paymentIntentId: result.paymentIntent.id }
    } catch (e) {
      return { error: e as StripeError }
    }
  }
}