import { Moment } from 'moment-timezone'
import { TreatmentTypes } from '../../shared-lib/commonStrings'
import { currencyFormat } from '../../shared-lib/currency'
import { $PromoLimitsText, $PromoNotUseableReason } from '../viewModels/VouchersViewModel'

export const treatmentTypesFromPromoCode = (limits: Maybe<Partial<PromoLimits>> | undefined) => {
  const { excludedTreatmentTypes, includedTreatmentTypes } = limits ?? {}

  let treatments: Array<string> = []
  let isExcluded = true
  let allowedPreview = ''

  if ((excludedTreatmentTypes && excludedTreatmentTypes.length > 0) ||
    (includedTreatmentTypes && includedTreatmentTypes.length > 0)) {

    const includedTypes = includedTreatmentTypes && includedTreatmentTypes.length > 0 ?
      (includedTreatmentTypes) as Array<string> :
      TreatmentTypes

    const excludedTypes = includedTreatmentTypes && includedTreatmentTypes.length > 0 ?
      TreatmentTypes.filter(type => !includedTreatmentTypes.includes(type)) :
      (excludedTreatmentTypes ?? []) as Array<string>

    const allIncluded = includedTypes.filter(type => !excludedTypes.includes(type))

    if (allIncluded.length <= excludedTypes.length || excludedTypes.length === 0) {
      treatments = allIncluded
      isExcluded = false
    } else {
      treatments = excludedTypes
    }

    if (allIncluded.length === 1) {
      allowedPreview = `${allIncluded[0]}.`
    }

    if (allIncluded.length === 2) {
      allowedPreview = `${allIncluded[0]} or ${allIncluded[1]}.`
    }

    if (allIncluded.length >= 3) {
      allowedPreview = `${allIncluded[0]} or ${allIncluded[1]} and more!`
    }
  }

  return { treatments, isExcluded, allowedPreview }
}

export const treatmentsFromPromoCode = (limits: Maybe<Partial<PromoLimits>>) => {
  const { excludedTreatments, includedTreatments } = limits ?? {}

  let treatments: Array<string> = []
  let isExcluded = true

  if (includedTreatments && includedTreatments.length > 0) {
    treatments = includedTreatments.map(treatment => treatment && treatment.name) as Array<string>
    isExcluded = false
  } else if (excludedTreatments && excludedTreatments.length > 0) {
    treatments = excludedTreatments.map(treatment => treatment && treatment!.name) as Array<string>
  }

  return { treatments, isExcluded }
}

export const promoCodeDiscountText = (promoCodeData: UserPromoCode) => {
  const { limits, discountType, value } = promoCodeData

  const discount = discountType === 'percentage' ? `${value}%` : `${currencyFormat(value)}`

  const treatmentLimits = treatmentsFromPromoCode(limits)
  const treatmentTypeLimits = treatmentTypesFromPromoCode(limits)

  const discountLimitTexts: Array<$PromoLimitsText> = []

  if (treatmentTypeLimits.treatments.length > 0 && treatmentLimits.treatments.length > 0) {

    // Both excluded
    if (treatmentTypeLimits.isExcluded && treatmentLimits.isExcluded) {
      discountLimitTexts.push({
        text: 'PROMO_DISCOUNT_EXCLUDING_BOTH',
        args: [treatmentTypeLimits.treatments.join(', '), treatmentLimits.treatments.join(', ')],
      })
    } else if (!treatmentLimits.isExcluded) {
      // Treatment included only show treatment
      discountLimitTexts.push({
        text: 'PROMO_DISCOUNT_INCLUDING_SIMPLE',
        args: [treatmentLimits.treatments.join(', ')],
      })
    } else {
      // Included types but treatment excluded
      discountLimitTexts.push({
        text: 'PROMO_DISCOUNT_INCLUDING_TYPES_EXCLUDING_TREATMENT',
        args: [treatmentTypeLimits.treatments.join(', '), treatmentLimits.treatments.join(', ')],
      })
    }

  } else {
    //There is only one limit type
    if (treatmentTypeLimits.treatments.length > 0) {
      const { treatments, isExcluded } = treatmentTypeLimits

      discountLimitTexts.push({
        text: isExcluded ? 'PROMO_DISCOUNT_EXCLUDING_SIMPLE' : 'PROMO_DISCOUNT_INCLUDING_SIMPLE',
        args: [treatments.join(', ')],
      })
    } else if (treatmentLimits.treatments.length > 0) {
      const { treatments, isExcluded } = treatmentLimits

      discountLimitTexts.push({
        text: isExcluded ? 'PROMO_DISCOUNT_EXCLUDING_SIMPLE' : 'PROMO_DISCOUNT_INCLUDING_SIMPLE',
        args: [treatments.join(', ')],
      })
    }
  }

  //There are no limits
  if (discountLimitTexts.length === 0) {
    discountLimitTexts.push({
      text: 'PROMO_DISCOUNT_NO_LIMIT',
      args: [discount],
    })
  }

  return discountLimitTexts
}

export const userPromoCodeActiveFormat = (promoCodeData: UserPromoCode) => {
  const { promoCode, expiryDate, discountType, value, redeemAction, _id, featured } = promoCodeData

  const isFromPro = ['crossSellReward', 'referralReward'].includes(redeemAction?.action ?? '')
  const proName = redeemAction?.givenByUser?.firstName ?? 'one of our professionals'

  const discountValueText = {
    text: 'PROMO_DISCOUNT_VALUE',
    args: [discountType === 'percentage' ? `${value}%` : `${currencyFormat(value)}`],
  }

  const discountLimitText = promoCodeDiscountText(promoCodeData)

  return {
    _id,
    promoCode,
    expiryDate,
    discountValueText,
    discountLimitText,
    isFromPro,
    proName,
    featured: !!featured,
  }
}

export const textFromReason = (reason: $PromoNotUseableReason) => {
  let text = reason.reason || ''

  if (reason.value) {
    if (reason.value.treatments) {
      text = `${text}:\n${reason.value.treatments}`
    } else if (reason.value.minBasketCount) {
      text = `${text}: ${reason.value.minBasketCount}`
    } else if (reason.value.minBasketValue) {
      text = `${text}: ${reason.value.minBasketValue}`
    } else if (reason.value.treatmentTypes) {
      text = `${text}: ${reason.value.treatmentTypes}`
    }
  }

  return text
}

export const dateFromExpiry = (expiryDate: Moment, now: Moment, pluralise: (string: string, count?: number) => any) => {
  const hours = expiryDate.diff(now, 'hours')

  let expireDate = expiryDate.format('DD/MM/YY')
  if (hours < 72) expireDate = `in ${hours} ${pluralise('hour', hours)}`
  if (hours === 0) expireDate = `in ${expiryDate.diff(now, 'minutes')} mins`

  return expireDate
}