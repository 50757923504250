import { observer } from 'mobx-react'
import { observable, computed } from 'mobx'
import React, { Component, ComponentPropsWithRef, MouseEventHandler } from 'react'

import { Box, IconButton, withStyles, Container, Typography, WithStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { debounce } from '../shared-lib/utils'
import { colors } from '../theme/'
import { styleCreate } from '../lib/styleCreate'

const styles = styleCreate(theme => ({
  button: {
    position: 'relative',
    background: colors.BG100,
    boxShadow: `${colors.BOLD_SHADOW} 0px 4px 15px`,
    zIndex: 11,
    height: theme.spacing(6),
    width: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonLeft: {
    left: theme.spacing(2),
  },
  buttonRight: {
    right: theme.spacing(2),
  },
  container: {
    alignItems: 'center',
  },
  scrollView: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'scroll',
  },
  buttonsContainer: {
    height: '100%',
    alignItems: 'center',
  },
  removeLeftSpace: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-6),
    },
  },
  removeRightSpace: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(-6),
    },
  },
}))

interface ScrollerProps extends ComponentPropsWithRef<any>, WithStyles<typeof styles> {
  initialIndex?: number
  initialScrollOffset?: number
  showArrows?: boolean
  className?: string
  classNameInner?: string
  scrollViewClassName?: string
  isDraggable?: boolean
  canOverscroll?: boolean
  children?: React.ReactNode
  itemWidth: number
  scrollAmountOnButtonPress?: number
  aria_details?: string
  items: number
}

@observer
export class GenericHorizontalScroller extends Component<ScrollerProps> {
  @observable extraSpacingWidth = 1
  @observable scrollPosition = 0
  @observable scrollWidth = 0
  doGoToItemOnLoad = true
  scrollView: HTMLDivElement | null = null
  containerView: HTMLDivElement | null = null
  hasMoved = false

  debouncedFn = debounce(() => {
    this.scrollPosition = this.scrollView?.scrollLeft ?? 0
  }, 100)

  constructor(props: ScrollerProps) {
    super(props)
  }

  componentDidMount() {
    const { scrollView } = this
    if (scrollView) {
      scrollView.addEventListener('scroll', this.debouncedFn)

      if (this.props.isDraggable) {
        let isDown = false
        let startX = 0
        let scrollLeft = 0

        scrollView.addEventListener('mousedown', event => {
          isDown = true
          startX = event.pageX - scrollView.offsetLeft
          scrollLeft = scrollView.scrollLeft
        })

        scrollView.addEventListener('mouseleave', () => {
          isDown = false
          this.hasMoved = false
        })

        scrollView.addEventListener('mouseup', () => {
          isDown = false
        })

        scrollView.addEventListener('mousemove', event => {
          if (!isDown) return
          event.preventDefault()
          const x = event.pageX - scrollView.offsetLeft
          const walk = x - startX
          scrollView.scrollLeft = this.safeScrollPosition(scrollLeft - walk)
          if (Math.abs(walk) > 5) {
            this.hasMoved = true
          }
        })
      }
    }
  }

  componentDidUpdate() {
    if (this.doGoToItemOnLoad) {
      if (this.scrollView) {
        const { initialIndex, initialScrollOffset = 0 } = this.props
        initialIndex !== undefined && this.scrollToIndex(initialIndex, initialScrollOffset, false)
      }
      this.doGoToItemOnLoad = false
    }
  }

  clickOnScroll: MouseEventHandler<HTMLElement> = event => {
    if (this.hasMoved) {
      event.stopPropagation()
      this.hasMoved = false
    }
  }

  scrollDirection = (direction: number) => {
    if (this.scrollView) {
      const { itemWidth, scrollAmountOnButtonPress = 1 } = this.props
      const amountToScroll = this.scrollPosition + (direction * scrollAmountOnButtonPress * itemWidth)
      this.scrollPosition = this.safeScrollPosition(amountToScroll)

      this.scrollView.scroll({
        top: 0,
        left: this.scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  scrollToIndex = (index: number, offset: number, animated = true) => {
    if (index < this.props.items) {
      const { itemWidth } = this.props
      this.scrollToOffset(itemWidth * index + offset, animated)
    }
  }

  scrollToOffset = (offset: number, animated = true) => {
    if (this.scrollView) {
      this.scrollView.scrollTo({
        left: this.safeScrollPosition(offset),
        behavior: animated ? 'smooth' : 'auto',
      })
    }
  }

  refCallbackScrollView = (element: HTMLDivElement) => {
    if (element) {
      this.scrollView = element
      const { itemWidth } = this.props
      const { width } = element.getBoundingClientRect()
      const adjustedSpace = width - itemWidth - 60
      this.scrollWidth = width
      this.extraSpacingWidth = this.props.canOverscroll ? adjustedSpace : 0
    }
  }

  refCallbackInsideDiv = (element: HTMLDivElement) => {
    if (element) this.containerView = element
  }

  safeScrollPosition = (x: number) => {
    if (x < 0) return 0
    if (x > this.props.items * this.props.itemWidth) return this.props.items * this.props.itemWidth
    return x
  }

  @computed get isLeftHidden() {
    const { itemWidth } = this.props
    return this.scrollPosition <= itemWidth / 2
  }

  @computed get isRightHidden() {
    return this.scrollPosition >= (this.props.items - 1.5) * this.props.itemWidth
  }

  @computed get containerMaxWidth() {
    const { itemWidth } = this.props
    return itemWidth * this.props.items - this.extraSpacingWidth - itemWidth * 2
  }

  @computed get containerMaxScroll() {
    const { itemWidth } = this.props
    return this.props.canOverscroll ? this.containerMaxWidth
      : this.containerMaxWidth - (this.scrollWidth - itemWidth * 2)
  }

  render() {
    const { classes, showArrows = false, className = '', classNameInner = '', scrollViewClassName = '', children, aria_details = '' } = this.props
    const {
      extraSpacingWidth,
      isRightHidden,
      refCallbackScrollView,
      refCallbackInsideDiv,
      scrollDirection,
      clickOnScroll,
      isLeftHidden,
    } = this

    const showLeftArrow = showArrows && !isLeftHidden
    const showRightArrow = showArrows && !isRightHidden

    const scrollViewClasses = `hideScrollbars ${scrollViewClassName} ${classes.scrollView}
    ${showLeftArrow && classes.removeLeftSpace} ${showRightArrow && classes.removeRightSpace}`

    return (
      <Box display='flex' className={`${classes.container} ${className}`}>
        {showLeftArrow &&
          <Box display='flex' className={classes.buttonsContainer}>
            <IconButton
              onClick={() => scrollDirection(-1)}
              aria-label={`navigate_before${aria_details}`}
              className={`${classes.button} ${classes.buttonLeft}`}
            >
              <NavigateBefore fontSize='inherit' />
            </IconButton>
          </Box>
        }
        <Box className={scrollViewClasses} {...{ ref: refCallbackScrollView }} onClickCapture={clickOnScroll}>
          <Box display='flex' {...{ ref: refCallbackInsideDiv }} className={classNameInner}>
            {children}
          </Box>
          <Container>
            <Typography component='div' style={{ width: extraSpacingWidth, height: 1 }} />
          </Container>
        </Box>
        {showRightArrow &&
          <Box display='flex' className={classes.buttonsContainer}>
            <IconButton
              onClick={() => scrollDirection(1)}
              aria-label={`navigate_next${aria_details}`}
              className={`${classes.button} ${classes.buttonRight}`}
            >
              <NavigateNext fontSize='inherit' />
            </IconButton>
          </Box>
        }
      </Box>
    )
  }
}

export default withStyles(styles)(GenericHorizontalScroller)
