import { Box, Dialog, Typography, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Avatar, RectButton, TextButton } from '.'
import { styleCreate } from '../lib/styleCreate'
import { filteredProCategories } from '../shared-consumer/helpers/professionalBookingHelper'
import { abbreviatedUserName } from '../shared-consumer/lib/utils'
import useViewModel from '../shared-lib/hooks/useViewModel'
import { useI18n } from '../shared-lib/i18n/i18n'
import analytics from '../src/stores/analytics'
import RootStore from '../src/stores/RootStore'

class ViewModel {
  store: RootStore

  constructor(store: RootStore) {
    this.store = store
  }

  hideModal = () => {
    const { uiStore } = this.store

    uiStore.hideRebookPopup()
  }

  rebook = async () => {
    const {
      newBookingHandler: {
        rebook,
      },
      bookingStore,
      reviewBookingHandler: {
        bookingId,
        clearData,
      },
    } = this.store
    const booking = bookingStore.findItemById(bookingId)

    if (!booking) {
      this.hideModal()
      return
    }

    this.hideModal()

    analytics.track('Rebooked Pro after review', {
      ...booking.data,
    })

    rebook(booking, 'rebook_review', this.navigateAfterRebooking)
    clearData()
  }

  navigateAfterRebooking = async (professionalId: string) => {
    const {
      modalController,
      router,
      professionalStore,
      categoryStore: {
        items,
      },
      reviewBookingHandler: {
        professional,
      },
    } = this.store

    let pro = professionalStore.findItemById(professional?._id)
    if (!pro) {
      pro = await professionalStore.fetchItem_api(professionalId)
    }
    const proCategories = filteredProCategories(items, pro.data)

    await modalController.closeAll()

    if (proCategories[0]) {
      const bookWithUrl = proCategories[0].urlSafeName
      router.push('/categories/[id]', `/categories/${bookWithUrl}`)
    }
  }
}

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  professional: {
    margin: theme.spacing(2),
  },
  explainer: {
    marginBottom: theme.spacing(4),
  },
  action: {
    marginBottom: theme.spacing(2),
  },
}))

const RebookPopup = (props: Props) => {
  const { store, classes } = props
  const {
    uiStore: {
      rebookPopupVisible,
    },
    reviewBookingHandler: {
      professional,
    },
  } = store!
  const viewModel = useViewModel(ViewModel, props.store!)
  const { hideModal, rebook } = viewModel
  const { s } = useI18n()

  return (
    <Dialog
      open={rebookPopupVisible}
      onClose={hideModal}
      maxWidth={'xs'}
      fullWidth
    >
      <Box display='flex' flexDirection='column' p={4} alignItems='center'>
        <Avatar user={professional} size={100} />

        <Typography variant='body2' className={classes.professional}>
          {s('Rebook $0', abbreviatedUserName(professional))}
        </Typography>

        <Typography variant='body1' align='center' className={classes.explainer}>
          {s('REBOOK_EXPLAINER')}
        </Typography>

        <RectButton onClick={rebook} fullWidth className={classes.action}>
          {s('Rebook')}
        </RectButton>

        <TextButton onClick={hideModal}>
          {s('Not now')}
        </TextButton>
      </Box>
    </Dialog>
  )
}

export default withStyles(styles)(inject('store')(observer(RebookPopup)))