import { action, observable } from 'mobx'
import momentTz from 'moment-timezone'
import { Moment } from 'moment-timezone'

import RootStore from '../../../src/stores/RootStore'
import { $BookingModel } from '../BookingStore/BookingModel'
import { $Professional, $ProfessionalProfile } from '../ProfessionalStore/ProfessionalModel'
import { $User } from '../UserStore/UserStore'
import { nullCleanObject } from '../../../shared-lib/utils'
import analytics from '../../../src/stores/analytics'

export interface $ProfessionalDiary {
  unavailableSlots: Array<number>
  professionalAvailability: {
    times: Array<number>
    status: number
  }
}

export interface $ProfileMatch {
  userId: string
  user: $User
  favouriteCount: number
  averageReview: number
  reviewCount: number
  featured: boolean
}

export interface $MatchResult {
  _id: string
  status?: string
  errors?: Array<string>
  professionalProfile: $ProfileMatch
  startTimes?: Array<Date>
  isFavourite: boolean
  selected?: boolean
  availability?: any
}

export interface $ProfessionalMatchResult {
  professional: $Professional
  day: string
  isFavourite?: boolean
  availableTimeSlots: Array<number>
  bookedTimesSlots: Array<number>
  availability: {
    status: number
  }
  sortScore?: number
  bookingId?: string
}

export interface $SuggestedProfessional extends Omit<$ProfessionalProfile, 'bio' | 'picture' | 'postcodes'> {
  featured: boolean
  user: $Professional
  availability: Array<Pick<ProfessionalAvailability, 'day'>>
}

export interface $EstimatedProfessionalAvailability {
  professionalId: string
  day: string
  availabilityStatus: number
  availableHours: number
}

export default class ProfessionalMatcherStore {
  rootStore: RootStore

  @observable loading = false
  @observable matchingProfessionals: Array<$MatchResult> = []
  @observable suggestedProfessionals: Array<$SuggestedProfessional> = []
  @observable selectedDate: Date = new Date()
  @observable estimatedProfessionalAvailability: Array<$EstimatedProfessionalAvailability> = []
  @observable instantAvailability: Record<string, Array<$ProfessionalMatchResult>> = {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action.bound setSelectedDate(date: Moment): void {
    const { checkoutStore } = this.rootStore

    const { timezone } = checkoutStore.booking.data
    const dateTimezone = momentTz(date.format()).tz(timezone, true).toDate()

    this.selectedDate = dateTimezone
    checkoutStore.booking.set({
      date: dateTimezone,
    })
  }

  getMatchingParams(booking: $BookingModel) {
    const {
      data: { postcode, postcodeId },
      totalDurationComputed,
      treatmentIds,
      optionIds,
    } = booking

    return {
      treatmentIds,
      postcodeId: postcode._id || postcodeId,
      totalDuration: totalDurationComputed,
      optionIds,
    }
  }

  @action.bound checkProfessionalIsAvailable_api = async () => {
    const { communication: { requester }, checkoutStore: { booking } } = this.rootStore
    const matchingParams = this.getMatchingParams(booking) as InstantMatchingParams

    const response = await requester.checkProfessionalAvailability({
      bookingId: booking.data._id,
      professionalId: booking.data.professional?._id || '',
      date: booking.data.date,
      matchingParams,
    })

    if (response.checkProfessionalAvailability !== 'instant') {
      analytics.track('Professional Not Available For Booking', {
        bookingId: booking.data._id,
        professionalId: booking.data.professional?._id,
      })

      return false
    }

    return true
  }

  findInstantProfessionals_api = async (
    days: Array<string>,
    booking: $BookingModel,
    options: {
      clear?: boolean,
      proProfileFilter?: Record<string, any>,
    },
  ) => {
    const { communication: { requester } } = this.rootStore

    // Don't fetch if in the past
    if (days.length === 0) return

    if (options.clear) {
      this.matchingProfessionals = []
      this.instantAvailability = {}
    }

    this.loading = true

    try {
      const matchingParams = this.getMatchingParams(booking)

      const args: Record<string, any> = {
        matchingParams,
        days,
        proProfileFilter: options.proProfileFilter || {},
      }

      if (booking.data.status !== 'DRAFT') {
        args.bookingId = booking.data._id
      }

      const ret = await requester.findInstantProfessionals(args)

      const daysWithProfessionals: Array<{ day: string, professionals: Array<$ProfessionalMatchResult> }>
        = nullCleanObject(ret.findInstantProfessionals) || []

      daysWithProfessionals.forEach(d => {
        if (d && d.day && d.professionals) {
          this.instantAvailability[d.day] = d.professionals.map(day => nullCleanObject(day)).filter(day => day !== null)
        }
      })

      this.loading = false
      return !!ret
    } catch (e) {
      this.loading = false
      return false
    }
  }

  findSuggestedProfessionals_api = async (treatments: Array<string> = []) => {
    const { communication: { requester }, checkoutStore: { booking } } = this.rootStore

    this.suggestedProfessionals = []
    this.loading = true

    const { data: { postcode } } = booking
    const postcodeId = postcode._id

    try {
      const matchingParams = {
        treatments,
        postcode: postcodeId,
      }

      const response = await requester.findSuggestedProfessionals({ matchingParams })
      this.suggestedProfessionals = nullCleanObject(response.findSuggestedProfessionals) || []

      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }

  @action.bound estimatedProfessionalAvailability_api = async (professionalIds: [string], days: Array<string>, booking: $BookingModel) => {
    const { communication: { requester } } = this.rootStore

    this.loading = true
    this.estimatedProfessionalAvailability = []

    try {
      const args = {
        matchingParams: this.getMatchingParams(booking) as InstantMatchingParams,
        professionalIds,
        days,
      }

      const response = await requester.estimatedProfessionalAvailability(args)

      this.estimatedProfessionalAvailability = nullCleanObject(response.estimatedProfessionalAvailability) || []
      this.loading = false
      return true
    } catch (e) {
      this.loading = false
      return false
    }
  }

  @action.bound reset = () => {
    this.matchingProfessionals = []
    this.suggestedProfessionals = []
  }

  matchResultFromProfessionalId = (_id: string) => {
    return this.matchingProfessionals.find((m: $MatchResult) => m.status === 'instant' && m.professionalProfile.userId === _id)
  }

  suggestedProfessionalFromId = (_id: string) => {
    return this.suggestedProfessionals.find((p: $SuggestedProfessional) => p.userId === _id)
  }
}
