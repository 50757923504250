import { observable } from 'mobx'

interface $MessageData {
  type: 'info' | 'warn' | 'error' | 'success'
  title: string
  message?: string
  onTap?: () => void
}

class DropdownMessageHandler {
  @observable messageData: $MessageData = {
    type: 'success',
    title: '',
    message: '',
  }

  @observable visible = false

  showDropdownMessage = (messageData: $MessageData) => {
    this.messageData = messageData
    this.visible = true
  }

  hideDropdownMessage = () => {
    this.visible = false
  }

  onTap = () => {
    const { onTap } = this.messageData
    onTap && onTap()
  }
}

export default DropdownMessageHandler