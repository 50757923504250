
interface OffPeakConfig {
  [key: string]: Array<Array<number>>
}

export const allowedOffPeakDiscountTimes = (
  dayOfWeek: number,
  itemTypes: Set<string>,
  remoteConfig: OffPeakConfig = {},
) => {

  if (dayOfWeek === 0 || dayOfWeek === 6 || dayOfWeek === 5) return []

  for (const [key, value] of Object.entries(remoteConfig)) {
    if (itemTypes.has(key)) {
      return value
    }
  }

  return []
}

export const calculateServiceCharge = (
  percentage: number, // 0.01 = 1%
  serviceFeeCap: number, // 400 = £4.00
  bookingTotal: number, // In pence
) => {
  // Work out price and round to nearest 0.25p
  const pricePounds = Math.ceil((bookingTotal / 100) * percentage * 4) / 4
  return Math.min(serviceFeeCap, Math.floor(pricePounds * 100))
}