import { observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'

export interface $Review {
  _id: string
  professionalId: string
  user: {
    firstName: string
    lastName: string
    _id: string
  }
  comment: string
  rating: number
  createdAt: Date
  pinned: boolean
}

export type $ReviewModel = ReviewModel

export const DefaultReviewData = {
  _id: '',
  professionalId: '',
  user: {
    firstName: '',
    lastName: '',
    _id: '',
  },
  comment: '',
  rating: 5,
  createdAt: new Date(),
  pinned: false,
}

export default class ReviewModel extends ModelStore {
  @observable data: $Review = DefaultReviewData
  fetchMethodName = 'review'
}
