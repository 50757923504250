import gql from 'graphql-tag'
import { CHAT_FIELDS } from './fragments'

class Listeners {
  bookingUpdated = gql`
    subscription($userId: String) {
      bookingUpdated(userId: $userId) {
        booking {
          _id
          status
          type
          userId
          requestedProfessionals {
            _id
            firstName
            lastName
            profileUrl
          }
          requestedProfessionalResponses {
            professionalId
            status
            respondedAt
            availabilityAdded
          }
          professional {
            _id
            firstName
            lastName
            profileUrl
          }
        }
      }
    }
  `
  chatUpdated = gql`
   ${CHAT_FIELDS}
    subscription($userId: String!) {
      chatUpdated(userId: $userId) {
        chat {
          ...ChatFields
        }
        clientUnreads
      }
    }
  `
}

const listeners = new Listeners()
export default listeners
