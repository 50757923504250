import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Dialog, DialogProps, IconButton, isWidthDown, Slide, WithStyles, withStyles, withWidth } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { LoadingOverlay } from './'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { resetWindowScrollY, recordWindowScrollY } from '../lib/modalFix'
import { TransitionProps } from '@material-ui/core/transitions'
import PageTitle from './PageTitle'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  open: boolean
  loading: boolean
  dialogProps?: Partial<DialogProps>
  width?: Breakpoint
  minHeight?: any
  close?: () => void
  ariaLabel?: string
  closeOnBackdropClick?: boolean
  hideCloseIcon?: boolean
  transitionProps?: Partial<TransitionProps>
  title?: string
}

const styles = styleCreate(theme => ({
  dialog: {
    [theme.breakpoints.between('xs', 'sm')]: {
      borderRadius: 0,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      borderRadius: 12,
      height: '90vh',
    },
    overflowX: 'hidden',
  },
  container: {
    '& .MuiDialog-scrollPaper': {
      willChange: 'transform',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '9px',
    right: theme.spacing(2),
    zIndex: 100,
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
}))

class SlideUp extends Component<TransitionProps & {
  children?: React.ReactElement<any, any>
}> {
  render() {
    return <Slide direction='up' {...this.props}>{this.props.children}</Slide>
  }
}

@observer
class ResponsiveModal extends Component<Props> {

  onClose = (_event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    const { close, closeOnBackdropClick } = this.props
    if (closeOnBackdropClick && reason === 'backdropClick') {
      close()
    }
  }

  onEnter = (node: HTMLElement, isAppearing: boolean) => {
    recordWindowScrollY()
    if (this.props.transitionProps && this.props.transitionProps.onEnter) {
      this.props.transitionProps?.onEnter(node, isAppearing)
    }
  }

  onExit = (node: HTMLElement) => {
    resetWindowScrollY()
    if (this.props.transitionProps && this.props.transitionProps.onExit) {
      this.props.transitionProps?.onExit(node)
    }
  }

  render() {
    const { open, classes, width, loading, dialogProps, children, close, minHeight, ariaLabel = '', hideCloseIcon = false, transitionProps, title } = this.props
    const isMobile = isWidthDown('xs', width)

    return <Dialog
      open={open}
      onClose={this.onClose}
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={SlideUp}
      transitionDuration={300}
      PaperProps={{
        className: classes.dialog,
        style: { minHeight },
      }}
      className={classes.container}
      aria-label={open ? ariaLabel : ''}
      TransitionProps={{
        ...transitionProps,
        onEnter: this.onEnter,
        onExit: this.onExit,
      }}
      disableEnforceFocus
      {...dialogProps}
    >
      <PageTitle title={title} />
      {children}

      {loading && <LoadingOverlay />}

      {close && !hideCloseIcon && <IconButton
        className={classes.closeButton}
        onClick={close}
        aria-label='modal_close_button'
      >
        <CloseRounded />
      </IconButton>}
    </Dialog>
  }
}

export default withWidth()(withStyles(styles)(ResponsiveModal))
