export default {
  // GENERIC
  NO_INTERNET_ERROR: 'Please check you are connected to wifi or mobile internet and try again.',
  MALFORMED_RESPONSE_ERROR: 'The response from the server was malformed. Please try again. If this problem persists contact support.',
  SERVER_CONNECTION_ERROR: 'There was a problem with the connection to Secret Spa. Please try again. If this problem persists contact support.',
  GRAPHQL_VALIDATION_FAILED: 'There was a server error, please try again. If this problem persists contact customer support.',

  // AUTH
  AUTH_VALIDATE_IDENT_FAILED: 'Sorry, we couldn\'t log you in. Please try logging in again. If this problem persists contact support.',
  AUTH_FAILED_TO_SET_LAST_LOGIN: 'Sorry, we couldn\'t log you in. Please try logging in again. If this problem persists contact support.',
  AUTH_LINK_TOKEN_EXPIRED: 'Sorry, that link didn\'t work. Please, request a new one.',
  AUTH_LINK_TOKEN_NOT_FOUND: 'Sorry, that link didn\'t work. Please, request a new one.',
  AUTH_NO_USER_RECORD_FOUND: 'That user does not exist within Secret Spa. If you think we\'re wrong, feel free to contact support.',
  AUTH_INVALID_AUTH_TOKEN: 'Sorry, we couldn\'t log you in. Please try logging in again. If this problem persists contact support.',
  AUTH_NO_PASSWORD_MATCHES: 'Wrong email or password. Please check your credentials and try again.',
  AUTH_LOGIN_ATTEMPT_WITH_OLD_PASSWORD: 'Wrong password. It seems you\'re trying to login with an old password.',
  AUTH_PASSWORD_IS_NOT_VALID: 'Wrong email or password. Please check your credentials and try again.',
  AUTH_USER_HAS_NO_PASSWORD_SET: 'You didn\'t set a password for your account. Please login with magic link.',
  AUTH_FAILED_TO_CREATE_GUEST_USER: 'Sorry, we couldn\'t log you in as a guest. Please try again. If this problem persists contact support.',
  AUTH_FAILED_TO_CREATE_PASSWORD: 'Sorry, the creation of your password failed. Please, try with a different one. If this problem persists contact support.',
  AUTH_NO_EMAIL_PROVIDED_TO_SEND_TOKEN: 'Sorry, we couldn\'t send you an email. Please, check your email address and try again. If this problem persists contact support.',
  AUTH_REVOKED_HEADER: 'Sorry, we couldn\'t log you in. Please try logging in again. If this problem persists contact support.',
  AUTH_HEADER_ERROR: 'Sorry, we couldn\'t log you in. Please try logging in again. If this problem persists contact support.',
  AUTH_FAILED_TO_CREATE_PROFESSIONAL_PROFILE: 'Sorry, we couldn\'t create your professional profile. Please try again. If this problem persists contact support.',
  USER_IS_BLOCKED: 'This account has been blocked. Please contact the support for further information.',
  SMS_VERIFICATION_FAILED: 'This code is invalid. Check your code and try again.',

  // BOOKING
  BOOKING_NOT_FOUND: 'That booking does not exist within Secret Spa. If you think we\'re wrong, feel free to contact support.',
  BOOKING_CANNOT_CANCEL_UNCONFIRMED: 'This booking cannot be cancelled as it\'s status is neither Booked nor Accepted.',
  BOOKING_CANNOT_CANCEL_PAST_BOOKING: 'This booking cannot be cancelled as the booking date is in the past.',
  BOOKING_FAILED_TO_UPDATE_DRAFT: 'There was an error saving your booking. Please try again.',
  BOOKING_ERROR_REFUND_BOOKING: 'Something went wrong while refunding your booking. Feel free to contact support if you have any questions about it.',
  BOOKING_FAILED_TO_UPDATE_REVIEW_PROMPTED: 'Something went wrong while updating your booking.',
  BOOKING_IN_THE_PAST: 'Your booking date was in the past. Please select a new date and time for your booking.',

  // ADDRESS
  QUESTIONS_ADDRESS_NOT_FOUND: 'This address was not found within Secret Spa. Please try again.',
  ADDRESS_OUTSIDE_OF_SERVICE_AREA: 'Secret Spa does not operate in this area. Please use another address.',
  PROFILE_ADD_UPDATE_ADDR_FAILED: 'Failed to add or update your address. Please try again. If this problem persists contact support.',
  POSTCODE_NOT_SUPPLIED: 'Failed to check your postcode. Please try again.',

  // CREDIT / GIFTCARD
  GIFTCARD_NOT_FOUND: 'This code was not recognised. Please check you have entered the code correctly or contact the team for assistance.',

  // REFERRAL
  GUESTS_CANNOT_REFER_USERS: 'Guests cannot refer other users. Please, create an account to be able to refer users.',

  // PROMO CODE
  PROMOCODE_NOT_FOUND: 'This code was not recognised. Please check you have entered the code correctly or contact the team for assistance.',
  PROMOCODE_UNAVAILABLE_TO_USER: 'This promo code was not created for you. If you think we\'re wrong, feel free to contact support.',
  PROMOCODE_USAGE_MAXED_OUT: 'You have already used this promocode.',
  PROMOCODE_USER_USAGE_MAXED_OUT: 'You have already used this promocode.',

  // FAVOURITE
  FAVOURITES_FAILED_TO_REMOVE: 'Failed to remove the professional from your favourites. Please try again. If this problem persists contact support.',
  FAVOURITES_FAILED_TO_ADD: 'Failed to add the professional to your favourites. Please try again. If this problem persists contact support.',

  // USER
  USER_DOES_NOT_EXISTS: 'This user does not exists within Secret Spa. If you think we\'re wrong, feel free to contact support.',
  USER_PROFESSIONAL_PROFILE_DOES_NOT_EXISTS: 'This professional profile does not exists within Secret Spa. If you think we\'re wrong, feel free to contact support.',
  INVALID_MISSING_MOBILE_COUNTRYCODE_DATA: 'This phone phone number is invalid. It should contains the country code (ex: +44).',

  // CARD
  CARD_FAILED_TO_ADD: 'Failed to add this card to your cards. Please try again. If this problem persists contact support.',
  CARD_FAILED_TO_REMOVE_NO_USER: 'Failed to remove your card. Please try again. If this problem persists contact support.',
  CARD_FAILED_TO_REMOVE_CARD_DOES_NOT_EXIST: 'Failed to remove your card. Please try again. If this problem persists contact support.',
  CARD_FAILED_TO_FETCH: 'Failed to get your cards from Secret Spa. Please try again. If this problem persists contact support.',
  CARD_FAILED_TO_UPDATE: 'Failed to set your card as default. Please try again. If this problem persists contact support.',

  // PAYMENT / REFUND
  PAYMENT_FAILED: 'Unfortunately, the payment failed. Please pick another card and try again. If this problem persists contact support.',
  PAYMENT_FAILED_INVALID_CARD: 'Unfortunately, the payment failed due to invalid card. Please pick another card and try again.',
  BOOKING_UNKNOWN_AREA: 'Payment failed because your booking location is not recognised. Please try again or contact support.',
  PAYMENT_FAILED_NO_USER: 'Payment failed because we couldn\'t find your account. Please register and try again.',
  PAYMENT_FAILED_NO_STRIPE_USER: 'Payment failed because something is wrong with your user configuration. Please contact support.',
  PAYMENT_FAILED_NO_CARD: 'Payment failed due to an error with your card. Please try another card or contact support.',
  PAYMENT_FAILED_NO_CARD_TOKEN: 'Payment failed due to an error with your card. Please try another card or contact support.',
  PAYMENT_FAILED_CARD_BLOCKED: 'Payment failed because your card was blocked. Please try another card or contact support.',
  PAYMENT_FAILED_STRIPE_ERROR: 'Payment failed on your card. Please try another card or contact support.',
  PAYMENT_FAILED_NO_ORDER_ID: 'Payment failed because something is wrong with your booking. Please contact support.',
  TRANSACTION_INSUFFICIENT_FUNDS: 'Payment failed due to insufficient funds.',
  REFUND_FAILED_NO_USER: 'Refund failed because we couldn\'t find your account. Please contact support.',
  REFUND_FAILED_NO_CREDIT_TRANSACTION: 'Refund failed because no payment has been made for this booking. Please try again. If this problem persists contact support.',
  REFUND_FAILED_CANCEL_HOLD: 'Refund failed. Please try again. If this problem persists contact support.',
  REFUND_FAILED_STRIPE_REFUND: 'Refund failed. Please try again. If this problem persists contact support.',
  REFUND_FAILED_TRANSACTION_STATUS_WRONG: 'Your booking cannot be refunded anymore. If you think we\'re wrong, feel free to contact support.',
  REFUND_FAILED_INCORRECT_AMOUNT: 'Refund failed. Please try again. If this problem persists contact support.',

  // SETTINGS
  SETTINGS_NOT_FOUND: 'Something went wrong while fetching Secret Spa configuration. If this problem persists contact support.',

  // LOYALTY
  LOYALTY_INSUFFICENT_POINTS: 'You don\'t have enough points to redeem a reward yet. If you think we\'re wrong, feel free to contact support.',

  INVALID_POSTCODE: 'Please enter a valid UK postcode and try again.',

  // ALERTS WITH SUPPORT BUTTON
  SUPPORT: [
    // GENERIC
    'MALFORMED_RESPONSE_ERROR',
    'SERVER_CONNECTION_ERROR',

    // AUTH
    'AUTH_VALIDATE_IDENT_FAILED',
    'AUTH_FAILED_TO_SET_LAST_LOGIN',
    'AUTH_NO_USER_RECORD_FOUND',
    'AUTH_INVALID_AUTH_TOKEN',
    'AUTH_FAILED_TO_CREATE_GUEST_USER',
    'AUTH_FAILED_TO_CREATE_PASSWORD',
    'AUTH_NO_EMAIL_PROVIDED_TO_SEND_TOKEN',
    'AUTH_REVOKED_HEADER',
    'AUTH_HEADER_ERROR',
    'AUTH_FAILED_TO_CREATE_PROFESSIONAL_PROFILE',
    'USER_IS_BLOCKED',

    // BOOKING
    'BOOKING_NOT_FOUND',
    'BOOKING_FAILED_TO_UPDATE_DRAFT',
    'BOOKING_ERROR_REFUND_BOOKING',

    // ADDRESS
    'PROFILE_ADD_UPDATE_ADDR_FAILED',

    // CREDIT / GIFTCARD
    'GIFTCARD_NOT_FOUND',

    // PROMOCODE
    'PROMOCODE_NOT_FOUND',
    'PROMOCODE_UNAVAILABLE_TO_USER',

    // FAVOURITE
    'FAVOURITES_FAILED_TO_REMOVE',
    'FAVOURITES_FAILED_TO_ADD',

    // USER
    'USER_DOES_NOT_EXISTS',
    'USER_PROFESSIONAL_PROFILE_DOES_NOT_EXISTS',

    // CARD
    'CARD_FAILED_TO_ADD',
    'CARD_FAILED_TO_REMOVE_NO_USER',
    'CARD_FAILED_TO_REMOVE_CARD_DOES_NOT_EXIST',
    'CARD_FAILED_TO_FETCH',
    'CARD_FAILED_TO_UPDATE',

    // PAYMENT / REFUND
    'PAYMENT_FAILED',
    'BOOKING_UNKNOWN_AREA',
    'PAYMENT_FAILED_NO_USER',
    'PAYMENT_FAILED_NO_STRIPE_USER',
    'PAYMENT_FAILED_NO_CARD',
    'PAYMENT_FAILED_NO_CARD_TOKEN',
    'PAYMENT_FAILED_CARD_BLOCKED',
    'PAYMENT_FAILED_STRIPE_ERROR',
    'PAYMENT_FAILED_NO_ORDER_ID',
    'TRANSACTION_INSUFFICIENT_FUNDS',
    'REFUND_FAILED_NO_USER',
    'REFUND_FAILED_NO_CREDIT_TRANSACTION',
    'REFUND_FAILED_CANCEL_HOLD',
    'REFUND_FAILED_STRIPE_REFUND',
    'REFUND_FAILED_TRANSACTION_STATUS_WRONG',
    'REFUND_FAILED_INCORRECT_AMOUNT',

    // SETTINGS
    'SETTINGS_NOT_FOUND',

    // LOYALTY
    'LOYALTY_INSUFFICENT_POINTS',
  ],
}
