import { action, observable } from 'mobx'

import ModelStore from '../../../shared-lib/stores/ModelStore'
import analytics from '../../../src/stores/analytics'
import { DeleteUserMutation } from '../../graph/generated/client'

export interface $User {
  emailConfirmed?: boolean
  status?: string
  mobileConfirmed?: boolean
  _id: string
  role?: string
  guestKey?: string
  lastLogin?: string
  emailAddress: string
  firstName: string
  lastName: string
  mobile: string
  gender: string
  createdAt?: Date
  referrer: string | undefined | null
  blocked: boolean
  marketingPreferences: {
    push: boolean
    email: boolean
    text: boolean
  }
  userPreferences: {
    favouriteCategories?: Array<string>
  }
  acceptedTermsCA?: boolean
  tags?: Array<string>
  promoCodeIds: Array<string>
  subscriptionDetails: SubscriptionDetails
}

const defaultData: $User = {
  _id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  mobile: '',
  gender: '',
  emailConfirmed: false,
  mobileConfirmed: false,
  acceptedTermsCA: false,
  blocked: false,
  referrer: undefined,
  marketingPreferences: {
    push: false,
    email: false,
    text: false,
  },
  userPreferences: {},
  promoCodeIds: [],
  subscriptionDetails: {
    status: null,
  },
}

export default class UserStore extends ModelStore {
  @observable data: $User = {
    ...defaultData,
  }
  @observable profilePictureUpdate = Date.now()
  fetchMethodName = 'user'

  // Update profile information
  @action.bound async updateProfile_api(data: any): Promise<boolean> {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    try {
      // Create questions object
      const questions = []
      for (const key of Object.keys(data)) {
        if (data[key] !== '') questions.push({ name: key, value: data[key] })
      }

      // Send request
      await requester.answers({ answers: { questions } as QuestionAnswer })

      this.set(data)
      this.syncInStorage()

      this.loading = false

      return true
    } catch (e) {
      this.loading = false
      return false
    }
  }

  trackCampaign_api = async (campaignData: any) => {
    const { communication: { requester } } = this.rootStore

    try {
      await requester.trackCampaign({ campaignData })
      return true
    } catch (e) {
      return false
    }
  }

  updateUserTraits_api = async () => {
    const { communication: { requester }, checkoutStore } = this.rootStore

    let traits = {}

    try {
      const response = await requester.getUserTraits({})
      traits = response.getUserTraits || {}
    } catch (e) {
      traits = {}
    }

    analytics.setUserTraits(traits)

    const { region, code } = checkoutStore.booking.data.postcode
    analytics.identify('CLIENT', this.data, { region, code })
  }

  // Sync store data in storage
  syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('USER')
  }

  // Clear all data
  @action.bound clearData(): void {
    this.data = { ...defaultData }
    this.syncInStorage()
  }

  @action.bound async deleteUser_api(): Promise<DeleteUserMutation['deleteUser']> {
    const { communication: { requester } } = this.rootStore

    try {
      const { deleteUser } = await requester.deleteUser()
      return deleteUser
    } catch (e) {
      return false
    }
  }
}
