import { observable } from 'mobx'
import analytics from '../stores/analytics'
import RootStore from '../stores/RootStore'

class ZendeskHandler {
  rootStore: RootStore
  @observable unread = 0

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  listenToEvents = () => {
    window.zE('webWidget:on', 'chat:unreadMessages', (unread: number) => {
      this.unread = unread
    })
  }

  openWindow = () => {
    const { authStore, userStore: { data }, router, modalController } = this.rootStore

    if (authStore.isLoggedIn) {
      window.zE('webWidget', 'identify', {
        name: `${data.firstName} ${data.lastName}`,
        email: data.emailAddress,
      })
    }

    const currentPathname = router.asPath

    // By default use URL as search
    let suggestionOptions = { search: currentPathname }

    // If we have a modal open check for special cases
    if (modalController.windowHash === '#account') {
      suggestionOptions = { search: 'account' }
    } else if (currentPathname === '/booking/select-professional-time') {
      suggestionOptions = { search: 'professional' }
    } else if (currentPathname === '/booking/summary') {
      suggestionOptions = { search: 'booking-summary' }
    }

    analytics.track('Opened Zendesk', {
      ...suggestionOptions,
      unread: this.unread,
    })

    this.unread = 0

    window.zE('webWidget', 'helpCenter:setSuggestions', suggestionOptions)
    window.zE.activate()
  }

  dismissUnread = () => {
    this.unread = 0
  }
}

export default ZendeskHandler