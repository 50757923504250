import { action, observable } from 'mobx'
import ListStore from '../../../shared-lib/stores/ListStore'
import { GetCrossSellPromoCodeMutation } from '../../graph/generated/client'
import { $PromoCodeModel } from './PromoCodeModel'

export type $PromoCodeStore = PromoCodeStore

export default class PromoCodeStore extends ListStore<$PromoCodeModel> {
  @observable crossSellItems: Record<string, GetCrossSellPromoCodeMutation['getCrossSellPromoCode']> = {}
  fetchMethodName = 'userPromoCodes'

  @action.bound getCrossSellCode_api = async (bookingId: string) => {
    const { communication: { requester } } = this.rootStore

    this.loading = true

    if (bookingId) {
      try {
        const ret = await requester.getCrossSellPromoCode({ bookingId })
        if (ret.getCrossSellPromoCode) {
          this.crossSellItems[bookingId] = ret.getCrossSellPromoCode
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }

  findItemByCode = (code: string) => {
    return this.items.find(promoCode => promoCode.data.promoCode === code)
  }

  findItemByBookingId = (_id: string) => {
    return this.crossSellItems[_id]
  }
}
