import * as Backgrounds from '../public/img/categories/'
import * as Icons from '../public/img/navMenuIcons/'

const categories = {
  default: {
    icon: Icons.Packages,
    textColor: '#EFEAED',
    color: '#d59d96',
  },
  nails: {
    backgroundImage: Backgrounds.Nails,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 30%',
    icon: Icons.Nails,
    textColor: 'white',
    color: '#d59d96',
  },
  facial: {
    backgroundImage: Backgrounds.Facial,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Facial,
    color: '#c98a71',
  },
  eyes: {
    backgroundImage: Backgrounds.Eyes,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Eyes,
    color: '#b8997d',
  },
  make_up: {
    backgroundImage: Backgrounds.Makeup,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Makeup,
    color: '#745d67',
  },
  massage: {
    backgroundImage: Backgrounds.Massage,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 60%',
    textColor: 'white',
    icon: Icons.Massage,
    color: '#7b5d6b',
  },
  waxing: {
    backgroundImage: Backgrounds.Waxing,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left',
    textColor: 'white',
    icon: Icons.Waxing,
    color: '#99a3a2',
  },
  spray_tan: {
    backgroundImage: Backgrounds.Spraytan,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Spraytan,
    color: '#b4927e',
  },
  hair: {
    backgroundImage: Backgrounds.Hair,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Hair,
    color: '#a0a5a1',
  },
  hairdressing: {
    backgroundImage: Backgrounds.Hairdressing,
    backgroundImagePositionLarge: 'center bottom',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Hairdressing,
    color: '#a0a5a1',
  },
  iv_drips: {
    backgroundImage: Backgrounds.Ivdrips,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 20%',
    textColor: 'white',
    icon: Icons.Ivdrips,
    color: '#d2a363',
  },
  packages: {
    backgroundImage: Backgrounds.Packages,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Packages,
    color: '#400d15',
  },
  aesthetics: {
    backgroundImage: Backgrounds.Aesthetics,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Aesthetics,
    color: '#a9b7b2',
  },
}

export default categories