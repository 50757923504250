import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Divider, Hidden, SwipeableDrawer, Typography, WithStyles, withStyles } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'

import { TrustPilotBox } from '../'
import { currencyFormat } from '../../shared-lib/currency'

import RootStore from '../../src/stores/RootStore'
import { breakPoints, colors } from '../../theme/'
import RectButton from '../RectButton'
import TextButton from '../TextButton'
import BookingPostcode from './BookingPostcode'
import BookingProfessional from './BookingProfessional'
import ItemsTotal from './ItemsTotal'
import ItemSummary from './ItemSummary'
import InstructionsView from '../InstructionsView'
import { resetWindowScrollY, recordWindowScrollY } from '../../lib/modalFix'
import { styleCreate } from '../../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
  theme?: any
  drawer?: boolean
  visible?: boolean
}

const styles = styleCreate(theme => ({
  summaryMobile: {
    padding: theme.spacing(4, 4, 0, 4),
    overflowY: 'auto',
  },
  header: {
    marginTop: -8,
  },
  sidePanel: {
    backgroundColor: colors.BG300,
    padding: theme.spacing(4),
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: colors.BG300,
      height: '80vh',
      borderRadius: theme.spacing(2, 2, 0, 0),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.between('sm', 'lg')]: {
        height: '100vh',
        borderRadius: theme.spacing(2, 0, 0, 2),
        minWidth: 400,
      },
    },
  },
  footerMobile: {
    padding: theme.spacing(4),
    backgroundColor: colors.BG100,
    boxSizing: 'border-box',
  },
  footerMobileLoyalty: {
    padding: theme.spacing(4),
    paddingTop: 0,
    backgroundColor: colors.BG100,
    boxSizing: 'border-box',
  },
}))

@inject('store')
@observer
class CartSummary extends Component<Props> {
  goToSelectTime = async () => {
    const { checkoutStore, uiStore, routeHandler, i18n: { s } } = this.props.store
    const { toggleCartVisible } = uiStore

    toggleCartVisible(false)

    const { minPrice, minPriceReached } = checkoutStore

    if (!minPriceReached) {
      uiStore.showMessage({
        title: s('Error'),
        body: s('The minimum booking value is $0. Please add another treatment to continue.', currencyFormat(minPrice)),
      })
      return
    }

    routeHandler.redirectPreCheckout()
  }

  showInstructionsPopup = () => {
    const { modalController } = this.props.store!
    modalController.openModal('bookingInstructions')
  }

  render() {
    const { drawer, store, classes } = this.props
    const { i18n: { s }, checkoutStore, uiStore } = store!
    const { booking, provisionalProfessional } = checkoutStore
    const { data: { items } } = booking
    const { cartVisible, toggleCartVisible } = uiStore

    const footerClassName = items.length > 0 ? classes.footerMobileLoyalty : classes.footerMobile

    const top = <Box>
      <Box mb={3} mt={1}>
        <Typography variant='h4' className={classes.header}>{s('Your Booking')}</Typography>
      </Box>

      <Divider />

      <BookingPostcode />
      {provisionalProfessional && <BookingProfessional />}

      <Divider />

      {items.length === 0
        ? <Box mt={4} mb={2}>
          <Typography variant='body1'>{s('EMPTY_CART_MESSAGE')}</Typography>
        </Box>
        : <ItemSummary canEdit={true} />
      }

      <InstructionsView booking={booking} editable boxProps={{ 'mb': 3, 'mt': 3 }} />
    </Box>

    const footer = <Box mt={2}>
      <ItemsTotal />

      <RectButton
        onClick={this.goToSelectTime}
        color='primary'
        variant='contained'
        fullWidth
        endIcon={<ArrowForwardIos />}
        style={{ padding: '0 18px' }}
      >
        {s('Select time')}
      </RectButton>

      <Hidden mdDown>
        <Box mt={6}>
          <TrustPilotBox />
        </Box>
      </Hidden>

      {drawer && <Box textAlign='center' mt={3} mb={1}>
        <TextButton onClick={() => toggleCartVisible(false)}>
          {s('HIDE_CART')}
        </TextButton>
      </Box>}
    </Box>

    if (drawer) {
      const anchor = window.innerWidth > breakPoints.sm ? 'right' : 'bottom'

      return (
        <SwipeableDrawer
          anchor={anchor}
          open={cartVisible}
          onEnter={recordWindowScrollY}
          onExit={resetWindowScrollY}
          onClose={() => toggleCartVisible(false)}
          onOpen={() => toggleCartVisible(true)}
          className={classes.drawer}
          aria-label='cart_summary'
        >
          <Box className={classes.summaryMobile}>
            {top}
          </Box>
          <Box className={footerClassName}>
            {footer}
          </Box>
        </SwipeableDrawer>
      )
    }

    return (
      <Box className={`hideScrollbars ${classes.sidePanel}`} aria-label='cart_summary'>
        {top}
        {footer}
      </Box>
    )
  }
}

export default withStyles(styles)(CartSummary)
