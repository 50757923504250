import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Image from 'next/image'

import { Box, Typography, WithStyles, withStyles } from '@material-ui/core'

import { Avatar } from '../'
import LocationSmallBlack from '../../public/img/icons/locationSmallBlack.png'
import RootStore from '../../src/stores/RootStore'
import { colors } from '../../theme'
import { upperCase } from 'voca'
import { styleCreate } from '../../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  cartButton: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    height: theme.spacing(10),
    borderRadius: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    boxShadow: '0px 3px 8px 2px #653ED310',
    backgroundColor: colors.BG100,
    zIndex: 400,
    '&:hover': {
      backgroundColor: colors.BG300,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  itemCount: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(-1),
    top: theme.spacing(-0.5),
    background: colors.PRI900,
    textAlign: 'center',
  },
  itemCountText: {
    color: 'white',
  },
  postcode: {
    marginRight: theme.spacing(3),
  },
}))

@inject('store')
@observer
class CartButton extends Component<Props> {
  render() {
    const { store, classes } = this.props
    const { checkoutStore, uiStore } = store!
    const { booking, provisionalProfessional } = checkoutStore
    const { items, postcode: { code } } = booking.data
    const { toggleCartVisible } = uiStore

    return (
      <Box onClick={() => { toggleCartVisible(true) }} className={classes.cartButton} boxShadow={8}>
        <Box className={classes.icon}>
          <Image src={LocationSmallBlack} alt={'location'} priority />
        </Box>
        <Typography className={classes.postcode} variant='body2'>{upperCase(code)}</Typography>

        {provisionalProfessional && <Box marginRight='4px' marginLeft={-1}>
          <Avatar
            user={provisionalProfessional}
            size={52}
          />
        </Box>}

        <Box className={classes.itemCount}>
          <Typography className={classes.itemCountText} variant='body2'>{items.length}</Typography>
        </Box>
      </Box>
    )
  }
}

export default withStyles(styles)(CartButton)
