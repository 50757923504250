import { computed, observable } from 'mobx'
import moment from 'moment-timezone'
import analytics from '../../src/stores/analytics'
import RootStore from '../../src/stores/RootStore'
import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'

export default class EditBookingHandler {
  @observable editingBookingId = ''
  @observable loading = false
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed get booking() {
    return this.rootStore.bookingStore.findItemById(this.editingBookingId)
  }

  editBookingTime = (bookingId: string) => {
    this.editingBookingId = bookingId
  }

  clearEditingBookingId = () => {
    this.editingBookingId = ''
  }

  updateBookingTime_api = async (professional: $Professional | undefined, date: Date, type: 'instant' | 'request' | 'sendToAll') => {
    const bookingData = this.booking!.data

    analytics.track('Booking Time Updated', {
      booking__id: bookingData._id,
      booking_status: bookingData.status,
      frombookingType: bookingData.type,
      tobookingStatus: type,
      fromDate: bookingData.date,
      toDate: date,
      didChangeProfessional: professional?._id !== bookingData.professional?._id,
    })

    this.booking!.set({
      professional: type === 'instant' ? professional : null,
      date,
      requestedProfessionals: type === 'request' ? [professional] : [],
      type,
    })

    this.loading = true
    const bookingCreated = await this.publishBooking_api()
    this.loading = false

    return bookingCreated
  }

  publishBooking_api = async () => {
    const { communication: { requester }, dropdownMessageHandler, i18n: { s } } = this.rootStore

    if (!this.booking) return false

    const data = this.booking.toRequestData() as BookingInput

    try {
      const response = await requester.publishBooking({ data, sendNotifications: true })

      const newBooking = response.publishBooking

      if (newBooking) {
        this.booking.merge(newBooking)

        const { type } = newBooking

        const modalType = type === 'instant' ? 'success' : 'warn'
        const modalTitle = type === 'instant' ? 'Booking time sucessfully updated.' : 'Booking request updated.'

        dropdownMessageHandler.showDropdownMessage({
          type: modalType,
          title: s(modalTitle),
        })

        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }

  showRequestWarning = (newDate: Date, onAccept: () => void) => {
    const { uiStore, i18n: { s } } = this.rootStore

    const formattedDate = moment(newDate).format('H:mm on dddd Do MMM')

    uiStore.showMessage({
      title: s('REQUEST_WARNING_TITLE'),
      body: s('REQUEST_WARNING_BODY', formattedDate),
      buttons: [
        {
          title: 'Confirm date change',
          onPress: onAccept,
        },
        {
          title: 'Cancel',
        },
      ],
    })
  }

  confirmNewBookingTime = (newDate: Date, onAccept: () => void) => {
    const { uiStore, i18n: { s } } = this.rootStore

    const formattedDate = moment(newDate).format('H:mm on dddd Do MMM')

    uiStore.showMessage({
      title: s('CONFIRM_NEW_BOOKING_DATE_TITLE'),
      body: s('CONFIRM_NEW_BOOKING_DATE_BODY', formattedDate),
      buttons: [
        {
          title: 'Confirm date change',
          onPress: onAccept,
        },
        {
          title: 'Cancel',
        },
      ],
    })
  }
}