import { toJS } from 'mobx'
import { randomFromArray } from '../utils'
import addKeyPrefix from './addKeyPrefix'

export default class AnalyticsBase {
  campaignData: Record<string, any> = {}
  userData: Record<string, any> = {}

  config: any = {
    currency: 'GBP',
  }

  sessionContext: Record<string, any> = {}
  userTraits: Record<string, any> = {}
  testGroups: Record<string, boolean | number | string> = {}
  onTrackMethod?: (eventName: string, propertes: Record<string, any>, userTraits: Record<string, any>) => void

  storage: any = {}

  get analytics(): any {
    return {}
  }

  parseProperties = (properties: Record<string, any>) => {
    const parsedProperties = { ...properties }

    // Ensure dates are parsed as strings
    for (const key in parsedProperties) {
      if (parsedProperties[key] instanceof Date) parsedProperties[key] = parsedProperties[key].toISOString()
    }

    return parsedProperties
  }

  // Ensures all properties are strings or numbers for ecommerce tracking
  simplifyEventData = (properties: Record<string, any>) => {
    const simplifiedProperties = { ...properties }

    for (const key in simplifiedProperties) {
      const type = typeof simplifiedProperties[key]
      if (type === 'object') simplifiedProperties[key] = JSON.stringify(simplifiedProperties[key])
    }

    return simplifiedProperties
  }

  resetSessionContext = () => {
    this.sessionContext = {}
  }

  setSessionContext = (newContext: Record<string, any>) => {
    for (const i in newContext) {
      this.sessionContext[`${i}`] = newContext[i]
    }
  }

  incrementSessionContextProperty = (property: string, amount = 1) => {
    const currentValue = this.sessionContext[property] || 0

    if (typeof currentValue === 'number') {
      this.sessionContext[property] = currentValue + amount
    }
  }

  setUserTraits = (userTraits: Record<string, any>) => {
    for (const i in userTraits) {
      this.userTraits[i] = userTraits[i]
    }
  }

  startABTests(config: Record<string, Array<any>>, overrides: Record<string, any>) {
    /*
     * AB test values should be defined in remote config like so:
     * abTests: {
     *    myTest: [true, false],
     *    variableThing: [1,3,4],
     *    otherThing: ["foo", "bar"]
     * }
     */

    Object.keys(config).forEach((abTestName: string) => {
      const values = config[abTestName]

      if (values) {
        this.testGroups[abTestName] = randomFromArray(values)
      }
    })

    Object.keys(overrides).forEach((abTestName: string) => {
      this.testGroups[abTestName] = overrides[abTestName]
    })
  }

  ABTest(testName: string) {
    const inTestGroup = this.testGroups[testName]
    return inTestGroup
  }

  getContext() {
    return {
      ...addKeyPrefix(this.campaignData, 'A'),
      ...addKeyPrefix(this.sessionContext, 'S'),
      ...addKeyPrefix(this.userTraits, 'U'),
      ...addKeyPrefix(this.testGroups, 'AB'),
      platform: this.config.platform,
      currency: this.config.currency,
      timezone: this.config.timezone,
      SS_ENV: this.config.name,
      codePushVersion: this.config.codePushVersion,
      version: this.config.version,
    }
  }

  // Track generic event to segment
  track(eventName: string, data?: any) {
    const properties = this.parseProperties({
      ...toJS(data),
      ...this.getContext(),
    })

    this.analytics.track(eventName, properties)

    this.onTrackMethod && this.onTrackMethod(eventName, properties, { ...this.userTraits, ...this.userData })
  }

  // Identify user credentials and send to segment and facebook analytics
  identify(actingAs: 'PROFESSIONAL' | 'CLIENT', user: Record<string, any>, extra?: Record<string, any>) {
    const { _id, lastName, firstName, emailAddress, mobile, gender, role } = user

    if (!_id) return

    const userData = {
      _id,
      role,
      lastName,
      firstName,
      emailAddress: emailAddress || '',
      email: emailAddress || '', // Duplicate key for customer.io as they use email for identification
      mobile: mobile || '',
      phone: mobile || '', // Duplicate key for mixpanel as 'mobile' is reserved
      gender: gender || '',
      SS_ENV: this.config.name,
      platform: this.config.platform,
      timezone: this.config.timezone,
      ...extra,
      ...this.userTraits,
    }

    const prefix = actingAs === 'PROFESSIONAL' ? 'PRO_' : ''
    this.analytics.identify(`${prefix}${_id}`)
    this.userData = userData
  }

  alias = (oldUserId: string, newUserId: string) => {
    this.analytics.alias(oldUserId, newUserId)
  }

  // Reset segment, use when logging out
  reset = () => {
    this.analytics.reset()
  }

  // Facebook registration event, triggered after login
  completeRegistration = () => {
    this.track('User Signed Up', {
      currency: this.config.currency,
    })
  }

  onTrack = (onTrackMethod: typeof this.onTrackMethod) => {
    this.onTrackMethod = onTrackMethod
  }
}
