import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, CircularProgress, createStyles, WithStyles, withStyles } from '@material-ui/core'

const styles = () => createStyles({
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    background: '#ffffffc7',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: 99,
  },
})

@observer
class LoadingOverlay extends Component<WithStyles<typeof styles>> {
  render() {
    const { loadingOverlay } = this.props.classes

    return (
      <Box className={loadingOverlay}>
        <CircularProgress />
      </Box>
    )
  }
}

export default withStyles(styles)(LoadingOverlay)
