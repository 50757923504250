import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Divider, Typography } from '@material-ui/core'
import { colors } from '../../theme'

import { currencyFormat } from '../../shared-lib/currency'
import { itemDescription, itemPrice } from '../../shared-consumer/helpers/bookingItemHelpers'
import { $Treatment } from '../../shared-consumer/stores/TreatmentStore/TreatmentModel'
import RootStore from '../../src/stores/RootStore'
import TextButton from '../TextButton'
import { ELITE_TREATMENT_COST, OFFPEAK_ITEM_NAME, SERVICE_FEE_ITEM_NAME } from '../../shared-lib/commonStrings'
import InfoIconButton from '../InfoIconButton'

interface Props {
  store?: RootStore
  canEdit: boolean
}

@inject('store')
@observer
class ItemSummary extends Component<Props> {
  showServiceFeeInfo = () => {
    const { uiStore, i18n: { s } } = this.props.store!

    uiStore.showInformationModal({
      title: s('Service Fee'),
      body: s('SERVICE_FEE_EXPLAINER'),
    })
  }

  render() {
    const { store, canEdit } = this.props
    const { booking: { data: { items } }, removeItem } = store!.checkoutStore
    const { i18n: { s } } = store!

    return (
      <Box aria-label={'cart_items'}>
        {
          items.map((item: { _id: string, treatment: $Treatment }, index: number) => {
            const isServiceFee = item.treatment.name === SERVICE_FEE_ITEM_NAME
            const isRemovable = !isServiceFee && item.treatment.name !== OFFPEAK_ITEM_NAME &&
              item.treatment.name !== ELITE_TREATMENT_COST

            return <Box key={index}>
              <Box mt={2} key={item._id} display='flex' flexDirection='row' justifyContent='space-between'>
                <Box className='animate__animated animate__fadeIn'>
                  <Box display='flex'>
                    <Typography variant='body2'>{item.treatment.name}</Typography>

                    {/* AB Test: Service Fees */}
                    {isServiceFee &&
                      <InfoIconButton onClick={this.showServiceFeeInfo} color={colors.INK400} />
                    }
                  </Box>

                  <Typography variant='body1'>{itemDescription(item.treatment)}</Typography>

                  {canEdit && isRemovable && <Box mt={1}>
                    <TextButton onClick={() => { removeItem(item) }}>{s('Remove')}</TextButton>
                  </Box>}
                </Box>

                <Box pl={2}>
                  <Typography variant='body2'>{currencyFormat(itemPrice(item.treatment))}</Typography>
                </Box>
              </Box>

              <Box mt={2} mb={2}>
                <Divider />
              </Box>
            </Box>
          })
        }
      </Box>
    )
  }
}

export default ItemSummary
