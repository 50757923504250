import React from 'react'

const LogoIcon = () => {
  return <React.Fragment>
    <img src='/img/logos/s.svg' alt='Secret Spa' className='logo' />

    <style> {`
      .logo {
        width: 100%;
      }
    `}</style>
  </React.Fragment>
}

export default LogoIcon
