import { toJS } from 'mobx'
import { itemPrice } from '../../shared-consumer/helpers/bookingItemHelpers'
import { $BookingItem, $BookingItemTreatment, $BookingModel } from '../../shared-consumer/stores/BookingStore/BookingModel'
import { $Treatment, $TreatmentOptionGroup } from '../../shared-consumer/stores/TreatmentStore/TreatmentModel'
import addKeyPrefix from '../../shared-lib/lib/addKeyPrefix'
import AnalyticsBase from '../../shared-lib/lib/AnalyticsBase'

export default class AnalyticsConsumerBase extends AnalyticsBase {
  parseTreatment = (treatment: $Treatment | $BookingItemTreatment, i = 0) => {
    const keyPrefix = `treatment_${i}`

    const params = addKeyPrefix(treatment, keyPrefix, ['_id', 'name', 'type', 'duration'])

    params[`${keyPrefix}_price`] = itemPrice(treatment) / 100

    const selectedOptionNames: Array<string> = []
    treatment.optionGroups && treatment.optionGroups.forEach((optionGroup: $TreatmentOptionGroup) => {
      const selectedOptions = optionGroup.options.filter((option: $Treatment) => option.selected)
      selectedOptions.forEach((option: $Treatment) => {
        selectedOptionNames.push(option.name)
      })
    })

    params[`${keyPrefix}_selected_options`] = selectedOptionNames

    return params
  }

  ecommerceStep(action: 'Viewed' | 'Completed', stepNumber: number, stepName: string, booking: $BookingModel, params: any = {}) {
    const o: any = {
      checkout_id: new Date(booking.data.createdAt || Date.now()).getTime(),
      step: stepNumber,
      stepName,
      currency: this.config.currency,
    }

    o.total = o.price = o.value = booking.totalPriceComputed / 100

    this.track(`Checkout Step ${action}`, {
      ...o,
      ...params,
    })
  }

  // Facebook event when treatment added to bookng or when a treatment is viewed
  ecommerceEvent(eventName: string, treatment?: $Treatment | $BookingItemTreatment, booking?: $BookingModel, extra?: Record<string, any>) {
    let o: any = {}

    if (treatment) {
      o = {
        ...this.parseTreatment(treatment),
        quantity: 1,
      }

      o.total = o.price = o.value = ((treatment.price || 0) / 100)
    }

    if (booking) {
      const bookingTotal = booking.totalPriceComputed / 100

      o.total = o.price = o.value = bookingTotal

      o.products = toJS(booking.data.items.map((item: $BookingItem) => {
        return this.parseTreatment(item.treatment)
      }))

      o.checkout_id = new Date(booking.data.createdAt || Date.now()).getTime()
      o.orderId = o.cart_id = booking.data._id
    }

    if ('Order Completed' === eventName) {
      o.revenue = o.total
    }

    this.analytics.track(eventName, this.parseProperties(
      this.simplifyEventData({
        ...o,
        ...extra,
        currency: this.config.currency,
      }),
    ))
  }
}
