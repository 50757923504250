import { action } from 'mobx'
import ListStore from '../../../shared-lib/stores/ListStore'
import ParkingInformationModel, { $ParkingInformationModel } from './ParkingInformationModel'

export default class ParkingInformationStore extends ListStore<$ParkingInformationModel>{
  ModelClass = ParkingInformationModel

  @action.bound fetchItemByProperty_api = async (value: string, property: string) => {
    this.loading = true

    const item = this.findItemByProperty(value, property)
    const options = { variables: { [property]: value } }

    if (item) {
      await item.fetch_api(options)
    } else {
      const newItem = new this.ModelClass({ [property]: value }, this.rootStore)
      await newItem.fetch_api(options)
      this.items.push(newItem)
    }

    this.loading = false

    return this.findItemByProperty(value, property)
  }
}

export type $ParkingInformationStore = ParkingInformationStore
