// English

export default {
  SUPPORT_SUBJECT: 'New message from $0',
  SUPPORT_BODY: 'Hello,\n\nI am reaching you today to ...\n\nKind regards,\n\n',
  REGION_CHANGED_TITLE: 'Changing booking region',
  REGION_CHANGED_BODY: 'You are changing your booking region. You will need to reselect your treatments and professional.',
  POSTCODE_CHANGED_TITLE: 'Booking address change',
  POSTCODE_CHANGED_BODY: 'Your selected booking address has changed. You will need to reselect your booking time and professional.',
  POSTCODE_OUTSIDE_AREA_TITLE: "We don't currently operate in your area",
  POSTCODE_OUTSIDE_AREA_BODY: "We're adding new locations all the time, so we'll let you know as soon as we're in your area.",
  POSTCODE_OUTSIDE_PRO_AREA_TITLE: 'Area not covered',
  POSTCODE_OUTSIDE_PRO_AREA_BODY: 'Sorry, $0 does not take bookings in $1. Please select a different professional.',
  TREATMENT_NOT_COVERED_BY_PRO_TITLE: 'Treatments not available',
  TREATMENT_NOT_COVERED_BY_PRO_BODY: '$0 does not currently offer $1. To book with $0 please remove $1 from your booking.',
  CHANGING_PRO_TITLE: 'Are you sure?',
  CHANGING_PRO_BODY: 'Your current booking with $0 will be cleared.',
  POSTCODE_OUTSIDE_AREA_EXTRA_INFO: "You can still browse the website but you'll need to add a postcode we cover if you want to book",
  ACCOUNT_EXISTS_TITLE: 'Account already exists',
  ACCOUNT_EXISTS_BODY: 'An account using this number already exists.',
  ACCOUNT_EXISTS_ACTION: 'Use this number to sign in',
  AUTH_ERROR_TITLE: 'An error occured',
  AUTH_ERROR_BODY: 'Something went wrong while acessing your account. Please try again.',
  NO_ACCOUNT_EXISTS_TITLE: 'No account found',
  NO_ACCOUNT_EXISTS_BODY: "We couldn't find an account with this mobile number.",
  NO_ACCOUNT_EXISTS_ACTION: 'Use this number to create a new account',
  ACCEPT_TERMS_TITLE: 'Terms & Conditions',
  ACCEPT_TERMS_BODY: 'Please accept the Terms & Conditions to continue',
  VERIFY_CODE_RESENT_TITLE: 'Verification code resent',
  VERIFY_CODE_RESENT_BODY: 'We have resent the verification code to the mobile number provided.',
  ERROR_SENDING_VERIFICATION_TITLE: 'Error sending verification code.',
  ERROR_SENDING_VERIFICATION_BODY: 'There was an error sending a verification code to $0. Please check the number and try again.',
  VERIFY_CODE_SPAM_TITLE: 'Please wait..',
  PRO_UNCOVERED_TREATMENT: '$0 doesn\'t offer any treatments in this category. Please choose another category or remove $0 from your booking.',
  VERIFY_CODE_SPAM_BODY: 'A confirmation code was sent recently, use that one or wait $0 seconds.',
  CONFIRM_CANCEL_TITLE: 'Are you sure you want to cancel your booking?',
  CONFIRM_CANCEL_BODY_REFUND: 'Upon cancelling your booking you will receive a refund of $0.',
  CONFIRM_CANCEL_BODY_CREDITS: 'Upon cancelling your booking you will receive a credit refund of $0.',
  CONFIRM_CANCEL_BODY_NO_REFUND_NEEDED: 'Your booking will be cancelled and the professional will be notified.',
  CONFIRM_CANCEL_BODY_REFUND_AND_CREDITS: 'Upon cancelling your booking you will receive a refund of $0 and a credit refund of $1.',
  CONFIRM_CANCEL_BODY: 'As you are outside the cancellation period you will not receive any refund for your booking.',
  BOOKING_VALIDATION_ERROR_TITLE: 'Booking error',
  BOOKING_VALIDATION_ERROR_BODY: 'There was a problem creating your booking. If the problem persists please contact customer support. You will now be redirected to the Home screen.',
  BOOKING_INVALID_TIME_TITLE: 'Booking slot unavailable',
  BOOKING_INVALID_TIME_BODY: 'Your selected booking time is unavailable. Please select another time for your booking.',
  INSUFFICIANT_FUNDS_BODY: 'The payment was declined due to insufficient funds on your card. Please add funds and try again.',
  PAYMENT_ERROR_BODY: 'There was an issue taking payment from your card. Please try again or contact support for help.',
  GIFT_CARD_DESCRIPTION: 'Our gift cards are redeemable on our full range of luxury salon and spa treatments. We will send your gift card and personalised message via email, either to you or to the recipient according to your selection.',
  GIFT_CARD_PREVIEW_BODY: '$0 has sent you a gift card to treat yourself to any of Secret Spa\'s luxury at-home treatments.',
  GIFT_CARD_REDEEM_HELP: 'To redeem your gift card, simply enter the code in your Secret Spa account on our app or website.',
  GIFT_CARD_DIGITAL_SEND_TO: 'This gift card will be sent instantly by email to $0',
  GIFT_CARD_PHYSICAL_SEND_TO: 'This gift card will be sent by post to $0',
  GIFT_CARD_SENT_TITLE: 'Your gift card has been sent.',
  GIFT_CARD_WILL_BE_SENT_TITLE: 'Your gift card will be sent out via first class post.',
  GIFT_CARD_SENT_BODY: 'To redeem your gift card, simply enter the code in your Secret Spa account on our app or website.',
  PROMO_INVALID_TITLE: 'Promocode cannot be applied',
  PROMO_NOT_ADDED_TITLE: 'Promocode cannot be added',
  PROMO_NO_LONGER_VALID_TITLE: 'Promocode is no longer valid and has been removed',
  COOKIE_BANNER_MESSAGE_1: 'We use 1st and 3rd Party cookies to provide all of our features. By accepting, you are agreeing to our use of cookies.',
  COOKIE_BANNER_COOKIE_LINK_MESSAGE: 'Find out more about how we use cookies.',
  COOKIE_BANNER_LINK_TEXT: 'More information on our use of cookies',
  EMPTY_CARD_LIST: 'You haven\'t added any cards yet.',
  CARD_FAILED_TO_ADD: 'Failed to add this card to your cards. Please try again. If this problem persists contact support.',
  EMPTY_ADDRESS_LIST: 'You haven\'t added any addresses yet.',
  EMPTY_CART_MESSAGE: 'Please select from our range of luxury treatments.',
  MATCHING_INFO_TITLE: 'How long does it take?',
  MATCHING_INFO_BODY: 'Normally your booking will be accepted within a couple of minutes. If it is taking longer, our customer service team will be in touch to assist you.',
  LEAVE_REVIEW_TITLE: 'We hope you enjoyed your booking with',
  ADD_FAVOURITE_TITLE: 'Add to favourites',
  ADD_FAVOURITE_BODY: 'Add $0 to your favourites so you can easily book her next time!',
  ASSURANCE_CANCELLATION: 'Cancel at no cost up to 24 hours before your booking',
  ASSURANCE_TIMING: 'Appointments available 6am - 10pm',
  ASSURANCE_TESTED: 'Professionals tested and recommended by us',
  ASSURANCE_AREAS: 'Now covering London, Manchester & Brighton',
  ASSURANCE_DBS: 'All professionals insured and DBS checked',
  ASSURANCE_SUPPORT: 'Dedicated support team to help with any bespoke requests',
  MARKETING_PREFERENCES_DESCRIPTION: 'Let us know how you would like to receive notifications about your bookings',
  SIGN_IN_UP_BOOKING_TITLE: 'Login or sign-up',
  SIGN_IN_UP_BOOKING_BODY: 'You need to login or create a new account to continue with the booking process.',
  PRO_APPLICATION_HEADING: 'Your Application',
  PRO_APPLICATION_SUBHEADING: 'Join our elite network of mobile therapists. We are looking for London, Manchester and Brighton based professionals with experience in hair, beauty and wellness treatments.',
  PRO_APPLICATION_SUBMITTED_HEADING: 'Thank You!',
  PRO_APPLICATION_SUBMITTED_SUBHEADING: 'We have received your application. We will review it and contact you in the next couple of days.',
  PRO_APPLICATION_EXPERIENCE_HEADING: 'Your Beauty Experience',
  PRO_APPLICATION_EXPERIENCE_SUBHEADING: 'Please enter the date you have started working professionally in beauty',
  PRO_APPLICATION_SETUP_HEADING: 'Your Mobile Beauty Setup',
  PRO_APPLICATION_SETUP_SUBHEADING: 'Please select the treatments you are currently trained in and set up to offer mobile*',
  PRO_APPLICATION_QUALIFICATIONS_HEADING: 'Your Qualifications',
  PRO_APPLICATION_EMPLOYMENT_HEADING: 'Employment History',
  PRO_APPLICATION_CAREER_HEADING: 'Your Career Highlights',
  PRO_APPLICATION_EXTRA_INFORMATION_HEADING: 'Additional Information',
  PRO_APPLICATION_EXTRA_INFORMATION_SUBHEADING: 'Please provide a brief bio about yourself, including any work experience*',
  ADD_TREATMENTS_BODY: 'This will take you back to the treatment browser',
  TOKEN_EXPIRED_TITLE: 'Session expired',
  TOKEN_EXPIRED_BODY: 'Your session has expired. You will need re-start your account set up.',
  TOKEN_EXPIRED_BODY_USER: 'Your session has expired. Please re-authenticate your mobile number ($0) to continue',
  REAUTH_FAILED_TITLE: 'Re-authentication failed',
  REAUTH_FAILED_BODY: 'Something went wrong while trying to re-authenticate on Secret Spa. Please login manually.',
  COMING_SOON_TITLE: 'Coming soon',
  COMING_SOON_BODY: 'We will resume these services as soon as the Government announces it is safe to do so.\n\nThank you for your patience.',
  COMING_SOON: 'We\'ll let you know as soon as you can book your $0 services.',
  NO_INTERNET_TITLE: 'No internet connection',
  NO_INTERNET_BODY: 'Please check you are connected to wifi or mobile internet and try again.',
  BOOKING_NOT_FOUND_TITLE: 'Booking not found',
  BOOKING_NOT_FOUND_BODY: 'We cannot find the booking you have requested. Please try again.',
  PURCHASED_GIFT_CARD_BODY: 'Your gift card was successfully purchased and has been sent to the email you provided.',
  UPDATE_REQUIRED_INFO: 'An update is required to continue using the Secret Spa App.\
    \n\nPlease update to the latest version via the AppStore.',
  CATEGORY_NOT_COVERED_BY_PRO_TITLE: 'Category not available',
  CATEGORY_NOT_COVERED_BY_PRO_BODY: '$0 does not offer any treatments in the $1 category. To continue you must remove $0 from your booking.',
  EXIT_PRO_FLOW_TITLE: 'Are you sure?',
  EXIT_PRO_FLOW_BODY: 'If you continue $0 will be removed from your booking.',
  SIGN_OUT_PROMPT_TITLE: 'Are you sure?',
  SIGN_OUT_PROMPT_BODY: 'Please confirm you would like to sign out of your account',
  CONTACT_EMAIL_ERROR: 'There was a problem creating the email. Please send an email to $0.',
  CONTACT_PHONE_ERROR: 'There was a problem making the phone call. Please call $0.',
  APPSTORE_LINK_ERROR: 'There was an error opening the AppStore. To update open the AppStore on your phone and update the Secret Spa app.',
  UPDATE_REQUIRED_TITLE: 'Update Required',
  GENERIC_CAMERA_ERROR: 'Something went wrong while trying to pick or take your photo. Please try again.',
  CAMERA_PERMISSION_ERROR: 'Camera permissions are disabled for the Secret Spa App. Please enable them in the Settings app.',
  MIN_BOOKING_PRICE: 'The minimum booking value is $0. Please add another treatment to continue',
  ONBOARDING_1_TITLE: 'Convenient',
  ONBOARDING_1_TEXT: 'Salon-quality treatments without leaving the house',
  ONBOARDING_2_TITLE: 'Flexible',
  ONBOARDING_2_TEXT: 'Appointments to suit you from 6am - 10pm',
  ONBOARDING_3_TITLE: 'Trusted',
  ONBOARDING_3_TEXT: 'Experienced & friendly professionals recommended personally by us',
  LOAD_ERROR_TITLE: 'Error',
  LOAD_ERROR_BODY: 'There was a problem loading the app. Please try relaunching the app or get in touch with our support team.',
  LOAD_ERROR_BUTTON: 'Relaunch app',
  BOOKING_CANCELLED_TITLE: 'Booking cancelled',
  BOOKING_CANCELLED_BODY: 'Your booking has been successfully cancelled',
  REFER_FRIEND_DOWNLOAD: 'Download link: $0',
  ENABLE_NOTIFICATIONS_IN_SETTINGS_TITLE: 'Notifications are disabled',
  ENABLE_NOTIFICATIONS_IN_SETTINGS_BODY: 'To enable notifications open the Settings app, navigate to Secret Spa, Notifications and toggle "Allow Notifications".',
  NOT_REGISTERED_FOR_NOTIFICATIONS_TITLE: 'Failed to enable notifications',
  NOT_REGISTERED_FOR_NOTIFICATIONS_BODY: 'Your device is not registered for notifications. Please try again or contact customer support.',
  CART_EMPTY: "You haven't added any treatments to your booking yet.",
  REFER_A_FRIEND_HOME: 'Earn credit when you refer a friend to Secret Spa.',
  FIND_PROS_HOME: 'Discover the best beauty & wellness professionals in your area.',
  CUSTOMER_SUPPORT_INFO: 'Our customer service team is available every day from 6am to 10pm.',
  REWARD_PROGRESS_FAR: 'You’re on your way! Reach $0 points for $1 off your next booking.',
  REWARD_PROGRESS_CLOSE: 'Almost there! Reach $0 points for $1 off your next booking.',
  REWARD_PROGRESS_REACHED: 'Congratulations! You reached $0 points. You can now redeem your points for $1 off your next booking.',
  REWARD_HOW_IT_WORKS_TITLE: 'How it works',
  REWARD_HOW_IT_WORKS_BODY_PART_1: 'You earn loyalty points for every booking you make\n\nFor every $0 points you earn, you get $1 off your next booking.',
  REWARD_HOW_IT_WORKS_BODY_PART_2: 'Once you reached $0 points, you can choose to redeem your points.\n\nYour $1 will be credited to your account and will automatically apply when you make a booking.',
  REWARD_HOW_IT_WORKS_BODY_PART_3: 'After you reach $0 points, you immediately start earning towards your next reward.',
  HIDE_CART: 'Add more treatments',
  BOOKING_INSTRUCTIONS_ADD_PHOTOS: 'Add photos',
  BOOKING_INSTRUCTIONS_TITLE: 'Add instructions',
  BOOKING_INSTRUCTIONS_LABEL: 'Booking instructions',
  BOOKING_INSTRUCTIONS_BUTTON: 'Add instructions & photos',
  BOOKING_INSTRUCTIONS_PLACEHOLDER: 'Share with your professional any ideas or preferences you would like for the treatment.',
  BOOKING_INSTRUCTIONS_TEXTINPUT_LABEL: 'Instructions',
  REQUEST_AVAILABILITY_ALL: 'Request availability from our team of elite professionals in your area.',
  REQUEST_AVAILABILITY_SINGLE: 'Request availability from $0 on $1.',
  BOOKING_DATE_TOO_SOON_TITLE: 'Booking date too soon',
  BOOKING_DATE_TOO_SOON_BODY: 'Your booking date must be at least two hours in the future. Please select another date & time.',
  NO_SAME_DAY_REQUESTS_TITLE: 'Sorry, we are at max capacity today',
  NO_SAME_DAY_REQUESTS_BODY: 'All the professionals in your area are fully booked, please request a date from tomorrow onwards.',
  EXTRA_OUT_PROMPT_TITLE: 'Are you sure?',
  EXTRA_OUT_PROMPT_BODY: 'You have added treatments but not finalised payment. Do you want to discard your changes?',
  TREATMENT_COMBINATIONS_MANY_TITLE: 'You are booking multiple treatment categories',
  TREATMENT_COMBINATIONS_MANY_BODY: 'As many of our professionals are specialists in their field, one professional may not be able to offer all these treatments.\nWe would suggest making separate bookings to find the right professionals for your treatments.',
  TREATMENT_COMBINATIONS_IMPOSSIBLE_TITLE: 'Treatments cannot be booked together',
  TREATMENT_COMBINATIONS_IMPOSSIBLE_BODY: 'As our hairdressers are specialist in their field, it is not possible to book these treatments together.\nPlease make separate bookings for your treatments so multiple professionals can be confirmed.',
  AVAILABLE_PROS_CONTINUE_BUTTON: 'Continue to request booking',
  AVAILABLE_PROS_HEADER: 'The professionals below are available close to your requested booking time.',
  AVAILABLE_PROS_BODY: 'If none of these options work, you can still send your booking request for $0 to our network of professionals.',
  PARKING_INFORMATION_DESCRIPTION: 'Your professional may need somewhere to park for your booking. Any information you can provide will be hugely helpful to them.',
  SERVICE_FEE_EXPLAINER: 'This helps us to run our platform as we grow, continue to deliver high quality standards, provide unrivalled customer services and support our network of beauty entrepreneurs.',
  MOBILE_NUMBER_BODY_PART_1: 'To make your account even more secure, we use your mobile number to identify your account.',
  MOBILE_NUMBER_BODY_PART_2: 'We only use it in the rare circumstance that we need to contact you for a booking cancellation. Don\'t worry, we won\'t use it for any other reason!',
  PROFESSIONAL_PROFILE_CHECKED: 'DBS checked, fully insured & vetted by our team',
  RESTRICTED_UNAVAILABLE: '$0 is not available on $1',
  RESTRICTED_BOOKED: '$0 is fully booked on $1',
  RESTRICTED_PATCHTEST: 'You must be book at least $0 days in advance for your patch test.',
  REQUEST_BLOCK_EXPLAINER: "If you can't find an instant time that suits, our friendly team will find a professional at a time that works best for you.",
  INSTANT_BLOCK_EXPLAINER: 'Top professionals in your area who you can book instantly.',
  SELECT_REQUEST_TIME_EXPLAINER: 'Our team will find you a top professional in your area for your booking',
  NO_INSTANT_PROS: 'Sorry there are no instantly bookable pros in your area. Please request a time to continue your booking.',
  CONFIRMATION_DBS_MESSAGE: 'All our therapists are DBS checked, fully insured & fully vetted by our team.',
  PROMO_ROW_EXPIRES: 'Expires $0',
  PROMO_ROW_EXPIRED: 'Expired on $0',
  PROMO_ROW_NOT_ACTIVE: 'No longer active',
  PROMO_ROW_REFERRAL: 'Referral from $0',
  PROMO_DISCOUNT_EXCLUDING_BOTH: 'booking excluding $0 and $1',
  PROMO_DISCOUNT_INCLUDING_TYPES_EXCLUDING_TREATMENT: '$0 booking excluding $1',
  PROMO_DISCOUNT_EXCLUDING_SIMPLE: 'booking excluding $0',
  PROMO_DISCOUNT_INCLUDING_SIMPLE: '$0 booking',
  PROMO_DISCOUNT_NO_LIMIT: 'booking',
  PROMO_DISCOUNT_VALUE: '$0 off your next ',
  EXTRA_TREATMENTS_TOO_MANY_REMOVED: 'You have removed too many items.\nPlease add at least another $0 worth of treatments.',
  PROMO_LIST_EMPTY: 'You don\'t have any promocodes added yet.',
  INVALID_POSTCODE_TITLE: 'Invalid Postcode',
  INVALID_POSTCODE_BODY: 'Please enter a valid UK postcode and try again.',
  PROMO_APPLIED: 'The promo code has been applied to your booking.',
  BOOKING_PRO_NOT_AVAILABLE_TITLE: 'Sorry this professional is no longer available at this time',
  BOOKING_PRO_NOT_AVAILABLE_BODY: 'You will need to go back and select a different professional or time for your booking.',
  REQUEST_WARNING_TITLE: 'We cannot guarantee this time is available',
  REQUEST_WARNING_BODY: 'You are updating your booking time and date to $0.\n\nChanging your booking time will create a new request and a new professional will have to be confirmed.\n\nIf there are no professionals available at your selected time your booking will be cancelled.',
  CONFIRM_NEW_BOOKING_DATE_TITLE: 'Confirm new booking date',
  CONFIRM_NEW_BOOKING_DATE_BODY: 'Please confirm you would like to change your booking date to $0.',
  REVIEW_FEEDBACK_WOULD_REBOOK: 'What could $0 do to make this a 6 star experience next time?',
  REVIEW_FEEDBACK_WOULD_NOT_REBOOK: 'Tell us what $0 could have done differently so you would book them again.',
  REVIEW_FEEDBACK_NEGATIVE_ACTION_LABEL: 'We are sorry you did not have a perfect experience. Please provide us with some information on what went wrong so we can improve for next time.',
  REVIEW_FEEDBACK_POSITIVE_ACTION_LABEL: 'Would you book $0 again?',
  REVIEW_FEEDBACK_SUBTITLE: 'Your feedback is extremely valuable to us. This feedback is private and will help us provide a better experience.',
  REVIEW_TIP_EXPLAINER: 'Professionals receive 100% of tips. This will contribute to the cost of parking or other travel expenses the professional has incurred.',
  REVIEW_BAD_EXPERIENCE_APOLOGY: 'We\'re sorry that you had a bad experience. We will be in touch to help resolve the issue.',
  REBOOK_EXPLAINER: 'Secure your next slot before your professional gets booked up. Our cancellation policy allows you to cancel with 24 hours notice free of charge.',
  SUPER_PRO_EXPLAINER: 'Super pros are experienced, highly rated professionals who are commited to providing great experiences for their clients.',
  ELITE_PRO_EXPLAINER: 'Elite professionals are the most experienced and highly rated in their field. Their clients include a number of celebrities and VIPs, and they work with some of the most prestigious brands in the industry.',
  DELETE_ACCOUNT_PROMPT_TITLE: 'Are you sure?',
  DELETE_ACCOUNT_PROMPT_TITLE_REALLY: 'Are you really sure?',
  DELETE_ACCOUNT_PROMPT_BODY: 'Please confirm you would like to delete of your account. This is not reversible.',
  LAUNCH_ERROR: 'There was a problem contacting our servers. Please check your internet connection and relaunch the app.',
  LONG_LOAD_ERROR: "We're having trouble installing an update. Please check your internet connection and relaunch the app.",
  GRAPHQL_VALIDATION_FAILED_CA: 'There was an error, please make sure your app is up to date on the $0.',
  APPLE_STORE: 'AppStore',
  PLAY_STORE: 'Google Play Store',
}
