import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Image from 'next/image'

import { Box, DialogActions, withStyles, IconButton, WithStyles } from '@material-ui/core'
import { action, observable, observe } from 'mobx'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'

import RootStore from '../src/stores/RootStore'
import ResponsivePageModal from './ResponsivePageModal'
import TextButton from './TextButton'
import { styleCreate } from '../lib/styleCreate'

interface ImageProps extends WithStyles<typeof stylesImage> {
  src: string
}

const stylesImage = styleCreate(theme => ({
  imageFull: {
    transition: 'opacity 400ms ease 0ms',
    maxWidth: '90%',
    maxHeight: '85vh',
    [theme.breakpoints.between('xs', 'sm')]: {
      maxHeight: '70vh',
    },
  },
  loader: {
    maxWidth: '90%',
    width: '90%',
    height: '70vh',
    position: 'absolute',
  },
  opacity: {
    opacity: 0,
  },
}))

@observer
class ImageComponentPrivate extends Component<ImageProps> {
  @observable isLoaded = false

  @action.bound setLoaded = () => {
    this.isLoaded = true
  }

  render() {
    const { classes, src } = this.props

    return (
      <Image
        className={classes.imageFull}
        src={src}
        placeholder='blur'
        blurDataURL={'/img/logos/secret-spa-logo.svg'}
        alt=''
        layout='fill'
        objectFit='contain'
      />
    )
  }
}

export const ImageComponent = withStyles(stylesImage)(ImageComponentPrivate)

interface DialogProps {
  images: Array<string>
  store?: RootStore
}

@inject('store')
@observer
class ImageDialog extends Component<DialogProps> {
  @observable showingIndex = 0
  @observable isVisible = false

  constructor(props: DialogProps) {
    super(props)
    this.observeUiStore()
  }

  observeUiStore = (): void => {
    const { uiStore } = this.props.store!

    observe(uiStore, 'imageDialogVisible', ({ newValue }) => {
      if (newValue === true) {
        this.showModal()
      }
    })
  }

  showModal = (): void => {
    this.isVisible = true
  }

  hideModal = (): void => {
    this.isVisible = false
    const { uiStore } = this.props.store!
    uiStore.imageDialogVisible = false
  }

  showNext = () => {
    this.skip(1)
  }

  showBefore = () => {
    this.skip(-1)
  }

  skip = (direction: -1 | 1) => {
    const newIndex = this.showingIndex + direction
    this.showingIndex = Math.min(Math.max(newIndex, 0), this.props.images.length - 1)
  }

  render() {
    const { images } = this.props
    const src = images[this.showingIndex]
    const { i18n: { s } } = this.props.store!

    return <ResponsivePageModal
      open={this.isVisible}
      loading={false}
      close={this.hideModal}
      closeOnBackdropClick
      hideCloseIcon
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={2} style={{ margin: 'auto' }}>
        <ImageComponent src={src} />
      </Box>
      <DialogActions style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={this.showBefore}><NavigateBefore /></IconButton>
        <TextButton onClick={this.hideModal}>{s('Close')}</TextButton>
        <IconButton onClick={this.showNext}><NavigateNext /></IconButton>
      </DialogActions>
    </ResponsivePageModal>
  }
}

export default ImageDialog
